import {AfterViewInit, ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import {Buttons, ButtonTypes, DynamicDialogComponent, FormatPipe} from '@matchsource/nmdp-ui';
import {NgTemplateOutlet} from '@angular/common';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {TranslocoPipe} from '@ngneat/transloco';

const CONTAINER_CLASS = 'dynamic-confirmation-dialog';

export interface ConfirmationDialogData {
  confirmButtonType: ButtonTypes;
  caption: string;
  cancelText: string;
  confirmText: string;
  confirmMessage: string;
  hideCancel: boolean;
  confirmMessageParams: MsApp.Dictionary;
}

const DEFAULT_CONFIRMATION_DIALOG_CONFIG: Partial<ConfirmationDialogData> = {
  confirmButtonType: Buttons.Action,
  cancelText: 'COMMON.CANCEL',
  confirmText: 'COMMON.OK',
  hideCancel: false,
};

@Component({
  selector: 'ms-dynamic-confirmation-dialog',
  templateUrl: './dynamic-confirmation-dialog.component.html',
  styleUrls: ['./dynamic-confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DynamicDialogComponent, NgTemplateOutlet, ConfirmationDialogComponent, TranslocoPipe, FormatPipe],
})
export class DynamicConfirmationDialogComponent implements AfterViewInit {
  @ViewChild(DynamicDialogComponent)
  dynamicDialogComponent: DynamicDialogComponent;

  ngAfterViewInit(): void {
    this.dynamicDialogComponent.appendCustomClass(CONTAINER_CLASS);
  }

  applyDefaultData(data: Partial<ConfirmationDialogData>): Partial<ConfirmationDialogData> {
    return {
      ...DEFAULT_CONFIRMATION_DIALOG_CONFIG,
      ...(data || {}),
    };
  }
}
