import {BLOOD_SOURCE_TYPE_TO_SOURCE_TYPE_MAP} from 'app/shared/constants';
import {BusinessPartyModel} from '@matchsource/models/business-party';
import {BloodSourceType, ION_AS_ID_BPTYPES, SourceType} from '@matchsource/models/source';

export const getIonDCValue = (ts: BusinessPartyModel): string => {
  if (!ts) {
    return '';
  }
  if (ts.ion && ION_AS_ID_BPTYPES.includes(ts.type)) {
    return ts.ion;
  }
  return ts.identifier;
};

export const bloodSourceTypeToSourceType = (bloodSourceType: BloodSourceType): SourceType =>
  BLOOD_SOURCE_TYPE_TO_SOURCE_TYPE_MAP[bloodSourceType];

/**
 * @deprecated Make sure you can't use a custom pipe to map messages
 */
export const mapToMessages = (data: string[], prefix: string): MsApp.Dictionary<string> =>
  data.reduce((mapping, item) => ({...mapping, [item]: `${prefix}${item}`}), {});

export const executeOnNextEventLoop = (action: () => void): number => window.setTimeout(action);

export const REMOVE_SPACES_REG = /\s/g;
