export enum TestId {
  EmergencyContactName1 = 'emergencyContactName1',
  EmergencyContactName2 = 'emergencyContactName2',
  EmergencyContactPhone1 = 'emergencyContactPhone1',
  EmergencyContactPhone2 = 'emergencyContactPhone2',
  AcEmail = 'acEmail',
  AcFax = 'acFax',
  FormCompletedBy = 'formCompletedBy',
  FormSubmissionDate = 'formSubmissionDate',
  OrderingPhysician = 'orderingPhysician',
  FormCompletedOnBehalf = 'formCompletedOnBehalf',
  ZipPostal = 'zip-postal',
  ReadOnly = 'read-only-',
}

export enum FormControlNames {
  AdditionalProductAddresses = 'additionalProductAddresses',
  EmergencyContactName1 = 'emergencyContactName1',
  EmergencyContactName2 = 'emergencyContactName2',
  EmergencyContactPhone1 = 'emergencyContactPhone1',
  EmergencyContactPhone2 = 'emergencyContactPhone2',
  AcEmail = 'acEmail',
  AcFax = 'acFax',
  FormCompletedBy = 'formCompletedBy',
  FormSubmissionDate = 'formSubmissionDate',
  OrderingPhysician = 'orderingPhysician',
  FormCompletedOnBehalf = 'formCompletedOnBehalf',
}
