import {CordListModel} from '@matchsource/models/cord';
import {DonorWorkupModel} from '@matchsource/models/donor-workup';
import {DonorListModel} from '@matchsource/models/donor';

export const WORKUP_VERIFIED_IND_CODE = 99993;

export const WORKUP_UI_FIELDS: number[] = [WORKUP_VERIFIED_IND_CODE];

export const enum FormNavActionCode {
  Clear = 'CLR',

  Default = 'DFLT',

  Disabled = 'DISA',

  Enabled = 'ENAB',

  HOOR = 'HOOR',

  HOWR = 'HOWR',

  Optional = 'OPTI',

  Required = 'RQRD',

  Script = 'SCRP',

  Once = 'ONCE',
}

export interface FormNavItem {
  id: number;
  itemId: number;
  linkItemId: number;
  values: string[] | null;
  script: string | null;
  actionCode: FormNavActionCode;
}

export type FormNavConfig = FormNavItem[];

export type GamidaOmisirgeOrder = string | null;

export interface WorkupItemValue {
  code: string;
  description: string;
}

export type WorkupItemValues = WorkupItemValue[];

export interface WorkupItems {
  code: string;
  disabled: boolean;
  hidden: boolean;
  id: number;
  items: WorkupItems[];
  label: string;
  maxLength: number;
  required: boolean;
  type: string;
  values: WorkupItemValues;
  customValid: string;
}

export interface WorkupStructureModel {
  allowedDefaultIds: number[];
  items: WorkupItems[];
  navigations: FormNavConfig;
  formVersion: string;
}

export interface SourceInProgress {
  completed: boolean;
  createDate: MsApp.DateString;
  id: string | number;
  source: DonorListModel | CordListModel;
  orderType: string;
  formCode: FormCode;
}

export interface WorkupInProgressModel {
  id: MsApp.Guid | number;
  sourceId: MsApp.Guid;
  createDate: MsApp.DateString;
  isCompleted: boolean;
  sourceType: string;
  services?: string[];
  gamidaOmisirgeOrderInd: GamidaOmisirgeOrder;
  patientIdentityVerifiedInd?: boolean;
  formCode: FormCode;
}

export interface WorkupListModel {
  workupId: number;
  sourceId: MsApp.Guid;
  model: {[key: number]: string | number | boolean} | DonorWorkupModel;
  type: string;
  formVersion?: string;
}

export enum NewWorkupModel {
  Anticoagulant = 'anticoagulant',
  AnticoagulantOthr = 'anticoagulantOthr',
  AmlAllOrOther = 'amlAllOrOther',
  AphCtrEmail = 'aphCtrEmail',
  AmtRatio = 'amtRatio',
  AphCtrFax = 'aphCtrFax',
  BackupCbuOnHold = 'backupCbuOnHold',
  BackupDonorsHeldForWorkup = 'backupDonorsHeldForWorkup',
  BpGuid = 'bpGuid',
  Cb1Id = 'cb1Id',
  Cb2Id = 'cb2Id',
  Cb3Id = 'cb3Id',
  Cb4Id = 'cb4Id',
  Cd34Cells = 'cd34Cells',
  Cd34ForQa = 'cd34ForQa',
  ClearDay1FirstChoiceMar = 'clearDay1FirstChoiceMar',
  ClearDay1FirstChoicePbsc = 'CcearDay1FirstChoicePbsc',
  CmlStatus = 'cmlStatus',
  CmlStatusRemoved = 'cmlStatusRemoved',
  CompleteOnBehalf = 'completeOnBehalf',
  DateTcCompleted = 'dateTcCompleted',
  DcCde = 'dcCde',
  DcsamAddnlComments = 'dcsamAddnlComments',
  DcsamAddnlCommentsSecond = 'dcsamAddnlCommentsSecond',
  DcsamAddrLine1 = 'dcsamAddrLine1',
  DcsamAddrLine2 = 'dcsamAddrLine2',
  DcsamAddrLine3 = 'dcsamAddrLine3',
  DcsamAttnName = 'dcsamAttnName',
  DcsamCenterName = 'dcsamCenterName',
  DcsamCity = 'dcsamCity',
  DcsamCountry = 'dcsamCountry',
  DcsamDay1Acd = 'dcsamDay1Acd',
  DcsamDay1AcdSecond = 'dcsamDay1AcdSecond',
  DcsamDay1Clot = 'dcsamDay1Clot',
  DcsamDay1ClotSecond = 'dcsamDay1ClotSecond',
  DcsamDay1Edta = 'dcsamDay1Edta',
  DcsamDay1EdtaSecond = 'dcsamDay1EdtaSecond',
  DcsamDay1Hep = 'dcsamDay1Hep',
  DcsamDay1HepSecond = 'dcsamDay1HepSecond',
  DcsamDay2Acd = 'dcsamDay2Acd',
  DcsamDay2AcdSecond = 'dcsamDay2AcdSecond',
  DcsamDay2Clot = 'dcsamDay2Clot',
  DcsamDay2ClotSecond = 'dcsamDay2ClotSecond',
  DcsamDay2Edta = 'dcsamDay2Edta',
  DcsamDay2EdtaSecond = 'dcsamDay2EdtaSecond',
  DcsamDay2Hep = 'dcsamDay2Hep',
  DcsamDay2HepSecond = 'dcsamDay2HepSecond',
  DcsamDeliveryAddrLine1 = 'dcsamDeliveryAddrLine1',
  DcsamDeliveryAddrLine2 = 'dcsamDeliveryAddrLine2',
  DcsamDeliveryAddrLine3 = 'dcsamDeliveryAddrLine3',
  DcsamDeliveryAttnName = 'dcsamDeliveryAttnName',
  DcsamDeliveryCenterName = 'dcsamDeliveryCenterName',
  DcsamDeliveryCity = 'dcsamDeliveryCity',
  DcsamDeliveryCountry = 'dcsamDeliveryCountry',
  DcsamDeliveryEmail = 'dcsamDeliveryEmail',
  DcsamDeliveryPhone = 'dcsamDeliveryPhone',
  DcsamDeliveryStateProv = 'dcsamDeliveryStateProv',
  DcsamDeliveryZipCode = 'dcsamDeliveryZipCode',
  DcsamEmail = 'dcsamEmail',
  DcsamPhone = 'dcsamPhone',
  DcsamRequireSecondSet = 'dcsamRequireSecondSet',
  DcsamStateProv = 'dcsamStateProv',
  DcsamWillBeSentWithProd = 'dcsamWillBeSentWithProd',
  DcsamZipPostalCode = 'dcsamZipPostalCode',
  Diagnosis = 'diagnosis',
  DiagnosisOthrSpecify = 'diagnosisOthrSpecify',
  DiseaseStage = 'diseaseStage',
  DiseaseStageRemoved = 'diseaseStageRemoved',
  EntryDte = 'entryDte',
  FirstChoice = 'firstChoice',
  FirstChoiceMar = 'firstChoiceMar',
  FirstChoicePbsc = 'firstChoicePbsc',
  FirstEmergencyContNme = 'firstEmergencyContNme',
  FirstEmergencyContPhn = 'firstEmergencyContPhn',
  FormCompletedBy = 'formCompletedBy',
  FormSubmissionDte = 'formSubmissionDte',
  Ld1HeldForWorkup = 'ld1HeldForWorkup',
  Ld2HeldForWorkup = 'ld2HeldForWorkup',
  LastUpdated = 'lastUpdated',
  LastUpdatedBy = 'lastUpdatedBy',
  MarrAdditionalComments = 'marrAdditionalComments',
  MarrCryopreservRequested = 'marrCryopreservRequested',
  MarrDnrcntrPrvds1stAvail = 'marrDnrcntrPrvds1stAvail',
  MarrNumDaysClearance = 'marrNumDaysClearance',
  MarrNumDaysPatientPreparativeRegimen = 'marrNumDaysPatientPreparativeRegimen',
  MarrRationaleRequest = 'marrRationaleRequest',
  MarrSchedOnCertainWeekday = 'marrSchedOnCertainWeekday',
  MarrSchedSelFri = 'marrSchedSelFri',
  MarrSchedSelMon = 'marrSchedSelMon',
  MarrSchedSelSat = 'marrSchedSelSat',
  MarrSchedSelSun = 'marrSchedSelSun',
  MarrSchedSelThu = 'marrSchedSelThu',
  MarrSchedSelTue = 'marrSchedSelTue',
  MarrSchedSelWed = 'marrSchedSelWed',
  MarrSelectCryopreservLoc = 'marrSelectCryopreservLoc',
  MarrSpecialProcessReq = 'marrSpecialProcessReq',
  MarrowTransportCondition = 'marrowTransportCondition',
  NultDonorsHeldForWorkup = 'nultDonorsHeldForWorkup',
  NmdpId = 'nmdpId',
  NmdpRid = 'nmdpRid',
  NuclCellsQa = 'nuclCellsQa',
  NuclPerKg = 'nuclPerKg',
  NumberOfRemissionRemoved = 'numberOfRemissionRemoved',
  NumberOfRemissions = 'numberOfRemissions',
  OptionalCommentsText = 'optionalCommentsText',
  OrderingPhysician = 'orderingPhysician',
  OtherDiagnosisRemoved = 'otherDiagnosisRemoved',
  OutstandReqAddnlComments = 'outstandReqAddnlComments',
  OutstandingRequestCancel = 'outstandingRequestCancel',
  PbscAdditionalComments = 'pbscAdditionalComments',
  PbscAdditivesToProduct = 'pbscAdditivesToProduct',
  PbscCryopreservRequested = 'pbscCryopreservRequested',
  PbscDnrcntrPrvds1stAvail = 'pbscDnrcntrPrvds1stAvail',
  PbscDonorPlasmaRequested = 'pbscDonorPlasmaRequested',
  PbscNumDaysClearance = 'pbscNumDaysClearance',
  PbscNumDaysPatientPreparativeRegimen = 'pbscNumDaysPatientPreparativeRegimen',
  PbscPlasmaVolumeMl = 'pbscPlasmaVolumeMl',
  PbscRationaleRequest = 'pbscRationaleRequest',
  PbscSecondDayRationale = 'pbscSecondDayRationale',
  PbscNctNumber = 'pbscNctNumber',
  PbscOtherProvideRationale = 'pbscOtherProvideRationale',
  PbscRecipWeight = 'pbscRecipWeight',
  PbscSchedOnCertainWeekday = 'pbscSchedOnCertainWeekday',
  PbscSchedSelFri = 'pbscSchedSelFri',
  PbscSchedSelMon = 'pbscSchedSelMon',
  PbscSchedSelSat = 'pbscSchedSelSat',
  PbscSchedSelSun = 'pbscSchedSelSun',
  PbscSchedSelThu = 'pbscSchedSelThu',
  PbscSchedSelTue = 'pbscSchedSelTue',
  PbscSchedSelWed = 'pbscSchedSelWed',
  PbscSelOneForPlasmaDeliv = 'pbscSelOneForPlasmaDeliv',
  PbscSelectCryopreservLoc = 'pbscSelectCryopreservLoc',
  PbscSpecialProcessReq = 'pbscSpecialProcessReq',
  PbscSpecifyAdditives = 'pbscSpecifyAdditives',
  PbscVolumeRatio = 'pbscVolumeRatio',
  PreviousAlloHtcInd = 'previousAlloHtcInd',
  ProdDay1Acd = 'prodDay1Acd',
  ProdDay1AcdSecond = 'prodDay1AcdSecond',
  ProdDay1Clot = 'prodDay1Clot',
  ProdDay1ClotSecond = 'prodDay1ClotSecond',
  ProdDay1Edta = 'prodDay1Edta',
  ProdDay1EdtaSecond = 'prodDay1EdtaSecond',
  ProdDay1Hep = 'prodDay1Hep',
  ProdDay1HepSecond = 'prodDay1HepSecond',
  ProdDay2Acd = 'prodDay2Acd',
  ProdDay2AcdSecond = 'prodDay2AcdSecond',
  ProdDay2Clot = 'prodDay2Clot',
  ProdDay2ClotSecond = 'prodDay2ClotSecond',
  ProdDay2Edta = 'prodDay2Edta',
  ProdDay2EdtaSecond = 'prodDay2EdtaSecond',
  ProdDay2Hep = 'prodDay2Hep',
  ProdDay2HepSecond = 'prodDay2HepSecond',
  ProdDeliveryAddrLine1 = 'prodDeliveryAddrLine1',
  ProdDeliveryAddrLine2 = 'prodDeliveryAddrLine2',
  ProdDeliveryAddrLine3 = 'prodDeliveryAddrLine3',
  ProdDeliveryAttnName = 'prodDeliveryAttnName',
  ProdDeliveryCity = 'prodDeliveryCity',
  ProdDeliveryCountry = 'prodDeliveryCountry',
  ProdDeliveryEmail = 'prodDeliveryEmail',
  ProdDeliveryPhone = 'prodDeliveryPhone',
  ProdDeliveryStateProvince = 'prodDeliveryStateProvince',
  ProdDeliveryTcName = 'prodDeliveryTcName',
  ProdDeliveryZip = 'prodDeliveryZip',
  PsamAdditionalComments = 'psamAdditionalComments',
  PsamMlAcd = 'psamMlAcd',
  PsamMlAcdSecond = 'psamMlAcdSecond',
  PsamMlClot = 'psamMlClot',
  PsamMlClotSecond = 'psamMlClotSecond',
  PsamMlEdta = 'psamMlEdta',
  PsamMlEdtaSecond = 'psamMlEdtaSecond',
  PsamMlHep = 'psamMlHep',
  PsamMlHepSecond = 'psamMlHepSecond',
  PsamRationaleForMlRequest = 'psamRationaleForMlRequest',
  PsamRequireCtPerformedPsams = 'psamRequireCtPerformedPsams',
  PsamRequirePsams = 'psamRequirePsams',
  PsamRequireSecondSetPsams = 'psamRequireSecondSetPsams',
  PsamShipAddrLine1 = 'psamShipAddrLine1',
  PsamShipAddrLine1Second = 'psamShipAddrLine1Second',
  PsamShipAddrLine2 = 'psamShipAddrLine2',
  PsamShipAddrLine2Second = 'psamShipAddrLine2Second',
  PsamShipAddrLine3 = 'psamShipAddrLine3',
  PsamShipAddrLine3Second = 'psamShipAddrLine3Second',
  PsamShipAttnName = 'psamShipAttnName',
  PsamShipAttnNameSecond = 'psamShipAttnNameSecond',
  PsamShipCenterName = 'psamShipCenterName',
  PsamShipCenterNameSecond = 'psamShipCenterNameSecond',
  PsamShipCity = 'psamShipCity',
  PsamShipCitySecond = 'psamShipCitySecond',
  PsamShipCountry = 'psamShipCountry',
  PsamShipCountrySecond = 'psamShipCountrySecond',
  PsamShipEmail = 'psamShipEmail',
  PsamShipEmailSecond = 'psamShipEmailSecond',
  PsamShipStateProvince = 'psamShipStateProvince',
  PsamShipStateProvinceSecond = 'psamShipStateProvinceSecond',
  PsamShipTelephone = 'psamShipTelephone',
  PsamShipTelephoneSecond = 'psamShipTelephoneSecond',
  PsamShipZipCode = 'psamShipZipCode',
  PsamShipZipCodeSecond = 'psamShipZipCodeSecond',
  PsamSpecSamplesCollected = 'psamSpecSamplesCollected',
  RecipWeight = 'recipWeight',
  SaaTransfused = 'saaTransfused',
  SaaTransfusedRemoved = 'saaTransfusedRemoved',
  ScuActivatedDate = 'scuActivatedDate',
  SecondChoice = 'secondChoice',
  SecondEmergencyContNme = 'secondEmergencyContNme',
  SecondEmergencyContPhn = 'secondEmergencyContPhn',
  TotalCd34 = 'totalCd34',
  TotalCd34Requested = 'totalCd34Requested',
  MultDonorsHeldForWorkup = 'multDonorsHeldForWorkup',
  Id1HeldForWorkup = 'id1HeldForWorkup',
  Id2HeldForWorkup = 'id2HeldForWorkup',
}

export interface FormValues {
  [key: number]: string | number | boolean;
}

export interface DefaultValues {
  values: FormValues;
}

export type InquiryCode =
  | 'DNRIDM'
  | 'MATID2'
  | 'MSQMS2'
  | 'FHQFH2'
  | 'CBUID2'
  | 'EWU'
  | 'SECEWU'
  | 'EIDMs'
  | 'CD34ST'
  | 'CD3ST'
  | 'CFU'
  | 'CFUST'
  | 'NRBCST'
  | 'TNCST'
  | 'VIAB'
  | 'VIABST'
  | 'NATHCV'
  | 'NATHIV'
  | 'EOR'
  | 'PROCES';

export const INQUIRY_CODES: Record<string, InquiryCode> = {
  EOR: 'EOR',
  EWU: 'EWU',
  SECEWU: 'SECEWU',
};

export const INQUIRY_CODES_BY_SOURCE_TYPE: Record<string, InquiryCode> = {
  cord: 'EOR',
  donor: 'EWU',
};

export const INITIAL_COUNTRY_VALUE = 'initial-country-value';

export type FormCode =
  | 'DNRIDM'
  | 'MATID2'
  | 'MSQMS2'
  | 'FHQFH2'
  | 'CBUID2'
  | 'EWU'
  | 'SECEWU'
  | 'EIDMs'
  | 'CD34ST'
  | 'CD3ST'
  | 'CFU'
  | 'CFUST'
  | 'NRBCST'
  | 'TNCST'
  | 'VIAB'
  | 'VIABST'
  | 'NATHCV'
  | 'NATHIV'
  | 'EOR'
  | 'PROCES';

export interface InProgressWorkUp {
  recipientGuid: MsApp.Guid;
  sourceGuid: MsApp.Guid;
  values: FormValues;
  sourceType: string;
  completed: boolean;
  gamidaOmisirgeOrderInd?: string;
  formVersion?: string;
  formCode?: FormCode;
}
