import {HlaTodayReportCountsMatchCategory} from '@matchsource/models/patient';

export enum SsaRequestStatus {
  Completed = 'COMPLETED',
  Canceled = 'CANCELLED',
  Submitted = 'SUBMITTED',
}

export enum PatientProcess {
  Standard = 'STANDARD',
  HlaToday = 'HLATODAY',
}

export const OTHER_DIAGNOSIS_CODES = ['OMS', 'OOL', 'OND'];

export const NOT_APPLICABLE = 'NA';

export const DEFAULT_LANG_CODE = 'ENG';

export enum Languages {
  Other = 'OTH',
}

export const MATCH_CATEGORY_NUMBERS_LIST = [
  {code: HlaTodayReportCountsMatchCategory.Zero, description: '0'},
  {code: HlaTodayReportCountsMatchCategory.OneToFive, description: '1-5'},
  {code: HlaTodayReportCountsMatchCategory.Five, description: '>5'},
  {code: HlaTodayReportCountsMatchCategory.Ten, description: '>10'},
  {code: HlaTodayReportCountsMatchCategory.TwentyFive, description: '>25'},
  {code: HlaTodayReportCountsMatchCategory.Fifty, description: '>50'},
];

export const DSA_LOCUS_KEYS = {
  antigenSpecificityEntries: 'antigenSpecificityEntries',
  alleleSpecificityEntries: 'alleleSpecificityEntries',
};

export const SELF_ANTIBODY_KEYS = {
  selfAntigens: 'selfAntigens',
  selfAlleles: 'selfAlleles',
};

export const MAXIMUM_PROFILES_NUMBER = 30;

export const HLA_TODAY_ROUTE_NAME = 'patient.abstract-view.view.phenotype.hla-today';
export const TCS_WITH_INTERNATIONAL_BEHAVIOUR = {
  FOR_NBD_USERS: '399',
  NON_NETWORK_TC: '479',
  HLA_TODAY_TC: '500',
};
export const STATUS_REASON_CODE = {
  OTHER_REASON: '5017',
  NO_SUITABLE_MATCH: '5012',
};

export enum PatientProfileTypeEnum {
  Positive = 'Positive',
  Negative = 'Negative',
}
