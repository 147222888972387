<div class="ms-top-header">
  <ul class="container" *ngIf="!isCommonPractice else commonPracticeMenu">
    <li class="ms-logo-area">
      <a [uiSref]="dashboardLink" [attr.test-id]="testIds.MsLogoDashboard">
        <ms-logo-component></ms-logo-component>
      </a>
    </li>
    <li class="workflow-manager-area menu-area" *msUserPermissions="workflowLinkShowPermissions">
      <div class="container">
        <a [attr.test-id]="testIds.ManagerLink" [ngClass]="{'selected': isStateActive(currentRoute$ | async, workflowManagerLinks)}" class="menu-link"
           [uiSref]="workflowManagerRoutes.Workflow" transloco="WORKFLOW_MANAGER.TITLE"></a>
      </div>
    </li>

    <li class="patient-create menu-area" *msUserPermissions="patientCreatingPermissions">
      <div class="container">
        <div class="ms-dropdown">
          <a [attr.test-id]="testIds.PatientsLink" class="menu-link dropdown-toggle dropdown-toggle-arrow"
             [ngClass]="{
             'open': patientMenuToggle.active,
             'selected': isStateActive(currentRoute$ | async, patientsLinks)
             }" msToggle #patientMenuToggle="toggle"
             transloco="DASHBOARD.CREATE_PATIENTS"
          ></a>
          <ul class="dropdown-menu" [ngClass]="{'open': patientMenuToggle.active}" *ngIf="hasAccessToProtectedArea">
            <li class="dropdown-menu-item">
              <a [attr.test-id]="testIds.CreateNewPatient" (click)="navToCreatePatient()">{{ 'PATIENT.CREATE_NEW_PATIENT' | transloco }}</a>
            </li>
            <li class="dropdown-menu-item" *ngIf="!isCommonPractice">
              <a [attr.test-id]="testIds.PatientInProgress" [uiSref]="patientsInProgressRoutes.PatientsInProgress" transloco="PATIENTS_IN_PROGRESS.TITLE"></a>
            </li>
          </ul>
        </div>
      </div>
    </li>

    <li *msUserPermissions="searchPermissions" class="header-search-link">
      <a [attr.test-id]="testIds.HeaderSearchStatus" [ngClass]="{'selected': isStateActive(currentRoute$ | async, searchStatusLinks)}" (click)="openSearchStatusPage()" class="header-link">
        <span *ngIf="hasUnviewedSearches$ | async" class="link-counter">{{ unviewedSearchesCount$ | async }}</span>
        <span class="rounded-spinner" [ngClass]="{ 'active': (hasInProgressSearches$ | async) }">
                <span class="bounce-1"></span>
                <span class="bounce-2"></span>
                <span class="bounce-3"></span>
                <span class="bounce-4"></span>
                <span class="bounce-5"></span>
                <span class="bounce-6"></span>
                <span class="bounce-7"></span>
                <span class="bounce-8"></span>
            </span>
        <span class="header-search-status" transloco="SEARCH_STATUS.TITLE"></span>
      </a>
    </li>

    <li class="search-area" *ngIf="isLoggedUser && hasAccessToProtectedArea">
      <ul class="container">
        <li>
          <ms-search
            [attr.test-id]="testIds.StatusPage"
            [searchTypes]="searchTypes"
            [searchParams]="searchOptions"
            [isCommonPractice]="isCommonPractice"
            (search)="search($event)"
          >
          </ms-search>
        </li>
        <li *msUserPermissions="advancedLookupPermissions" class="advanced-lookup menu-area">
          <a [attr.test-id]="testIds.AdvancedLookup" [ngClass]="{'selected': isStateActive(currentRoute$ | async, advancedLookupLinks)}"
             class="menu-link advanced-lookup-link" transloco="PATIENT.ADVANCED_LOOKUP_HEADER" (click)="goToAdvancedLookUp()"></a>
        </li>
      </ul>
    </li>
    <li class="user-area menu-area">
      <div class="container">
        <div class="ms-dropdown">
          <a class="user-menu-container"
             [attr.test-id]="testIds.UserMenu"
             [ngClass]="{'open': protectedUserMenuToggle.active}" msToggle #protectedUserMenuToggle="toggle">{{ userNameAbbr | uppercase }}</a>
          <ul class="dropdown-menu wrapper" [ngClass]="{'open': protectedUserMenuToggle.active}" *ngIf="hasAccessToProtectedArea">
            <li class="role"><b>{{userFullName | uppercase}}</b></li>
            <li class="dropdown-menu-note user-name">{{userName}}</li>

            <ms-switch-roles *ngIf="isMultipleRolesUser" [userRoles]="userRoles" [activeRole]="userRole" (switchRole)="switchRole($event)">
            </ms-switch-roles>

            <li class="dropdown-menu-divider"></li>
            <li class="dropdown-menu-item" *ngIf="showAnalyticsMenu$ | async">
              <a [attr.test-id]="testIds.WorkFlowManager" uiSref="analytics">{{ 'ANALYTICS.TITLE' | transloco }}</a>
            </li>
            <li><b transloco="NAV_TOOLBAR.APPLICATION_TOOLS"></b></li>
            <li class="dropdown-menu-item" *msUserPermissions="announcementsEditPermissions">
              <a [attr.test-id]="testIds.AdminTools" uiSref="admin-tools" transloco="ADMIN_TOOLS.ADMIN_TOOLS"></a>
            </li>
            <li class="dropdown-menu-item" *msUserPermissions="formsDuePermissions">
              <a [attr.test-id]="testIds.FormsDue" uiSref="forms-due">{{ 'FORMS_DUE.FORMS_22_DUE' | transloco }}</a>
            </li>
            <li class="dropdown-menu-item" *msUserPermissions="secondaryCodePermissions">
              <a [attr.test-id]="testIds.SecondaryCodes"  uiSref="secondary-codes" transloco="SECONDARY_CODES.MANAGE_SECONDARY_CODE"></a>
            </li>
            <li class="dropdown-menu-item" *msUserPermissions="duplicatesViewPermissions">
              <a [attr.test-id]="testIds.PotentialDuplicates" uiSref="potential-duplicates" transloco="POTENTIAL_DUPLICATES.PATE_TITLE"></a>
            </li>
            <li class="dropdown-menu-item" *msUserPermissions="sampleInstructionPermissions">
              <a [attr.test-id]="testIds.SampleInstruction" uiSref="sample-instruction" transloco="SIT.HEADER"></a>
            </li>
            <li class="dropdown-menu-item" *msUserPermissions="settingsPermissions">
              <a [attr.test-id]="testIds.Settings" uiSref="settings" transloco="SETTINGS.SETTINGS"></a>
            </li>

            <li class="dropdown-menu-divider"></li>
            <li><b transloco="NAV_TOOLBAR.TRAINING_AND_RESOURCES"></b></li>
            <li class="dropdown-menu-item external-item-wrapper">
              <a class="external-link" rel="noopener" href="https://app.cloud.scorm.com/sc/InvitationConfirmEmail?publicInvitationId=92c40595-6001-4970-84ae-3f248f015820"
                 target="_blank">
                <span transloco="NAV_TOOLBAR.MATCHSOURCE_INTERACTIVE_TRAINING" ></span>
                <span class="external-link-ico"></span>
              </a>
            </li>
            <li class="dropdown-menu-item external-item-wrapper">
              <a class="external-link" rel="noopener" href="https://network.bethematchclinical.org/transplant-centers/search-and-testing/matchsource/"
                  target="_blank">
                <span transloco="NAV_TOOLBAR.MATCHSOURCE_NETWORK_WEBSITE"></span>
                <span class="external-link-ico"></span>
              </a>
            </li>
            <li class="dropdown-menu-item external-item-wrapper">
              <a class="external-link" rel="noopener" href="https://network.bethematchclinical.org/education/education-catalog/donor-and-cord-blood-unit-selection-guidelines/"
                 target="_blank">
                <span transloco="NAV_TOOLBAR.NMDP_CIBMTR_MATCHING_GUIDELINES"></span>
                <span class="external-link-ico"></span>
              </a>

            </li>
            <li class="dropdown-menu-divider"></li>
            <li class="dropdown-menu-divider"></li>
            <li class="dropdown-menu-item">
              <a [attr.test-id]="testIds.Logout" (click)="logout()" transloco="USER.LOGOUT"></a>
            </li>
            <li class="dropdown-menu-note">
              <span>{{ 'NAV_TOOLBAR.LAST_LOGIN' | transloco }}: {{ lastLogin | date : lastLoginDateFormat | uppercase }}</span>
            </li>
          </ul>

          <ul id="userMenu" class="dropdown-menu" *ngIf="!hasAccessToProtectedArea">
            <li class="dropdown-menu-item">
              <a [attr.test-id]="testIds.Logout" (click)="logout()" transloco="USER.LOGOUT"></a>
            </li>
            <li class="dropdown-menu-note">
              <span>{{ 'NAV_TOOLBAR.LAST_LOGIN' | transloco }}: <span class="uppercase-text">{{ lastLogin | date : lastLoginDateFormat }}</span></span>
            </li>
          </ul>
        </div>
      </div>
    </li>
  </ul>
  <ng-template #commonPracticeMenu>
    <ms-cp-layout
      [attr.test-id]="testIds.CpLayout"
      [notificationsLink]="workflowManagerRoutes.Workflow"
      [dashboardLink]="dashboardLink"
      [searchTypes]="searchTypes"
      [searchOptions]="searchOptions"
      [userName]="userName"
      [userFullName]="userFullName"
      [userRole]="userRole"
      [userRoles]="userRoles"
      [isMultipleRolesUser]="isMultipleRolesUser"
      [userNameAbbr]="userNameAbbr"
      [hasAccessToProtectedArea]="hasAccessToProtectedArea"
      [lastLogin]="lastLogin"
      [lastLoginDateFormat]="lastLoginDateFormat"
      (search)="search($event)"
      (logout)="logout()"
      (switchRole)="switchRole($event)"
    >
    </ms-cp-layout>
  </ng-template>
</div>
<ms-announcement [attr.test-id]="testIds.MsAnnouncement" *msUserPermissions="announcementsViewPermissions" [announcement]="announcement$ | async"></ms-announcement>
<div class="ms-main-content flexible">
  <ui-view></ui-view>
</div>
<ms-spinner [attr.test-id]="testIds.UiSpinner" *ngIf="isSpinnerActive$ | async"></ms-spinner>
