/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AntibodyProfileFeature } from '../models/antibody-profile-feature';
import { DocumentDetails } from '../models/document-details';
import { DocumentResult } from '../models/document-result';
import { DonorContactReportRequestFeature } from '../models/donor-contact-report-request-feature';
import { InternationalFormFeature } from '../models/international-form-feature';
import { SearchSumReportFeature } from '../models/search-sum-report-feature';
import { SsaRequestFeature } from '../models/ssa-request-feature';

@Injectable({
  providedIn: 'root',
})
export class DocumentControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation changeDocumentFeature
   */
  static readonly ChangeDocumentFeaturePath = '/documents/feature';

  /**
   * This method changes a document featureGuid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeDocumentFeature()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeDocumentFeature$Response(params: {

    /**
     * Stored as OWC_DOCUMENT.OWC_DOCUMENT_IID in MS DB
     */
    documentId: number;
    featureGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<DocumentDetails>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentControllerService.ChangeDocumentFeaturePath, 'put');
    if (params) {
      rb.query('documentId', params.documentId, {});
      rb.query('featureGuid', params.featureGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentDetails>;
      })
    );
  }

  /**
   * This method changes a document featureGuid
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `changeDocumentFeature$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeDocumentFeature(params: {

    /**
     * Stored as OWC_DOCUMENT.OWC_DOCUMENT_IID in MS DB
     */
    documentId: number;
    featureGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<DocumentDetails> {

    return this.changeDocumentFeature$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<DocumentDetails>) => r.body as DocumentDetails)
    );
  }

  /**
   * Path part for operation getAllDocuments
   */
  static readonly GetAllDocumentsPath = '/documents';

  /**
   * This method gets all documents for entity with given GUID and type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDocuments$Response(params: {
    entity: string;
    type: 'SSAOPLREQUEST' | 'INTERNATIONAL_FORMS' | 'POS_ANTIBODY_FORM' | 'SEARCH_SUM_REPORT' | 'DONOR_CONTACT_REPORT';
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<DocumentDetails>>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentControllerService.GetAllDocumentsPath, 'get');
    if (params) {
      rb.query('entity', params.entity, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentDetails>>;
      })
    );
  }

  /**
   * This method gets all documents for entity with given GUID and type
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDocuments(params: {
    entity: string;
    type: 'SSAOPLREQUEST' | 'INTERNATIONAL_FORMS' | 'POS_ANTIBODY_FORM' | 'SEARCH_SUM_REPORT' | 'DONOR_CONTACT_REPORT';
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<DocumentDetails>> {

    return this.getAllDocuments$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<DocumentDetails>>) => r.body as Array<DocumentDetails>)
    );
  }

  /**
   * Path part for operation uploadDocument
   */
  static readonly UploadDocumentPath = '/documents';

  /**
   * This method uploads a document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadDocument()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadDocument$Response(params?: {
    context?: HttpContext
    body?: {
'feature': (AntibodyProfileFeature | DonorContactReportRequestFeature | InternationalFormFeature | SearchSumReportFeature | SsaRequestFeature);
'file': Blob;
}
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<DocumentResult>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentControllerService.UploadDocumentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentResult>;
      })
    );
  }

  /**
   * This method uploads a document
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadDocument$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadDocument(params?: {
    context?: HttpContext
    body?: {
'feature': (AntibodyProfileFeature | DonorContactReportRequestFeature | InternationalFormFeature | SearchSumReportFeature | SsaRequestFeature);
'file': Blob;
}
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<DocumentResult> {

    return this.uploadDocument$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<DocumentResult>) => r.body as DocumentResult)
    );
  }

  /**
   * Path part for operation getDocumentsDetails
   */
  static readonly GetDocumentsDetailsPath = '/documents/details';

  /**
   * This method returns documents details by IDs in MatchSource DB
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentsDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDocumentsDetails$Response(params: {
    context?: HttpContext
    body: Array<number>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<DocumentDetails>>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentControllerService.GetDocumentsDetailsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentDetails>>;
      })
    );
  }

  /**
   * This method returns documents details by IDs in MatchSource DB
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDocumentsDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDocumentsDetails(params: {
    context?: HttpContext
    body: Array<number>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<DocumentDetails>> {

    return this.getDocumentsDetails$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<DocumentDetails>>) => r.body as Array<DocumentDetails>)
    );
  }

  /**
   * Path part for operation downloadDocument
   */
  static readonly DownloadDocumentPath = '/documents/{id}';

  /**
   * This method downloads a document by its ID in MatchSource DB
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadDocument$Response(params: {

    /**
     * Stored as OWC_DOCUMENT.OWC_DOCUMENT_IID in MS DB
     */
    id: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentControllerService.DownloadDocumentPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method downloads a document by its ID in MatchSource DB
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadDocument(params: {

    /**
     * Stored as OWC_DOCUMENT.OWC_DOCUMENT_IID in MS DB
     */
    id: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Blob> {

    return this.downloadDocument$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation softDelete
   */
  static readonly SoftDeletePath = '/documents/{id}';

  /**
   * This method deletes a document by ID in MatchSource DB
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `softDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  softDelete$Response(params: {

    /**
     * Stored as OWC_DOCUMENT.OWC_DOCUMENT_IID in MS DB
     */
    id: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentControllerService.SoftDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method deletes a document by ID in MatchSource DB
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `softDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  softDelete(params: {

    /**
     * Stored as OWC_DOCUMENT.OWC_DOCUMENT_IID in MS DB
     */
    id: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.softDelete$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getDocumentDetails
   */
  static readonly GetDocumentDetailsPath = '/documents/{documentId}/details';

  /**
   * This method returns document details by its ID in MatchSource DB
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentDetails$Response(params: {
    documentId: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<DocumentDetails>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentControllerService.GetDocumentDetailsPath, 'get');
    if (params) {
      rb.path('documentId', params.documentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentDetails>;
      })
    );
  }

  /**
   * This method returns document details by its ID in MatchSource DB
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDocumentDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentDetails(params: {
    documentId: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<DocumentDetails> {

    return this.getDocumentDetails$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<DocumentDetails>) => r.body as DocumentDetails)
    );
  }

  /**
   * Path part for operation getInfoByOwcId
   */
  static readonly GetInfoByOwcIdPath = '/documents/info/{owcId}';

  /**
   * This method gets a document's information by OWC ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInfoByOwcId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInfoByOwcId$Response(params: {
    owcId: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<{
[key: string]: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentControllerService.GetInfoByOwcIdPath, 'get');
    if (params) {
      rb.path('owcId', params.owcId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: string;
        }>;
      })
    );
  }

  /**
   * This method gets a document's information by OWC ID
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getInfoByOwcId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInfoByOwcId(params: {
    owcId: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<{
[key: string]: string;
}> {

    return this.getInfoByOwcId$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<{
[key: string]: string;
}>) => r.body as {
[key: string]: string;
})
    );
  }

  /**
   * Path part for operation getDocumentFormats
   */
  static readonly GetDocumentFormatsPath = '/documents/extensions';

  /**
   * This method lists all accepted document formats
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentFormats()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentFormats$Response(params: {
    featureType: 'SSAOPLREQUEST' | 'INTERNATIONAL_FORMS' | 'POS_ANTIBODY_FORM' | 'SEARCH_SUM_REPORT' | 'DONOR_CONTACT_REPORT';
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentControllerService.GetDocumentFormatsPath, 'get');
    if (params) {
      rb.query('featureType', params.featureType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method lists all accepted document formats
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDocumentFormats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentFormats(params: {
    featureType: 'SSAOPLREQUEST' | 'INTERNATIONAL_FORMS' | 'POS_ANTIBODY_FORM' | 'SEARCH_SUM_REPORT' | 'DONOR_CONTACT_REPORT';
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<string>> {

    return this.getDocumentFormats$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation downloadByOwcId
   */
  static readonly DownloadByOwcIdPath = '/documents/download/{owcId}';

  /**
   * This method downloads document specified with OWC ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadByOwcId()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadByOwcId$Response(params: {
    owcId: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentControllerService.DownloadByOwcIdPath, 'get');
    if (params) {
      rb.path('owcId', params.owcId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method downloads document specified with OWC ID
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadByOwcId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadByOwcId(params: {
    owcId: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Blob> {

    return this.downloadByOwcId$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

}
