import {DocumentModel} from '@matchsource/models/documents';
import {Injectable} from '@angular/core';
import {DocumentsApiService} from '@matchsource/api/documents';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {filter, finalize, switchMap, tap} from 'rxjs/operators';
import {
  AddOrderAttachments,
  BulkUpdateAttachments,
  DeleteOrderAttachment,
  GetWorkupAttachments,
  LoadOrderAttachmentExtensions,
  GetWorkupByOrderId,
  ClearAttachmentsState,
  ReassignWorkupAttachments,
  DeleteWorkupInProgressAttachments,
  GetNewWorkupIdByOrderId,
} from './attachments.actions';
import {InternationalApiService} from '@matchsource/api/international-forms';
import {EMPTY, forkJoin, Observable} from 'rxjs';
import {WorkupApiService} from '@matchsource/api/workup';
import {DonorWorkupApiService} from '@matchsource/api/donor-workup';

export interface OrderAttachmentsStateModel {
  workupId: number;
  loading: boolean;
  loaded: boolean;
  attachments: DocumentModel[];
  attachmentExtensions: string[];
}

const orderAttachmentsInitState = (): OrderAttachmentsStateModel => ({
  workupId: null,
  loading: false,
  loaded: false,
  attachments: [],
  attachmentExtensions: [],
});

@State<OrderAttachmentsStateModel>({
  name: 'orderAttachments',
  defaults: orderAttachmentsInitState(),
})
@Injectable()
export class OrderAttachmentsState {
  constructor(
    private readonly documentsApi: DocumentsApiService,
    private readonly internationalApiService: InternationalApiService,
    private readonly workupApiService: WorkupApiService,
    private readonly donorWorkupApiService: DonorWorkupApiService
  ) {}

  @Selector([OrderAttachmentsState])
  static loading(state: OrderAttachmentsStateModel): boolean {
    return state.loading;
  }

  @Selector([OrderAttachmentsState])
  static hasAttachments(state: OrderAttachmentsStateModel): boolean {
    return state.workupId !== null;
  }

  @Selector([OrderAttachmentsState])
  static workupId(state: OrderAttachmentsStateModel): number {
    return state.workupId;
  }

  @Selector([OrderAttachmentsState])
  static attachmentExtensions(state: OrderAttachmentsStateModel): string[] {
    return state.attachmentExtensions;
  }

  @Selector([OrderAttachmentsState])
  static attachments(state: OrderAttachmentsStateModel): DocumentModel[] {
    return state.attachments;
  }

  @Action(LoadOrderAttachmentExtensions)
  loadAttachmentExtensions(
    ctx: StateContext<OrderAttachmentsStateModel>,
    {documentsFeatureType}: LoadOrderAttachmentExtensions
  ) {
    ctx.patchState({
      loading: true,
      attachmentExtensions: [],
    });

    return this.documentsApi.getExtensions(documentsFeatureType).pipe(
      tap(attachmentExtensions => {
        ctx.patchState({
          attachmentExtensions,
          loading: false,
          loaded: true,
        });
      }),
      finalize(() => ctx.patchState({loading: false}))
    );
  }

  @Action(GetWorkupByOrderId)
  getWorkupId(
    ctx: StateContext<OrderAttachmentsStateModel>,
    {orderId, isLegacyOrder, isInternationalFormsAvailable}: GetWorkupByOrderId
  ) {
    if (!orderId) {
      return;
    }

    ctx.patchState({
      workupId: null,
      attachments: [],
      loading: true,
      loaded: false,
    });

    return this.workupApiService.getWorkupAnswers(orderId, true).pipe(
      tap(workupAnswers => {
        ctx.patchState({
          workupId: workupAnswers.workupId,
          loaded: true,
        });
      }),
      filter(workupAnswers => !!workupAnswers.workupId),
      switchMap(workupAnswers => {
        if (!isInternationalFormsAvailable) {
          return EMPTY;
        }

        return ctx.dispatch(new GetWorkupAttachments(workupAnswers.workupId, isLegacyOrder));
      }),
      finalize(() => ctx.patchState({loading: false}))
    );
  }

  @Action(GetNewWorkupIdByOrderId)
  getNewWorkupId(ctx: StateContext<OrderAttachmentsStateModel>, {orderId}: GetNewWorkupIdByOrderId) {
    if (!orderId) {
      return;
    }

    ctx.patchState({
      workupId: null,
      loading: true,
      loaded: false,
    });

    return this.donorWorkupApiService.getWorkupByOrderGuid(orderId, true).pipe(
      tap(workup => {
        ctx.patchState({
          workupId: workup.workupId,
          loaded: true,
        });
      }),
      filter(workup => !!workup.workupId),
      switchMap(workup => ctx.dispatch(new GetWorkupAttachments(workup.workupId))),
      finalize(() => ctx.patchState({loading: false}))
    );
  }

  @Action(GetWorkupAttachments)
  getWorkupAttachments(ctx: StateContext<OrderAttachmentsStateModel>, {workupId, isLegacyOrder}: GetWorkupAttachments) {
    if (!workupId) {
      return;
    }
    ctx.patchState({
      workupId,
      loading: true,
      loaded: false,
    });

    return this.internationalApiService.getAllInternationalFiles(workupId, isLegacyOrder).pipe(
      tap(attachments => {
        ctx.patchState({
          attachments,
          loaded: true,
        });
      }),
      finalize(() => ctx.patchState({loading: false}))
    );
  }

  @Action(DeleteOrderAttachment)
  deleteOrderAttachments(
    ctx: StateContext<OrderAttachmentsStateModel>,
    {workupId, attachmentsIds, isLegacyOrder}: DeleteOrderAttachment
  ) {
    ctx.patchState({
      loading: true,
      workupId,
    });

    return this.internationalApiService.delete(attachmentsIds, isLegacyOrder).pipe(
      tap(() => {
        ctx.patchState({
          loading: false,
        });
      }),
      switchMap(() => {
        return ctx.dispatch(new GetWorkupAttachments(workupId, isLegacyOrder));
      }),
      finalize(() => ctx.patchState({loading: false}))
    );
  }

  @Action(BulkUpdateAttachments)
  bulkUpdateAttachments(
    ctx: StateContext<OrderAttachmentsStateModel>,
    {workupId, attachments, isLegacyOrder}: BulkUpdateAttachments
  ) {
    if (attachments) {
      // call to update the attachments delete or upload
      const attachmentsToAdd = attachments.filter(attachment => attachment.isNew);

      let updateObservables: Observable<any>[] = [];
      if (attachmentsToAdd.length > 0) {
        updateObservables = updateObservables.concat(
          this.internationalApiService.add(attachmentsToAdd, workupId, isLegacyOrder)
        );
      }

      // this.attachments = await this.attachmentApi.getTheAttachments();
      const attachmentsToDelete = attachments.filter(attachment => attachment.isDelete).map(a => a.id as number);
      if (attachmentsToDelete.length > 0) {
        updateObservables = updateObservables.concat(
          this.internationalApiService.delete(attachmentsToDelete, isLegacyOrder)
        );
      }

      if (updateObservables.length) {
        ctx.patchState({
          loading: true,
          workupId,
        });

        return forkJoin(updateObservables).pipe(
          tap(() => {
            ctx.patchState({
              loading: false,
            });
          }),
          switchMap(() => {
            return ctx.dispatch(new GetWorkupAttachments(workupId, isLegacyOrder));
          }),
          finalize(() => ctx.patchState({loading: false}))
        );
      }
    }
  }

  @Action(ClearAttachmentsState)
  clearAttachmentsState(ctx: StateContext<OrderAttachmentsStateModel>) {
    ctx.patchState({
      attachments: [],
    });
  }

  @Action(AddOrderAttachments)
  addOrderAttachments(
    ctx: StateContext<OrderAttachmentsStateModel>,
    {workupId, attachments, isLegacyOrder}: AddOrderAttachments
  ) {
    ctx.patchState({
      loading: true,
      workupId,
    });
    return this.internationalApiService.add(attachments, workupId, isLegacyOrder).pipe(
      tap(() => {
        ctx.patchState({
          loading: false,
        });
      }),
      switchMap(() => {
        return ctx.dispatch(new GetWorkupAttachments(workupId, isLegacyOrder));
      }),
      finalize(() => ctx.patchState({loading: false}))
    );
  }

  @Action(ReassignWorkupAttachments)
  reassignWorkupAttachments(
    ctx: StateContext<OrderAttachmentsStateModel>,
    {workupId, orderGuid, isLegacyOrder}: ReassignWorkupAttachments
  ) {
    ctx.patchState({
      loading: true,
    });
    return this.internationalApiService.reassign(workupId, orderGuid, isLegacyOrder).pipe(
      tap(() => {
        ctx.patchState({
          loading: false,
        });
      }),
      finalize(() => ctx.patchState({loading: false}))
    );
  }

  @Action(DeleteWorkupInProgressAttachments)
  deleteWorkupInProgressAttachments(
    ctx: StateContext<OrderAttachmentsStateModel>,
    {workupId}: DeleteWorkupInProgressAttachments
  ) {
    if (!workupId) {
      return;
    }
    ctx.patchState({
      workupId,
      loading: true,
      loaded: false,
    });

    return this.internationalApiService.getAllInternationalFiles(workupId).pipe(
      switchMap(attachments => {
        if (!attachments || !attachments?.length) {
          return;
        }
        const attIds = attachments.map(attachment => +attachment.id);
        return ctx.dispatch(new DeleteOrderAttachment(workupId, attIds));
      }),
      finalize(() => ctx.patchState({loading: false}))
    );
  }
}
