import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {AnnouncementModel} from '@matchsource/api/announcements';
import {TestableComponent} from '@matchsource/shared/interfaces';
import {TestIds} from 'app/features/announcements/components/announcement/declarations';
import {TranslocoDirective} from '@ngneat/transloco';
import {NgIf} from '@angular/common';

@Component({
  selector: 'ms-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, TranslocoDirective],
})
export class AnnouncementComponent implements TestableComponent {
  @Input()
  announcement: AnnouncementModel;

  readonly testIds = TestIds;

  get display(): boolean {
    return this.announcement?.display;
  }
}
