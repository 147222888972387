import {Pipe, PipeTransform} from '@angular/core';
import {UserRoles} from 'app/features/patient/services/patient-utils.service';

const USER_ROLES_TEXT_MAP: Record<UserRoles, string> = {
  [UserRoles.Tcc]: 'ROLES.TCC',
  [UserRoles.ViewAll]: 'ROLES.VIEW_ALL',
  [UserRoles.CoreCM]: 'ROLES.CORE_CM',
  [UserRoles.Chs]: 'ROLES.CHS',
  [UserRoles.Pe]: 'ROLES.PE',
  [UserRoles.Cp]: 'ROLES.CP',
  [UserRoles.Cpe]: 'ROLES.CPE',
  [UserRoles.ViewPart]: 'ROLES.VIEW_PART',
};

@Pipe({
  name: 'role',
  standalone: true,
})
export class RolePipe implements PipeTransform {
  transform(value: string): string {
    return USER_ROLES_TEXT_MAP[value];
  }
}
