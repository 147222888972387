import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslocoModule} from '@ngneat/transloco';
import {VALIDATORS_PROVIDER} from './services/validators.provider';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslocoModule],
  exports: [ReactiveFormsModule],
})
export class FormModule {
  static forRoot(): ModuleWithProviders<FormModule> {
    return {
      ngModule: FormModule,
      providers: [VALIDATORS_PROVIDER],
    };
  }
}
