import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HlaTypingControllerService, DetailedBLeader, DetailedDpb1Expression} from '@matchsource/api-generated/subject';
import {LigandRequestModel} from '@matchsource/models/patient';
import {map} from 'rxjs/operators';
import {setSingleErrorCustomErrorHandlingContext, ClientErrorCode} from '@matchsource/error-handling/core';
import {KirCenBLigand} from '@matchsource/models/filters';

@Injectable({
  providedIn: 'root',
})
export class TypingsApiService {
  constructor(private readonly api: HlaTypingControllerService) {}

  getLigandStatus(data: LigandRequestModel): Observable<KirCenBLigand> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingDonorData);

    return this.api.getPatientLigandType({body: data}, {responseType: 'text', context: context()}).pipe(
      map(status => {
        return {status} as KirCenBLigand;
      })
    );
  }

  getBLeaderDetailedInfo(allele: string): Observable<DetailedBLeader> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingBLeaderData);

    return this.api.getDetailedBLeaderInfo({allele}, {context: context()});
  }

  getDpb1ExpressionDetailedInfo(allele: string): Observable<DetailedDpb1Expression> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingDPB1Data);

    return this.api.getDetailedDpb1Expression({allele}, {context: context()});
  }
}
