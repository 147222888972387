/* tslint:disable */
/* eslint-disable */
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse, HttpContext} from '@angular/common/http';
import {BaseService} from '../base-service';
import {ApiConfiguration} from '../api-configuration';
import {StrictHttpResponse} from '../strict-http-response';
import {RequestBuilder, AvailableResponseType} from '../request-builder';
import {Observable} from 'rxjs';
import {map, filter} from 'rxjs/operators';

import {AddRecipientRelatedDonorsRequest} from '../models/add-recipient-related-donors-request';
import {CreatedRelatedFamilyMemberDetails} from '../models/created-related-family-member-details';
import {RecipientRelatedDonorStatusAndGuid} from '../models/recipient-related-donor-status-and-guid';
import {RelatedFamilyMemberTypingResults} from '../models/related-family-member-typing-results';
import {UpdateDonorMatchLevel} from '../models/update-donor-match-level';

@Injectable({
  providedIn: 'root',
})
export class RecipientRelatedDonorControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation updateRelatedDonorStatusAndGuid
   */
  static readonly UpdateRelatedDonorStatusAndGuidPath =
    '/recipients/{recipientGuid}/related-donors/{temporaryDonorGuid}/status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRelatedDonorStatusAndGuid()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRelatedDonorStatusAndGuid$Response(
    params: {
      recipientGuid: string;
      temporaryDonorGuid: string;
      context?: HttpContext;
      body: RecipientRelatedDonorStatusAndGuid;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecipientRelatedDonorControllerService.UpdateRelatedDonorStatusAndGuidPath,
      'put'
    );
    if (params) {
      rb.path('recipientGuid', params.recipientGuid, {});
      rb.path('temporaryDonorGuid', params.temporaryDonorGuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({body: undefined}) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateRelatedDonorStatusAndGuid$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRelatedDonorStatusAndGuid(
    params: {
      recipientGuid: string;
      temporaryDonorGuid: string;
      context?: HttpContext;
      body: RecipientRelatedDonorStatusAndGuid;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<void> {
    return this.updateRelatedDonorStatusAndGuid$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateRelatedDonorKit
   */
  static readonly UpdateRelatedDonorKitPath = '/recipients/{recipientGuid}/related-donors/{donorGuid}/kit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRelatedDonorKit()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateRelatedDonorKit$Response(
    params: {
      recipientGuid: string;
      donorGuid: string;
      kitId: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecipientRelatedDonorControllerService.UpdateRelatedDonorKitPath,
      'put'
    );
    if (params) {
      rb.path('recipientGuid', params.recipientGuid, {});
      rb.path('donorGuid', params.donorGuid, {});
      rb.query('kitId', params.kitId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({body: undefined}) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateRelatedDonorKit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateRelatedDonorKit(
    params: {
      recipientGuid: string;
      donorGuid: string;
      kitId: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<void> {
    return this.updateRelatedDonorKit$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateDonorsMatchLevels
   */
  static readonly UpdateDonorsMatchLevelsPath = '/recipients/{recipientGuid}/related-donors/match-level';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDonorsMatchLevels()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDonorsMatchLevels$Response(
    params: {
      recipientGuid: string;
      context?: HttpContext;
      body: Array<UpdateDonorMatchLevel>;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecipientRelatedDonorControllerService.UpdateDonorsMatchLevelsPath,
      'put'
    );
    if (params) {
      rb.path('recipientGuid', params.recipientGuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({body: undefined}) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateDonorsMatchLevels$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDonorsMatchLevels(
    params: {
      recipientGuid: string;
      context?: HttpContext;
      body: Array<UpdateDonorMatchLevel>;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<void> {
    return this.updateDonorsMatchLevels$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getRecipientRelatedDonorsDetails
   */
  static readonly GetRecipientRelatedDonorsDetailsPath = '/recipients/{recipientGuid}/related-donors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecipientRelatedDonorsDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientRelatedDonorsDetails$Response(
    params: {
      recipientGuid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<CreatedRelatedFamilyMemberDetails>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecipientRelatedDonorControllerService.GetRecipientRelatedDonorsDetailsPath,
      'get'
    );
    if (params) {
      rb.path('recipientGuid', params.recipientGuid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<CreatedRelatedFamilyMemberDetails>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRecipientRelatedDonorsDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientRelatedDonorsDetails(
    params: {
      recipientGuid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<CreatedRelatedFamilyMemberDetails>> {
    return this.getRecipientRelatedDonorsDetails$Response(params, requestOptions).pipe(
      map(
        (r: StrictHttpResponse<Array<CreatedRelatedFamilyMemberDetails>>) =>
          r.body as Array<CreatedRelatedFamilyMemberDetails>
      )
    );
  }

  /**
   * Path part for operation addRecipientRelatedDonors
   */
  static readonly AddRecipientRelatedDonorsPath = '/recipients/{recipientGuid}/related-donors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addRecipientRelatedDonors()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addRecipientRelatedDonors$Response(
    params: {
      recipientGuid: string;
      context?: HttpContext;
      body: AddRecipientRelatedDonorsRequest;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecipientRelatedDonorControllerService.AddRecipientRelatedDonorsPath,
      'post'
    );
    if (params) {
      rb.path('recipientGuid', params.recipientGuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({body: undefined}) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addRecipientRelatedDonors$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addRecipientRelatedDonors(
    params: {
      recipientGuid: string;
      context?: HttpContext;
      body: AddRecipientRelatedDonorsRequest;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<void> {
    return this.addRecipientRelatedDonors$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation notifyDonorTypingReceived
   */
  static readonly NotifyDonorTypingReceivedPath =
    '/recipients/{recipientGuid}/related-donors/{donorGuid}/typing-received';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notifyDonorTypingReceived()` instead.
   *
   * This method doesn't expect any request body.
   */
  notifyDonorTypingReceived$Response(
    params: {
      recipientGuid: string;
      donorGuid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecipientRelatedDonorControllerService.NotifyDonorTypingReceivedPath,
      'post'
    );
    if (params) {
      rb.path('recipientGuid', params.recipientGuid, {});
      rb.path('donorGuid', params.donorGuid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({body: undefined}) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `notifyDonorTypingReceived$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notifyDonorTypingReceived(
    params: {
      recipientGuid: string;
      donorGuid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<void> {
    return this.notifyDonorTypingReceived$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getRecipientRelatedDonorDetails
   */
  static readonly GetRecipientRelatedDonorDetailsPath = '/recipients/{recipientGuid}/related-donors/{donorGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecipientRelatedDonorDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientRelatedDonorDetails$Response(
    params: {
      recipientGuid: string;
      donorGuid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<CreatedRelatedFamilyMemberDetails>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RecipientRelatedDonorControllerService.GetRecipientRelatedDonorDetailsPath,
      'get'
    );
    if (params) {
      rb.path('recipientGuid', params.recipientGuid, {});
      rb.path('donorGuid', params.donorGuid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CreatedRelatedFamilyMemberDetails>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRecipientRelatedDonorDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientRelatedDonorDetails(
    params: {
      recipientGuid: string;
      donorGuid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<CreatedRelatedFamilyMemberDetails> {
    return this.getRecipientRelatedDonorDetails$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<CreatedRelatedFamilyMemberDetails>) => r.body as CreatedRelatedFamilyMemberDetails)
    );
  }

  /**
   * Path part for operation getTypingResults
   */
  static readonly GetTypingResultsPath = '/recipients/{recipientGuid}/related-donors/typing-results';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTypingResults()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTypingResults$Response(
    params: {
      recipientGuid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<RelatedFamilyMemberTypingResults>>> {
    const rb = new RequestBuilder(this.rootUrl, RecipientRelatedDonorControllerService.GetTypingResultsPath, 'get');
    if (params) {
      rb.path('recipientGuid', params.recipientGuid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<RelatedFamilyMemberTypingResults>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTypingResults$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTypingResults(
    params: {
      recipientGuid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<RelatedFamilyMemberTypingResults>> {
    return this.getTypingResults$Response(params, requestOptions).pipe(
      map(
        (r: StrictHttpResponse<Array<RelatedFamilyMemberTypingResults>>) =>
          r.body as Array<RelatedFamilyMemberTypingResults>
      )
    );
  }
}
