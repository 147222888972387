import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgxsModule} from '@ngxs/store';
import {environment} from '../../environments/environment';
import {
  BusinessPartiesState,
  BusinessPartiesNotEMDISState,
  CuratedSearchState,
} from '@matchsource/store/business-parties';
import {CountriesState} from '@matchsource/store/countries';
import {CountriesStatesState} from '@matchsource/store/countries-states';
import {NgxsUiRouterPluginModule} from 'ngxs-ui-router';
import {NgxsResetModule} from 'app/ngxs-reset/ngxs-reset.module';
import {CoordinatorsState} from 'app/store/coordinators/coordinators.state';
import {RacesState} from '@matchsource/store/races';
import {EthnicityState} from '@matchsource/store/ethnicity';
import {SecondaryCodesState} from 'app/store/secondary-codes/secondary-codes.state';
import {SearchesState} from 'app/store/searches/searches.state';
import {CmvState} from '@matchsource/store/cmv';
import {PopulationRacesState} from 'app/store/population-races/population-races.state';
import {PermissionsState} from '@matchsource/store/permissions';
import {AutoSaveState} from '@matchsource/store/auto-save';
import {LanguagesState} from 'app/store/languages/languages.state';
import {TransplantTimelineState} from 'app/store/transplant-timeline/transplant-timeline.state';
import {PreferredProductsState} from 'app/store/preferred-products/preferred-products.state';
import {BloodTypesState} from 'app/store/blood-types/blood-types.state';
import {CartState} from '@matchsource/store/cart';
import {SuppliersState} from 'app/store/suppliers/suppliers.state';
import {HaplogicSourcesState} from 'app/store/haplogic-sources/haplogic-sources.state';
import {CloseReasonsState} from 'app/store/close-reasons/close-reasons.state';
import {MatchPreferencesState} from 'app/store/match-preferences/match-preferences.state';
import {CcrState} from '@matchsource/store/ccr';
import {OrderAttachmentsState} from '@matchsource/store/attachments';
import {DsaState} from 'app/store/dsa/dsa.state';
import {DialogsState} from 'app/store/dialogs/dialogs.state';
import {SourceHlaHistoriesState} from 'app/store/source-hla-histories/source-hla-histories.state';
import {SearchStatesState} from '@matchsource/store/search-states';
import {WorkupsInProgressState} from '@matchsource/store/workup';
import {OplState} from 'app/store/opl/opl.state';
import {VaccinationsState} from 'app/store/vaccinations/vaccinations.state';
import {WorkupListState} from '@matchsource/store/workup-list';
import {DonorWorkupInProgressState} from '@matchsource/store/donor-workup-in-progress';
import {KitOrderLabRefsStateState} from 'app/store/kit-order-lab-refs/kit-order-lab-refs.state';
import {SmartyStretsAutocompleteState} from './smarty-streets/smarty-streets-autocomplete.state';
import {SmartyStreetsValidationState} from 'app/store/smarty-streets/smarty-streets-validate.state';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {RelatedFamilyMembersAddressesState} from 'app/store/related-family-members/related-family-members-addresses.state';
import {AnnouncementsState} from 'app/store/announcements/announcements.state';
import {SpinnerState} from '@matchsource/spinner';
import {PatientStatusState} from '@matchsource/store/patient-status';
import {LociState} from '@matchsource/store/loci';
import {
  PatientActualPTRState,
  PatientCloseHistoryState,
  PatientState,
  PatientTransferHistoryState,
  PatientTransplantTimelineHistoryState,
} from '@matchsource/store/patient';
import {DiseasesState} from '@matchsource/store/diseases';
import {SourceEthnicityRacesState} from '@matchsource/store/source-ethnicity-races';
import {GendersState} from '@matchsource/store/genders';
import {IdentityVerificationState} from '@matchsource/store/identity-verification';
import {DiseaseStagesState} from '@matchsource/store/disease-stages';
import {ClinicalRationaleState} from '@matchsource/store/clinical-rationale';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgxWebstorageModule,
    NgxsResetModule.forRoot(),
    NgxsModule.forRoot(
      [
        AnnouncementsState,
        BusinessPartiesState,
        BusinessPartiesNotEMDISState,
        CuratedSearchState,
        SpinnerState,
        CountriesState,
        CountriesStatesState,
        CartState,
        CoordinatorsState,
        RacesState,
        EthnicityState,
        SecondaryCodesState,
        SearchesState,
        DiseasesState,
        CmvState,
        CcrState,
        PopulationRacesState,
        SourceEthnicityRacesState,
        LanguagesState,
        TransplantTimelineState,
        DiseaseStagesState,
        PreferredProductsState,
        PermissionsState,
        AutoSaveState,
        BloodTypesState,
        LociState,
        SuppliersState,
        IdentityVerificationState,
        HaplogicSourcesState,
        MatchPreferencesState,
        CloseReasonsState,
        OrderAttachmentsState,
        DsaState,
        DialogsState,
        SourceHlaHistoriesState,
        SearchStatesState,
        WorkupsInProgressState,
        OplState,
        VaccinationsState,
        WorkupListState,
        DonorWorkupInProgressState,
        GendersState,
        PatientState,
        PatientCloseHistoryState,
        PatientTransferHistoryState,
        PatientTransplantTimelineHistoryState,
        PatientActualPTRState,
        KitOrderLabRefsStateState,
        SmartyStretsAutocompleteState,
        SmartyStreetsValidationState,
        PatientStatusState,
        RelatedFamilyMembersAddressesState,
        ClinicalRationaleState,
      ],
      {
        developmentMode: !environment.production,
        selectorOptions: {
          injectContainerState: false,
          suppressErrors: false,
        },
        compatibility: {
          strictContentSecurityPolicy: true,
        },
      }
    ),
    NgxsUiRouterPluginModule,
    environment.ngxsPlugins,
  ],
  exports: [NgxsModule],
})
export class StoreModule {}
