import {OrderableStatusModel} from '@matchsource/models/order-shared';

export enum SourceProductType {
  Nmdp = 'NMDP',

  Bmdw = 'BMDW',

  Bmdwd = 'BMDWD',

  Coop = 'COOP',

  Wmda = 'WMDA',

  Mbr = 'MBR',
}

export enum SourceList {
  Nmdp = 'NMDP',

  Wmda = 'WMDA',

  Coop = 'COOP',
}

export interface Locus {
  matchGrade1: string;

  sign1: string;

  matchGrade2: string;

  sign2: string;

  matchGradeSign1: string;

  matchGradeSign2: string;
}

export interface SourceBaseInfoModel {
  bpGuid: MsApp.Guid;
  displayGrid: string;
  pid: string;
  emdisCoop: boolean;
  emdisId: string;
  grId: MsApp.Guid;
  guid: MsApp.Guid;
  localId: string;
  nmdp: boolean;
  nmdpId: string;
  productType: string;
  id: MsApp.Guid;
  type: string;
  status: string;
  releaseDate: string;
}

export interface SourceCoreModel {
  isABOnly?: boolean;
  bpGuid: MsApp.Guid;
  id: MsApp.Guid;
  productType: string;
  type: 'cord' | 'donor' | 'bdp' | '';
  status: string;
  statusOverride?: string;
  previousCT: string;
}

export interface SourceBaseModel extends SourceCoreModel {
  age: number;
  bLeader: string;
  bloodTypeCode: string;
  bloodTypeRh: string;
  bpId: string;
  bpName: string;
  cmv: string;
  cmvDate: string;
  contactType: string;
  country: string;
  countryCode: string;
  countryName: string;
  doingBusinessAsName: string;
  ebsMissing: boolean;
  ethnicity: string;
  ethnicityCode: string;
  grid: MsApp.Guid;
  guid: MsApp.Guid;
  guidPart: MsApp.Guid;
  isWmda: boolean;
  lastContactDate: string;
  list: string;
  localId: string;
  orderableStatus?: OrderableStatusModel;
  orderable: boolean;
  orderableForCmOnly: boolean;
  orderableReasons: string[];
  orderableRecipientClosed: boolean;
  orderableSameDayOrder: boolean;
  duplicateRecipient: boolean;
  pendingAnotherRequest: boolean;
  pendingThisRequest: boolean;
  pregnancies: number | string;
  race: string;
  races: string[];
  ref: number;
  regDate: string;
  restricted: boolean;
  rh: string;
  sex: string;
  statusClass: string;
  statusLabel: string;
  weight: string | number;
  plasmaReduced: string;
  isEmdisCoop: boolean;
  birthDate: string;
}

export interface EthnicityRacesModel {
  ethnicityCode: string;
  primaryRaceCode: string;
  raceCodes: string[];
}

export type SourceType = 'DONOR' | 'CORD' | 'BDP';

export enum SourceTypes {
  Donor = 'donor',
  Cord = 'cord',
  Biobank = 'bdp',
}

export interface WmdaDonorData {
  requestStatus: string;
  requestDate: MsApp.DateString;
  requestDateAsDate: MsApp.DateTimeString;
  requestedDate: MsApp.DateString;
  resultsReceivedDate: MsApp.DateString;
}

export interface SourceListModel {
  id: MsApp.Guid;
  pid: string;
  ref: number;
  grid: string;
  localId: string;
  status: string;
  statusOverride?: string;
  statusClass: string;
  statusLabel: string;
  age: number;
  sex: string;
  rh: string;
  bloodTypeCode: string;
  bloodTypeRh: string;
  cmv?: string;
  cmvDate: string;
  pregnancies?: string | number;
  weight: string | number;
  primaryRaceCode: string;
  race: string;
  races: any;
  ethnicityCode: string;
  ethnicity: string;
  regDate: string;
  bpGuid: string;
  bpId: string;
  bpName: string;
  doingBusinessAsName: string;
  country: string;
  countryCode: string;
  countryName: string;
  previousCT: string;
  lastContactDate: string;
  contactType: string;
  productType: string;
  list: string;
  orderableStatus?: OrderableStatusModel;
  orderable: boolean;
  orderableReasons: string[];
  orderableForCmOnly: boolean;
  orderableSameDayOrder: boolean;
  orderableRecipientClosed: boolean;
  duplicateRecipient: boolean;
  isWmda: boolean;
  isEmdisCoop: boolean;
  guidPart: string;
  guid: MsApp.Guid;
  orderNum?: number;
  ptr?: any;
  pendingThisRequest: boolean;
  pendingAnotherRequest: boolean;
  availabilityDate: string;
  availableDate?: MsApp.DateString;
  type: 'donor' | 'cord' | 'bdp';
  previousDonation?: number;
  isABOnly?: boolean;
  birthDate: string;
  raceEth: string;
  list_bpId: string;
  age_sex: string;
  lastContactDate_contactType: string;
  cmv_bloodTypeRh?: string;
  pregnancies_previousCT?: string;
  availabilityDate_regDate: string;
  seq?: number;
  populationCode: string;
  matching: any;
  wmdaData?: WmdaDonorData;
  displayGrid?: MsApp.Guid;
  tncWeight: string | number;
  cd34FCWeight: string | number;
  tncFrozen: number;
  cd34FrozenCell: number;
  licenseStatus: string;
  seg: number;
  hgb: string;
  hgbTranslated: string;
  nrbc: number;
  matCatg8: number;
  matCatg6: number;
  finalProdVol: number;
  cordCenterIdentifier?: string;
  DOB_SEX: string;
  M_CAT_GROUPED: string;
  tncWeight_cd34FCWeight: string;
  tncFrozen_cd34FrozenCell: string;
  final_prod_vol_ABO_RH: string;
  previousCT_licensure: string;
  hgb_nRBC: string;
  nrbcFormatted: string;
  hgbFormatted: string;
  availabilityDate_of_seg: string;
  dpb1TCEMatch_ccr5: string;
  eligibilityCode: string;
  CordListModel?: string;
  plasmaReduced: string;
  rbcReduced: string;
  ccr5Genotype?: string;
}

export const SOURCE_PRODUCT_TYPE_LIST_MAP: Record<SourceProductType, SourceList> = {
  [SourceProductType.Nmdp]: SourceList.Nmdp,
  [SourceProductType.Bmdw]: SourceList.Wmda,
  [SourceProductType.Bmdwd]: SourceList.Wmda,
  [SourceProductType.Wmda]: SourceList.Wmda,
  [SourceProductType.Coop]: SourceList.Coop,
  [SourceProductType.Mbr]: SourceList.Nmdp,
};

export enum BloodSourceType {
  Donors = 'donors',
  Cords = 'cords',
  Biobanks = 'biobanks',
  Bdps = 'bdps',
  AbOnly = 'abonly',
}

export enum UppercaseSourceType {
  Donor = 'DONOR',
  Cord = 'CORD',
  Biobank = 'BDP',
}

export const ION_AS_ID_BPTYPES = ['REG', 'BMD', 'CDS', 'BMC', 'CCS'];
