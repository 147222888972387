import {Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, Inject} from '@angular/core';
import {ErrorMessageTranslationKeyPipe} from '../../pipes/error-message.pipe';
import {
  ModalCloseDirective,
  ButtonDirective,
  ButtonPanelComponent,
  DynamicDialogComponent,
  DialogService,
  ToastService,
  FormFieldComponent,
  FormatPipe,
} from '@matchsource/nmdp-ui';
import {NgIf, DOCUMENT, NgTemplateOutlet} from '@angular/common';
import {TranslocoDirective, TranslocoPipe, translate} from '@ngneat/transloco';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  ErrorDialogData,
  ERROR_DIALOG_ID,
  BasicErrorHttpResponse,
  DiagnosticsData,
} from '@matchsource/error-handling/core';
import {DynamicErrorDialogFlags, DynamicErrorDialogFormControls} from './declarations';
import {IncidentCreationApiService} from '@matchsource/api/incident-creation';
import {IncidentCreationModel} from '@matchsource/api/incident-creation';
import {UserService} from '@matchsource/core';
import {formatCodeToHtml, stringToBase64} from '@matchsource/utils';

@Component({
  selector: 'ms-dynamic-error-dialog',
  templateUrl: './dynamic-error-dialog.component.html',
  styleUrls: ['./dynamic-error-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DynamicDialogComponent,
    TranslocoDirective,
    NgIf,
    ButtonPanelComponent,
    ButtonDirective,
    ModalCloseDirective,
    TranslocoPipe,
    FormatPipe,
    ErrorMessageTranslationKeyPipe,
    FormFieldComponent,
    ReactiveFormsModule,
    FormsModule,
    NgTemplateOutlet,
  ],
})
export class DynamicErrorDialogComponent implements OnInit {
  basicErrorData = this as ErrorDialogData;
  flags: DynamicErrorDialogFlags = {};
  form: FormGroup<DynamicErrorDialogFormControls>;
  hiddenErrorInfo = '';

  constructor(
    private readonly userService: UserService,
    private readonly fb: FormBuilder,
    private readonly dialogService: DialogService,
    private readonly cd: ChangeDetectorRef,
    private readonly incidentCreationApiService: IncidentCreationApiService,
    private readonly toastService: ToastService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  ngOnInit(): void {
    this.form = this.createForm();
    this.setRoleFlags();
    this.checkCanCreateIncident();
    this.setHiddenErrorInfo();
  }

  onSubmitIncident(): void {
    const httpResponse = this.basicErrorData.errorHttpResponse;
    const errorMessage = this.getErrorMessage(httpResponse, this.basicErrorData.diagnosticsData);
    const base64EncodedErrorMessage = stringToBase64(errorMessage);
    const model: IncidentCreationModel = {
      currentUserLocation: this.document.defaultView.location.toString(),
      errorHttpCode: httpResponse?.status,
      errorMessage: base64EncodedErrorMessage,
      errorTraceId: this.basicErrorData.traceId,
      errorUrl: httpResponse?.url,
      type: 'TECH_DIFFICULTIES',
      userDescription: this.form.value.comments,
    };
    this.incidentCreationApiService.createIncident(model).subscribe({
      next: () => this.processCreateIncidentSucceeded(),
      error: () => this.processCreateIncidentError(),
    });
  }

  private checkCanCreateIncident(): void {
    this.flags.showLoadingDetailsTemplate = true;
    this.incidentCreationApiService.canCreateIncident().subscribe({
      next: canCreate => this.processCanCreateIncidentResult(canCreate),
      error: () => this.processCanCreateIncidentResult(true),
    });
  }

  private processCanCreateIncidentResult(canCreateIncident: boolean): void {
    this.flags = {
      ...this.flags,
      showCreateIncidentTemplate: canCreateIncident,
      showCantCreateIncidentsTooOftenTemplate: !canCreateIncident,
      showSubmitErrorTemplate: false,
      showLoadingDetailsTemplate: false,
    };
    this.cd.detectChanges();
  }

  private processCreateIncidentSucceeded(): void {
    this.dialogService.close(ERROR_DIALOG_ID);
    this.toastService.success({
      message: formatCodeToHtml(translate('TECHNICAL_DIFFICULTIES_POPUP.THE_INCIDENT_WAS_SUCCESSFULLY_SUBMITTED')),
      enableHtml: true,
    });
  }

  private processCreateIncidentError(): void {
    this.flags = {
      ...this.flags,
      showCantCreateIncidentsTooOftenTemplate: false,
      showCreateIncidentTemplate: false,
      showSubmitErrorTemplate: true,
      showLoadingDetailsTemplate: false,
    };
    this.toastService.error({
      message: formatCodeToHtml(translate('TECHNICAL_DIFFICULTIES_POPUP.THE_INCIDENT_WAS_NOT_SUBMITTED')),
      enableHtml: true,
    });
    this.cd.detectChanges();
  }

  private createForm(): FormGroup<DynamicErrorDialogFormControls> {
    const form = this.fb.group<DynamicErrorDialogFormControls>({
      comments: new FormControl(null),
    });
    return form;
  }

  private setRoleFlags(): void {
    const isTc = this.userService.isTCC();
    const isCp = this.userService.isCP();
    const isInternalUser = !isTc && !isCp;
    // Our "is..." flags are mutually exclusive even though the user can technically have more than one role
    this.flags = {...this.flags, isTcUser: false, isCpUser: false, isInternalUser: false};
    // The user can have more than one role so we are using precedence, which is TC-CP-Internal (others)
    if (isTc) {
      this.flags.isTcUser = true;
    } else if (isCp) {
      this.flags.isCpUser = true;
    } else if (isInternalUser) {
      this.flags.isInternalUser = true;
    }
  }

  private setHiddenErrorInfo(): void {
    const httpResponse = this.basicErrorData.errorHttpResponse;
    const errorMessage = this.getErrorMessage(httpResponse, this.basicErrorData.diagnosticsData);
    this.hiddenErrorInfo = [
      `Trace ID: ${this.basicErrorData.traceId}`,
      `Error URL: ${httpResponse?.url}`,
      `HTTP error code: ${httpResponse?.status}`,
      `Error message: ${errorMessage.substring(0, 1000)}`,
    ].join(' , ');
  }

  getErrorMessage(httpResponse?: BasicErrorHttpResponse, diagnosticsData?: DiagnosticsData): string {
    let errorMessage = [httpResponse?.message, JSON.stringify(httpResponse?.error)].join(' ; ');
    const newLine = '\n';
    if (diagnosticsData) {
      const diagnosticsLines = [
        `networkCheckHttpResponse: ${diagnosticsData.networkCheckHttpResponse}`,
        `retryAttempted: ${diagnosticsData.retryAttempted}`,
      ];
      if (diagnosticsData.retryAttempted) {
        diagnosticsLines.push(`retryHttpResponse: ${diagnosticsData.retryHttpResponse}`);
      }
      errorMessage += newLine + diagnosticsLines.join(newLine);
    }
    return errorMessage;
  }
}
