import {State, Action, StateContext, Selector} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {tap, finalize} from 'rxjs/operators';
import {Observable} from 'rxjs';

import {AddressValidationControllerService} from '@matchsource/api-generated/common';
import {
  ClearSmartyValidateAddresses,
  SmartyValidateAddresses,
} from 'app/store/smarty-streets/smarty-streets-validate.actions';
import {ValidationSmartAddressResult} from '@matchsource/models/smarty-street';

export interface SmartyStreetsValidationStateModel {
  loading: boolean;
  loaded: boolean;
  addressValidationResponse: ValidationSmartAddressResult;
}

const initState = (): SmartyStreetsValidationStateModel => ({
  loading: false,
  loaded: false,
  addressValidationResponse: null,
});

@State<SmartyStreetsValidationStateModel>({
  name: 'SmartyStreetsValidationStateStateModel',
  defaults: initState(),
})
@Injectable()
export class SmartyStreetsValidationState {
  constructor(private readonly addressValidationControllerService: AddressValidationControllerService) {}

  @Selector([SmartyStreetsValidationState])
  static suggestion(state: SmartyStreetsValidationStateModel) {
    return state.addressValidationResponse;
  }

  @Selector([SmartyStreetsValidationState])
  static loaded(state: SmartyStreetsValidationStateModel) {
    return state.loaded;
  }

  @Selector([SmartyStreetsValidationState])
  static inProgressOrCompleted(state: SmartyStreetsValidationStateModel): boolean {
    return state.loaded || state.loading;
  }

  @Action(SmartyValidateAddresses)
  smartyValidateAddresses(
    ctx: StateContext<SmartyStreetsValidationStateModel>,
    {addressBase}: SmartyValidateAddresses
  ): Observable<ValidationSmartAddressResult> {
    ctx.patchState({
      loading: true,
    });

    return this.addressValidationControllerService
      .validateUsAddress({
        body: {
          ...addressBase,
        },
      })
      .pipe(
        tap(addressValidationResponse => {
          ctx.patchState({
            addressValidationResponse,
            loaded: true,
          });
        }),
        finalize(() => ctx.patchState({loading: false}))
      );
  }

  @Action(ClearSmartyValidateAddresses)
  clearSmartyValidateAddresses(ctx: StateContext<SmartyStreetsValidationStateModel>) {
    ctx.patchState(initState());
  }
}
