import {Serializer} from '@matchsource/api-utils';
import {OrderModel, ORDER_STATES} from '@matchsource/models/order';
import {OrderDTO} from './order.interfaces';
import {formatDate, formatViewDate, formatViewDateLocal, toDateTime} from '@matchsource/date';
import {formatDateWithoutUTC} from '@matchsource/utils';
import {HLA_ORDER} from '@matchsource/models/hla';

const getServiceOrder = (name: string): number => {
  // It is assumed that the name of the locus is located at the beginning of the service name.
  // The locus consists of 4 letters if its name starts with the letter D.
  const locusName = name[0] !== 'D' ? name[0] : name.slice(0, 4);
  return HLA_ORDER[locusName] || 0;
};

const CRYO = 'CRYO';

export const orderSerializer: Serializer<OrderModel, Partial<OrderDTO>> = {
  fromDTO(input: Partial<OrderDTO>): OrderModel {
    return {
      id: input.id,
      type: input.type,
      typeName: input.orderTypeName,
      status: ORDER_STATES[input.orderStatus],
      newWorkupInd: input.newWorkupInd,
      requestedItems: (input.requestedItems || []).sort(
        (first, second) => getServiceOrder(first) - getServiceOrder(second)
      ),
      requestDate: formatViewDateLocal(input.requestDate),
      requestDateAsDate: toDateTime(input.requestDate),
      requestedDate: formatViewDate(input.requestedDate),
      releaseReason: input.releaseReason,
      infusion: formatDate(input.infusion) || null,
      infusionDate: input.infusion ? formatDateWithoutUTC(input.infusion) : null,
      activationDate: formatDate(input.activationDate),
      createDate: input.createDate,
      fulfillmentOrderGuid: input.fulfillmentOrderGuid,
      recipientGuid: input.recipientGuid,
      recipientTcId: input.recipientTcId,
      releaseDate: formatViewDateLocal(input.releaseDate),
      serviceCodes: input.serviceCodes,
      isCryoPreserved:
        input.foServiceCodes && Array.isArray(input.foServiceCodes) ? input.foServiceCodes.indexOf(CRYO) > -1 : false,
      sourceGuid: input.sourceGuid,
      sourceType: input.sourceType.toLowerCase(),
      isSubsequent: input.subsequent || false,
      apheresisCenter: input.apheresisCenter,
      collectionCenter: input.collectionCenter,
      transportationMethod: input.transportationMethod,
      gamidaOmisirgeOrderInd: input.gamidaOmisirgeOrderInd,
    };
  },
};
