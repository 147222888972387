/* tslint:disable */
/* eslint-disable */
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse, HttpContext} from '@angular/common/http';
import {BaseService} from '../base-service';
import {ApiConfiguration} from '../api-configuration';
import {StrictHttpResponse} from '../strict-http-response';
import {RequestBuilder, AvailableResponseType} from '../request-builder';
import {Observable} from 'rxjs';
import {map, filter} from 'rxjs/operators';

import {CaseResourceDto} from '../models/case-resource-dto';
import {CaseResourceExtendedDto} from '../models/case-resource-extended-dto';
import {ResourceRightDto} from '../models/resource-right-dto';
import {ResourceRoleDto} from '../models/resource-role-dto';

@Injectable({
  providedIn: 'root',
})
export class CaseResourceControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation updateDefaultTc
   */
  static readonly UpdateDefaultTcPath = '/case-resources/tcs/default';

  /**
   * This method updates a default tc for multi tc user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDefaultTc()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateDefaultTc$Response(
    params?: {
      bpGuid?: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CaseResourceControllerService.UpdateDefaultTcPath, 'put');
    if (params) {
      rb.query('bpGuid', params.bpGuid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({body: undefined}) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method updates a default tc for multi tc user
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateDefaultTc$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateDefaultTc(
    params?: {
      bpGuid?: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<void> {
    return this.updateDefaultTc$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getActiveRole
   */
  static readonly GetActiveRolePath = '/case-resources/{userName}/role/active';

  /**
   * This method retrieves active role for multi role user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActiveRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getActiveRole$Response(
    params: {
      userName: string;
      context?: HttpContext;
      body: Array<string>;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<CaseResourceDto>> {
    const rb = new RequestBuilder(this.rootUrl, CaseResourceControllerService.GetActiveRolePath, 'post');
    if (params) {
      rb.path('userName', params.userName, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CaseResourceDto>;
        })
      );
  }

  /**
   * This method retrieves active role for multi role user
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getActiveRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getActiveRole(
    params: {
      userName: string;
      context?: HttpContext;
      body: Array<string>;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<CaseResourceDto> {
    return this.getActiveRole$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<CaseResourceDto>) => r.body as CaseResourceDto)
    );
  }

  /**
   * Path part for operation getResourceRoles
   */
  static readonly GetResourceRolesPath = '/case-resources/roles';

  /**
   * This method retrieves resource roles
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getResourceRoles()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getResourceRoles$Response(
    params: {
      context?: HttpContext;
      body: Array<string>;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<ResourceRoleDto>>> {
    const rb = new RequestBuilder(this.rootUrl, CaseResourceControllerService.GetResourceRolesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ResourceRoleDto>>;
        })
      );
  }

  /**
   * This method retrieves resource roles
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getResourceRoles$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getResourceRoles(
    params: {
      context?: HttpContext;
      body: Array<string>;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<ResourceRoleDto>> {
    return this.getResourceRoles$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<ResourceRoleDto>>) => r.body as Array<ResourceRoleDto>)
    );
  }

  /**
   * Path part for operation getCaseResourcesByIds
   */
  static readonly GetCaseResourcesByIdsPath = '/case-resources/list';

  /**
   * This method retrieves case resources by a list of IDs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCaseResourcesByIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCaseResourcesByIds$Response(
    params: {
      context?: HttpContext;
      body: Array<number>;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<
    StrictHttpResponse<{
      [key: string]: CaseResourceDto;
    }>
  > {
    const rb = new RequestBuilder(this.rootUrl, CaseResourceControllerService.GetCaseResourcesByIdsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            [key: string]: CaseResourceDto;
          }>;
        })
      );
  }

  /**
   * This method retrieves case resources by a list of IDs
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCaseResourcesByIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCaseResourcesByIds(
    params: {
      context?: HttpContext;
      body: Array<number>;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<{
    [key: string]: CaseResourceDto;
  }> {
    return this.getCaseResourcesByIds$Response(params, requestOptions).pipe(
      map(
        (
          r: StrictHttpResponse<{
            [key: string]: CaseResourceDto;
          }>
        ) =>
          r.body as {
            [key: string]: CaseResourceDto;
          }
      )
    );
  }

  /**
   * Path part for operation getCaseResourceByRoleAndBp
   */
  static readonly GetCaseResourceByRoleAndBpPath = '/case-resources';

  /**
   * This method retrieves case resources by role code and bpGuid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCaseResourceByRoleAndBp()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCaseResourceByRoleAndBp$Response(
    params: {
      roleCode: string;
      bpGuid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<CaseResourceDto>>> {
    const rb = new RequestBuilder(this.rootUrl, CaseResourceControllerService.GetCaseResourceByRoleAndBpPath, 'get');
    if (params) {
      rb.query('roleCode', params.roleCode, {});
      rb.query('bpGuid', params.bpGuid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<CaseResourceDto>>;
        })
      );
  }

  /**
   * This method retrieves case resources by role code and bpGuid
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCaseResourceByRoleAndBp$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCaseResourceByRoleAndBp(
    params: {
      roleCode: string;
      bpGuid: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<CaseResourceDto>> {
    return this.getCaseResourceByRoleAndBp$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<CaseResourceDto>>) => r.body as Array<CaseResourceDto>)
    );
  }

  /**
   * Path part for operation getCaseResourceById
   */
  static readonly GetCaseResourceByIdPath = '/case-resources/{id}';

  /**
   * This method retrieves a case resource by its ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCaseResourceById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCaseResourceById$Response(
    params: {
      id: number;
      ignoreEndDate?: boolean;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<CaseResourceDto>> {
    const rb = new RequestBuilder(this.rootUrl, CaseResourceControllerService.GetCaseResourceByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('ignoreEndDate', params.ignoreEndDate, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CaseResourceDto>;
        })
      );
  }

  /**
   * This method retrieves a case resource by its ID
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCaseResourceById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCaseResourceById(
    params: {
      id: number;
      ignoreEndDate?: boolean;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<CaseResourceDto> {
    return this.getCaseResourceById$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<CaseResourceDto>) => r.body as CaseResourceDto)
    );
  }

  /**
   * Path part for operation getExtendedCaseResourceById
   */
  static readonly GetExtendedCaseResourceByIdPath = '/case-resources/{id}/extended';

  /**
   * This method retrieves an extended case resource by its ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExtendedCaseResourceById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExtendedCaseResourceById$Response(
    params: {
      id: number;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<CaseResourceExtendedDto>> {
    const rb = new RequestBuilder(this.rootUrl, CaseResourceControllerService.GetExtendedCaseResourceByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CaseResourceExtendedDto>;
        })
      );
  }

  /**
   * This method retrieves an extended case resource by its ID
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getExtendedCaseResourceById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExtendedCaseResourceById(
    params: {
      id: number;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<CaseResourceExtendedDto> {
    return this.getExtendedCaseResourceById$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<CaseResourceExtendedDto>) => r.body as CaseResourceExtendedDto)
    );
  }

  /**
   * Path part for operation getResourceRoles1
   */
  static readonly GetResourceRoles1Path = '/case-resources/user/roles';

  /**
   * This method retrieves all roles assigned user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getResourceRoles1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResourceRoles1$Response(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<ResourceRoleDto>>> {
    const rb = new RequestBuilder(this.rootUrl, CaseResourceControllerService.GetResourceRoles1Path, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ResourceRoleDto>>;
        })
      );
  }

  /**
   * This method retrieves all roles assigned user
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getResourceRoles1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResourceRoles1(
    params?: {
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<ResourceRoleDto>> {
    return this.getResourceRoles1$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<ResourceRoleDto>>) => r.body as Array<ResourceRoleDto>)
    );
  }

  /**
   * Path part for operation getCaseResourceByAdIdForSsaRequest
   */
  static readonly GetCaseResourceByAdIdForSsaRequestPath = '/case-resources/ssa/ad';

  /**
   * This method retrieves a case resource by AD ID for SSA request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCaseResourceByAdIdForSsaRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCaseResourceByAdIdForSsaRequest$Response(
    params: {
      adId: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<CaseResourceDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      CaseResourceControllerService.GetCaseResourceByAdIdForSsaRequestPath,
      'get'
    );
    if (params) {
      rb.query('adId', params.adId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CaseResourceDto>;
        })
      );
  }

  /**
   * This method retrieves a case resource by AD ID for SSA request
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCaseResourceByAdIdForSsaRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCaseResourceByAdIdForSsaRequest(
    params: {
      adId: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<CaseResourceDto> {
    return this.getCaseResourceByAdIdForSsaRequest$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<CaseResourceDto>) => r.body as CaseResourceDto)
    );
  }

  /**
   * Path part for operation getCaseResourceByRole
   */
  static readonly GetCaseResourceByRolePath = '/case-resources/roles/{roleCode}';

  /**
   * This method retrieves case resources by role code
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCaseResourceByRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCaseResourceByRole$Response(
    params: {
      roleCode: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<CaseResourceDto>>> {
    const rb = new RequestBuilder(this.rootUrl, CaseResourceControllerService.GetCaseResourceByRolePath, 'get');
    if (params) {
      rb.path('roleCode', params.roleCode, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<CaseResourceDto>>;
        })
      );
  }

  /**
   * This method retrieves case resources by role code
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCaseResourceByRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCaseResourceByRole(
    params: {
      roleCode: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<CaseResourceDto>> {
    return this.getCaseResourceByRole$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<CaseResourceDto>>) => r.body as Array<CaseResourceDto>)
    );
  }

  /**
   * Path part for operation getResourceRights
   */
  static readonly GetResourceRightsPath = '/case-resources/rights/{roleCode}';

  /**
   * This method retrieves resource rights by role code
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getResourceRights()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResourceRights$Response(
    params: {
      roleCode: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<ResourceRightDto>>> {
    const rb = new RequestBuilder(this.rootUrl, CaseResourceControllerService.GetResourceRightsPath, 'get');
    if (params) {
      rb.path('roleCode', params.roleCode, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ResourceRightDto>>;
        })
      );
  }

  /**
   * This method retrieves resource rights by role code
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getResourceRights$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResourceRights(
    params: {
      roleCode: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<ResourceRightDto>> {
    return this.getResourceRights$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<ResourceRightDto>>) => r.body as Array<ResourceRightDto>)
    );
  }

  /**
   * Path part for operation getCaseResourceByAdId
   */
  static readonly GetCaseResourceByAdIdPath = '/case-resources/ad';

  /**
   * This method retrieves a case resource by AD ID if it exists
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCaseResourceByAdId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCaseResourceByAdId$Response(
    params: {
      adId: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<CaseResourceDto>> {
    const rb = new RequestBuilder(this.rootUrl, CaseResourceControllerService.GetCaseResourceByAdIdPath, 'get');
    if (params) {
      rb.query('adId', params.adId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CaseResourceDto>;
        })
      );
  }

  /**
   * This method retrieves a case resource by AD ID if it exists
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCaseResourceByAdId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCaseResourceByAdId(
    params: {
      adId: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<CaseResourceDto> {
    return this.getCaseResourceByAdId$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<CaseResourceDto>) => r.body as CaseResourceDto)
    );
  }

  /**
   * Path part for operation getCaseResourceRolesByAdId
   */
  static readonly GetCaseResourceRolesByAdIdPath = '/case-resources/ad/roles';

  /**
   * This method retrieves case resource roles by AD ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCaseResourceRolesByAdId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCaseResourceRolesByAdId$Response(
    params: {
      adId: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, CaseResourceControllerService.GetCaseResourceRolesByAdIdPath, 'get');
    if (params) {
      rb.query('adId', params.adId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<string>>;
        })
      );
  }

  /**
   * This method retrieves case resource roles by AD ID
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCaseResourceRolesByAdId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCaseResourceRolesByAdId(
    params: {
      adId: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<string>> {
    return this.getCaseResourceRolesByAdId$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation getAllExtendedCaseResourcesByAdId
   */
  static readonly GetAllExtendedCaseResourcesByAdIdPath = '/case-resources/ad/extended/list';

  /**
   * This method retrieves all extended case resources by adId
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllExtendedCaseResourcesByAdId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllExtendedCaseResourcesByAdId$Response(
    params: {
      adId: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<StrictHttpResponse<Array<CaseResourceExtendedDto>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      CaseResourceControllerService.GetAllExtendedCaseResourcesByAdIdPath,
      'get'
    );
    if (params) {
      rb.query('adId', params.adId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<CaseResourceExtendedDto>>;
        })
      );
  }

  /**
   * This method retrieves all extended case resources by adId
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllExtendedCaseResourcesByAdId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllExtendedCaseResourcesByAdId(
    params: {
      adId: string;
      context?: HttpContext;
    },
    requestOptions: {
      context?: HttpContext;
      setHeaders?: {
        [name: string]: string | string[];
      };
      responseType?: AvailableResponseType;
    } = {}
  ): Observable<Array<CaseResourceExtendedDto>> {
    return this.getAllExtendedCaseResourcesByAdId$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<CaseResourceExtendedDto>>) => r.body as Array<CaseResourceExtendedDto>)
    );
  }
}
