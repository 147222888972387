import {Injectable} from '@angular/core';
import {CmvService} from '@matchsource/store/cmv';
import {RacesService} from '@matchsource/store/races';
import {EthnicityService} from '@matchsource/store/ethnicity';
import {CountriesService} from '@matchsource/store/countries';
import {SourceBaseDTO} from '@matchsource/api/source-base';
import {ORDER_STATES, ORDERABLE_STATE, OrderableReason, OrderModel} from '@matchsource/models/order';
import {Observable, of, zip} from 'rxjs';
import {
  formatGrid,
  ION_AS_ID_BPTYPES,
  SourceBaseModel,
  SourceList,
  SourceProductType,
} from '@matchsource/models/source';
import {formatSex} from '@matchsource/models/shared';
import {DONOR_PRODUCT_TYPE_LIST_MAP, DONOR_STATUS_CLASS_MAP, DONOR_STATUS_LABEL_MAP} from '@matchsource/models/donor';
import {filter, map} from 'rxjs/operators';
import {BusinessPartyModel, formatDcId} from '@matchsource/models/business-party';
import {isEmpty, isNumeric, toLowerCase} from '@matchsource/utils';
import {formatDate} from '@matchsource/date';
import isString from 'lodash-es/isString';
import {DEFAULT_RACE, RH_DETAILS} from '@matchsource/models/nomenclature';
import {Sex} from '@matchsource/models/sex';

@Injectable({
  providedIn: 'root',
})
export class SourceModelFactoryService {
  constructor(
    private readonly cmvService: CmvService,
    private readonly racesService: RacesService,
    private readonly ethnicityService: EthnicityService,
    private readonly countriesService: CountriesService
  ) {}

  formatBloodType(bloodTypeCode: string): string {
    return bloodTypeCode && bloodTypeCode !== 'U' ? bloodTypeCode : '';
  }

  generateSourceModel<T extends Partial<SourceBaseDTO>>(
    data: T,
    orders?: OrderModel[],
    patientId?: MsApp.Guid
  ): Observable<SourceBaseModel> {
    const id = data.sourceGuid || data.guid || '';
    const status = data.status || data.nmdpStatus || '';
    const rh = SourceModelFactoryService.getRh(data);
    const bloodTypeCode = this.formatBloodType(data.bloodType) || this.formatBloodType(data.aboBloodType);
    const sex = formatSex(data.sex);
    const orderableReasons: string[] = (data.orderableStatus && data.orderableStatus.reasons) || [];
    const list = DONOR_PRODUCT_TYPE_LIST_MAP[data.productType || ''];

    return zip(
      this.getCmv(data),
      this.getRace(data),
      this.getEthnicityDescription(data),
      this.getCountryName(data)
    ).pipe(
      map(([cmv, race, ethnicity, countryName]) => {
        const bp = data.bp || ({} as BusinessPartyModel);
        const rawEntityCode = SourceModelFactoryService.getEthnicityCode(data);
        const rawPregnancies = data.pastPreg || data.pastPregnancy || data.pregnancyCount;
        const restricted =
          (data.sourceType === 'CORD' && bp.isCordRestricted) || (data.sourceType === 'DONOR' && bp.isDonorRestricted);

        return {
          id,
          guid: id,
          ref: data.index,
          grid: data.grId ? formatGrid(data.grId) : data.displayGrid || '',
          localId: data.localId || '',
          status,
          statusOverride: data.statusOverride,
          statusClass: status ? DONOR_STATUS_CLASS_MAP[status] : '',
          statusLabel: status ? DONOR_STATUS_LABEL_MAP[status] : '',
          age: data.age,
          sex,
          rh,
          bloodTypeCode,
          bloodTypeRh: `${bloodTypeCode}${rh || ''}`,
          cmv,
          plasmaReduced: data.plasmaReduced,
          cmvDate: SourceModelFactoryService.getCmvDate(data) || '',
          bLeader: data.bleaderMatchStatus,
          pregnancies: sex === Sex.Female && isNumeric(rawPregnancies) ? rawPregnancies : '',
          weight: isNumeric(data.weight) && data.weight > 0 ? data.weight : '',
          race,
          races: SourceModelFactoryService.getRaces(data),
          primaryRaceCode: data.primaryRaceCode,
          preferredTestResult: data.preferredTestResult,
          ethnicityCode: !rawEntityCode || rawEntityCode === 'UK' ? 'UNK' : rawEntityCode,
          ethnicity,
          regDate: data.regDate || '',
          ebsMissing: data.ebsMissing,
          bpGuid: data.bpGuid,
          // Use ion instead id, stories #9851 and #12083
          bpId:
            ION_AS_ID_BPTYPES.includes(bp.type) && bp.ion
              ? bp.ion
              : formatDcId(bp.identifier || '', data.productType as SourceProductType),
          bpName: bp.name,
          doingBusinessAsName: bp.doingBusinessAsName || bp.name,
          country: bp.countryCode,
          countryCode: bp.countryCode, // both country and countryCode properties are used in many places
          countryName,
          restricted,
          internationalFormRequired: bp.internationalFormRequired,
          previousCT: data.ct,
          lastContactDate: data.lastContactDate || '',
          contactType: data.contactType || '',
          productType: data.productType,
          list,
          isEmdisCoop: !!data?.emdisCoop,
          // orderable flags processing
          orderable: !!ORDERABLE_STATE[data.orderableStatus && data.orderableStatus.orderable],
          orderableReasons,
          orderableForCmOnly: orderableReasons.some(reason => reason === OrderableReason.CmOnly),
          orderableSameDayOrder: orderableReasons.some(reason => reason === OrderableReason.SameDayOrders),
          orderableRecipientClosed: orderableReasons.some(reason => reason === OrderableReason.RecipientClosed),
          duplicateRecipient: orderableReasons.some(reason => reason === OrderableReason.DuplicateRecipient),
          // global data
          type: toLowerCase(data.sourceType || ''),
          isWmda: list === SourceList.Wmda,
          guidPart: id.substr(0, 10),
          pendingThisRequest:
            orders && orders.some(order => order.status === ORDER_STATES.PENDING && order.recipientGuid === patientId),
          pendingAnotherRequest:
            orders && orders.some(order => order.status === ORDER_STATES.PENDING && order.recipientGuid !== patientId),
          birthDate: data.birthDate,
        };
      })
    );
  }

  private getCountryName<T extends Partial<SourceBaseDTO>>(data: T): Observable<string> {
    return data.bp ? this.countriesService.getById(data.bp.countryCode).pipe(map(country => country?.name)) : of('');
  }

  private getCmv<T extends Partial<SourceBaseDTO>>(data: T): Observable<string> {
    if (!data.cmvStatus) {
      return of('');
    }
    return this.cmvService.getById(data.cmvStatus).pipe(
      filter(cmv => !!cmv),
      map(cmv => cmv.description)
    );
  }

  private static getCmvDate<T extends Partial<SourceBaseDTO>>(data: T): string {
    return formatDate(data.cmvStatusDate || data.cmvDate);
  }

  private static getRaceCode<T extends Partial<SourceBaseDTO>>(data: T): string {
    const primaryRaceCode = isString(data.primaryRaceCode) ? data.primaryRaceCode : '';
    return data.broadRace || data.detailRace || primaryRaceCode;
  }

  private getRace<T extends Partial<SourceBaseDTO>>(data: T): Observable<string> {
    const code = SourceModelFactoryService.getRaceCode(data);
    if (code === 'MULTI') {
      return of('Multiple');
    }

    return this.racesService.races$.pipe(
      map(races => {
        const race = races[code];
        return race ? race.broadRaceDescription : DEFAULT_RACE.broadRaceDescription;
      })
    );
  }

  private static getEthnicityCode<T extends Partial<SourceBaseDTO>>(data: T): string {
    return data.ethnicity || data.ethnicityCode;
  }

  private getEthnicityDescription<T extends Partial<SourceBaseDTO>>(data: T): Observable<string> {
    return this.ethnicityService.getByCode(SourceModelFactoryService.getEthnicityCode(data)).pipe(
      map(ethnicity => {
        return ethnicity ? ethnicity.description : DEFAULT_RACE.ethnicityDescription;
      })
    );
  }

  private static getRh<T extends Partial<SourceBaseDTO>>(data: T): string {
    return data.rhType ? RH_DETAILS[data.rhType] : null;
  }

  private static getRaces<T extends Partial<SourceBaseDTO>>(data: T): string[] {
    const noRaces = isEmpty(data.raceCodes) && isEmpty(data.races);
    if (noRaces && data.productType === SourceProductType.Coop) {
      return [data.primaryRaceCode as string];
    }

    return data.raceCodes || data.races;
  }
}
