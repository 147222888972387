import {NavigationGroupBaseService} from 'app/navigation-history/declarations';
import {NavigationGroups, NavigationGroupType, NavigationItemUiModel} from '@matchsource/models/navigation-history';

export class UnknownNavigationGroup implements NavigationGroupBaseService {
  readonly groupType: NavigationGroupType = NavigationGroupType.None;

  async group(items: NavigationItemUiModel[]): Promise<NavigationGroups> {
    return items.map(item => ({items: [item]}));
  }
}
