import {Injectable} from '@angular/core';
import {SessionStorageService} from 'ngx-webstorage';
import {Observable} from 'rxjs';
import {BusinessPartyModel} from '@matchsource/models/business-party';
import {filter, map, take} from 'rxjs/operators';

const DEFAULT_ANONYMOUS_USER = 'Anonymous';
const USER_STORAGE_KEY = 'me';

const USER_ROLE_TCC = 'TCC';
const USER_ROLE_VIEW_ALL = 'VIEW_ALL';
const USER_ROLE_VIEW_PARTIALLY = 'VIEW_PART';
const USER_ROLE_CORECM = 'CORE_CM';
const USER_ROLE_CHS = 'CHS';
const USER_ROLE_PE = 'PE';
const USER_ROLE_CP = 'CP';
const USER_ROLE_CPE = 'CPE';
const USER_ROLE_EXTERNAL_VIEW = 'EXT_VIEW';

const ADMIN_ROLE = 'MS_ADMIN';

const CORE_USER_ROLES = [USER_ROLE_TCC, USER_ROLE_CORECM, USER_ROLE_CHS];

interface UserModel {
  userId: MsApp.Guid;
  userName: string;
  userFirstName: string;
  userLastName: string;
  userFullName: string;
  userFullNameAlt: string;
  userRoles: string[];
  availableRoles: string[];
  lastLogin: MsApp.DateString;
  bpGuid: MsApp.Guid;
  bpGuids: MsApp.Guid[];
  tcAcceptedDate: MsApp.DateString | null;
  defaultTc: MsApp.Guid;
  userSelectedBp: MsApp.Guid;
}

const createDefaultUser = () =>
  createUser({
    uid: '',
    subject: '',
    firstName: '',
    lastName: '',
    roles: [DEFAULT_ANONYMOUS_USER],
    lastLoginDate: null,
    bpGuid: null,
    bpGuids: [],
    tcAcceptedDate: null,
    defaultTc: null,
    availableRoles: [],
  });

export interface UserSource {
  uid?: MsApp.Guid;
  subject: string;
  firstName: string;
  lastName: string;
  roles: string[];
  lastLoginDate: MsApp.DateString;
  bpGuid: MsApp.Guid;
  bpGuids: MsApp.Guid[] | null | undefined;
  tcAcceptedDate: MsApp.DateString;
  defaultTc: MsApp.Guid;
  availableRoles: any[];
}

export const createUser = (data: UserSource): UserModel => ({
  userId: data.uid,
  userName: data.subject,
  userFirstName: data.firstName,
  userLastName: data.lastName,
  userFullName: `${data.firstName} ${data.lastName}`,
  userFullNameAlt: `${data.lastName}, ${data.firstName}`,
  userRoles: data.roles,
  lastLogin: data.lastLoginDate,
  bpGuid: data.bpGuid,
  bpGuids: data.bpGuids && data.bpGuids.length ? data.bpGuids : [data.bpGuid],
  tcAcceptedDate: data.tcAcceptedDate,
  defaultTc: data.defaultTc,
  userSelectedBp: data.defaultTc,
  availableRoles: data.availableRoles?.map(role => role.code),
});

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private entity: UserModel = null;

  constructor(private readonly storage: SessionStorageService) {}

  private get user() {
    if (this.entity === null) {
      this.entity = this.storage.retrieve(USER_STORAGE_KEY) || createDefaultUser();
    }

    return this.entity;
  }

  private set user(value) {
    this.entity = value;

    if (value === null) {
      this.storage.clear(USER_STORAGE_KEY);
    } else {
      this.storage.store(USER_STORAGE_KEY, value);
    }
  }

  store(user: UserModel) {
    this.user = user;
  }

  clear() {
    this.user = null;
  }

  getUserRoles(): string[] {
    return this.user.userRoles;
  }

  getAvailableRoles(): string[] {
    return this.user.availableRoles;
  }

  inRole(role: string): boolean {
    return this.getUserRoles().includes(role);
  }

  inAnyRole(roles: string[]): boolean {
    return this.getUserRoles().some(r => roles.includes(r));
  }

  isTCC(): boolean {
    return this.inRole(USER_ROLE_TCC);
  }

  isSingleTc(): boolean {
    return this.inAnyRole([USER_ROLE_TCC, USER_ROLE_CP]);
  }

  isViewAll(): boolean {
    return this.inRole(USER_ROLE_VIEW_ALL);
  }

  isExternalView() {
    return this.inRole(USER_ROLE_EXTERNAL_VIEW);
  }

  isViewPartially(): boolean {
    return this.inRole(USER_ROLE_VIEW_PARTIALLY);
  }

  getUserFirstName(): string {
    return this.user.userFirstName;
  }

  getUserLastName(): string {
    return this.user.userLastName;
  }

  getUserFullName(): string {
    return this.user.userFullName;
  }

  getUserName(): string {
    return this.user.userName;
  }

  getUserNameAlt(): string {
    return this.user.userFullNameAlt;
  }

  getLastLogin() {
    return this.user.lastLogin;
  }

  isLoggedIn(): boolean {
    return !this.inRole(DEFAULT_ANONYMOUS_USER);
  }

  isCoreUser() {
    return this.inAnyRole(CORE_USER_ROLES);
  }

  isCM() {
    return this.inAnyRole([USER_ROLE_CORECM]);
  }

  isCHS() {
    return this.inAnyRole([USER_ROLE_CHS]);
  }

  isPE() {
    return this.inAnyRole([USER_ROLE_PE]);
  }

  isCPE() {
    return this.inAnyRole([USER_ROLE_CPE]);
  }

  isCP() {
    return this.inAnyRole([USER_ROLE_CP]);
  }

  isCommonPractice() {
    return this.inAnyRole([USER_ROLE_CPE, USER_ROLE_CP]);
  }

  getBPId() {
    return this.user.bpGuid;
  }

  getBPIds() {
    return this.user.bpGuids;
  }

  setBpIds$(bpList$: Observable<BusinessPartyModel[]>): Observable<boolean> {
    return bpList$.pipe(
      filter(bpList => !!bpList.length),
      take(1),
      map(bpList => bpList.map(bp => bp.id)),
      map(bps => {
        const user = this.user;
        user.bpGuids = bps.filter(bp => this.getBPIds().includes(bp));
        this.user = user;
        return true;
      })
    );
  }

  getPrimaryBPId() {
    return this.getBPIds().length ? this.getBPIds()[0] : '';
  }

  getDefaultTc() {
    return this.user.defaultTc;
  }

  setDefaultTc(newDefaultTc: MsApp.Guid): void {
    const user = this.user;
    user.defaultTc = newDefaultTc;

    this.user = user;
  }

  getCurrentBp(): MsApp.Guid {
    return this.hasMultipleBusinessParties() && this.getUserSelectedBp()
      ? this.getUserSelectedBp()
      : this.getPrimaryBPId();
  }

  getUserSelectedBp(): MsApp.Guid {
    return this.user.userSelectedBp;
  }

  setUserSelectedBp(selectedBp: MsApp.Guid) {
    const user = this.user;
    user.userSelectedBp = selectedBp;

    this.user = user;
  }

  revertUserSelectedBp() {
    const user = this.user;
    user.userSelectedBp = this.getDefaultTc();

    this.user = user;
  }

  switchRole(roles: string[]): void {
    const user = this.user;
    user.userRoles = roles;

    this.user = user;
  }

  disableRoleSelection() {
    const user = this.user;

    user.userRoles = user.availableRoles;
    user.availableRoles = [];

    this.user = user;
  }

  hasMultipleBusinessParties() {
    const userBPIds = this.getBPIds();
    return userBPIds ? userBPIds.length > 1 : false;
  }

  isExternal() {
    return this.inAnyRole([USER_ROLE_TCC, USER_ROLE_EXTERNAL_VIEW]);
  }

  isInternal() {
    return !this.inAnyRole([USER_ROLE_TCC, USER_ROLE_EXTERNAL_VIEW]);
  }

  get isTcAccepted() {
    return !!this.user.tcAcceptedDate;
  }

  setTcAcceptedDate(date: MsApp.DateString) {
    const user = this.user;
    user.tcAcceptedDate = date;

    this.user = user;
  }

  get isMultipleRolesUser(): boolean {
    return this.getAvailableRoles()?.filter(role => role !== ADMIN_ROLE).length > 1;
  }
}
