import {CordListModel} from '@matchsource/models/cord';
import {VaccinationModel} from '@matchsource/models/vaccination';
import {BiobankListModel} from '@matchsource/models/biobank';
import {DonorWorkupModel} from '@matchsource/models/donor-workup';
import {HttpContext} from '@angular/common/http';
import {OrderTrackingSourceModel} from './order-tracking-source.model';
import {IOrderTrackingSourceModel, OrderTrackingModel} from './order-tracking.model';
import {DonorListModel} from '@matchsource/models/donor';

export interface OrderModel {
  id: MsApp.Guid;
  type: string;
  typeName: string;
  status: string;
  newWorkupInd: boolean;
  requestedItems: Array<string>;
  requestDate?: MsApp.DateString;
  requestDateAsDate: MsApp.DateString;
  requestedDate?: MsApp.DateString;
  releaseReason: string;
  infusion?: MsApp.DateString;
  infusionDate?: MsApp.DateString;
  activationDate?: MsApp.DateString;
  createDate?: MsApp.DateString;
  fulfillmentOrderGuid: MsApp.Guid;
  recipientGuid: MsApp.Guid;
  recipientTcId: MsApp.Guid;
  releaseDate?: MsApp.DateString;
  serviceCodes: Array<string>;
  sourceGuid: MsApp.Guid;
  sourceType: string;
  isSubsequent?: boolean;
  apheresisCenter?: string;
  collectionCenter?: string;
  transportationMethod?: string;
  isSameTc?: boolean;
  isCryoPreserved?: boolean;
  gamidaOmisirgeOrderInd?: string;
}

export interface OrderWithTrackingsModel extends OrderModel {
  preparationStart?: string;
  collection1?: string;
  collection2?: string;
  clearance?: string;
  ctShipDate?: string;
  cordShipDate?: string;
  cordPreparationStart?: string;
  appointmentDate?: string;
  resultsReceivedDate?: string;
  cryopreservedShipmentDate?: string;
}

export interface FailedOrders {
  code: string;
  status: string;
  sourceGuid: MsApp.Guid;
}

export interface CompletedOrders {
  orderId: MsApp.Guid;
  status: string;
  sourceGuid: MsApp.Guid;
}

interface SubmittedOrderModelBase {
  recipientGuid: MsApp.Guid;
  serviceCodes: any[];
  sourceGuid: MsApp.Guid;
  sourceType: string;
  type: string;
  formInfo: {
    formCode: string;
    formVersion: string;
  };
}

export interface LegacyWorkupSubmittedOrderModel extends SubmittedOrderModelBase {
  answers: any;
}

export interface NewWorkupSubmittedOrderModel extends SubmittedOrderModelBase {
  workup: DonorWorkupModel;
}

export type SubmittedOrderModel = LegacyWorkupSubmittedOrderModel | NewWorkupSubmittedOrderModel;

export enum PostponementRequestTypes {
  POSTPONE = 'POSTPONE',
  CANCEL = 'CANCEL',
}

export type PostponementRequestType = PostponementRequestTypes.POSTPONE | PostponementRequestTypes.CANCEL;

export type PostponementSourceType = 'HAPLO' | 'RELATED' | 'UNRELATED' | 'BDP';
export type PostponementReasonType =
  | 'ACCCTIMING'
  | 'DTIMING'
  | 'DELETED'
  | 'IDMELIG'
  | 'MATCH'
  | 'OTHDONOR'
  | 'DNOTABLE'
  | 'INELIGIBLE'
  | 'DIED'
  | 'INSURANCE'
  | 'TREATMENT'
  | 'LOST'
  | 'XFER'
  | 'NONE'
  | 'OTHER'
  | 'BACKUP_DNR'
  | 'CAPACITY'
  | 'PT_OPTOUT'
  | 'CACTIMING'
  | 'CDTIMING'
  | 'CADDTEST'
  | 'CDELETED'
  | 'CIDMELIG'
  | 'CMATCH'
  | 'COTHDONOR'
  | 'CDNOTABLE'
  | 'CINELIG'
  | 'CDIED'
  | 'CINSURANCE'
  | 'CTREATMENT'
  | 'CLOST'
  | 'CXFER'
  | 'CNONE'
  | 'COTHER'
  | 'CBACKUP'
  | 'CCAPACITY'
  | 'CPT_OPTOUT';

export enum PostponementStatuses {
  InProgress = 'INPROGRESS',
  Sysgen = 'SYSGEN',
  Submit = 'SUBMIT',
  Deleted = 'DELETED',
}

export type PostponementStatus =
  | PostponementStatuses.InProgress
  | PostponementStatuses.Sysgen
  | PostponementStatuses.Submit
  | PostponementStatuses.Deleted;
export type PostponementTreatmentSubReasonType =
  | 'PROGRESSN'
  | 'RESPONSE'
  | 'HOSPICE'
  | 'NCOMORBID'
  | 'PREFERENCE'
  | 'WCOMORBID'
  | 'CPROGRESSN'
  | 'CRESPONSE'
  | 'CHOSPICE'
  | 'CNCOMORBID'
  | 'CPREF'
  | 'CWCOMORBID'
  | 'CNONE';

export type PostponementTreatmentSecReasonType = 'MEDDEFR' | 'DAMGDESTR' | 'RESRVOTHR';

export interface PostponementModel {
  alternateSourceChosen?: PostponementSourceType;
  confirmedCollectionDate?: MsApp.DateString;
  courierRequestCancelInd?: boolean;
  id?: number;
  multipleDonorInd?: boolean;
  newProposedCollectionDate?: MsApp.DateString;
  primaryReason?: PostponementReasonType;
  requestDetails?: string;
  requestType?: PostponementRequestType;
  requestedDate?: MsApp.DateString;
  status?: PostponementStatus;
  treatmentSubReason?: PostponementTreatmentSubReasonType;
  secondaryReason?: PostponementTreatmentSecReasonType;
  sourceType?: string;
}

export interface OrderModelSubmitted {
  orderId: MsApp.Guid;
  searchStarted: boolean;
  sourceGuid: MsApp.Guid;
  status: string;
  reason: {
    code?:
      | 'HAS_PENDING_ORDER'
      | 'IS_NOT_AVAILABLE'
      | 'ORDER_TYPE_MISSED_IN_CATALOG'
      | 'SERVER_ERROR'
      | 'RECIPIENT_FROM_OFAC_RESTRICTED_TC'
      | 'ORDERABLE_FOR_CM_ONLY'
      | 'ORDERABLE_FOR_CM_TCC_ONLY'
      | 'RECIPIENT_HAS_CLOSED_STATUS'
      | 'DUPLICATE_RECIPIENT'
      | 'RESTRICT_TWO_ORDERS_ON_THE_SAME_DAY'
      | 'CRITICAL_STATUS_ANOMALY_NOT_INITIAL_USER'
      | 'CRITICAL_STATUS_ANOMALY_INITIAL_USER'
      | 'TEMPORAL_STATUS_ANOMALY_NOT_INITIAL_USER'
      | 'TEMPORAL_STATUS_ANOMALY_INITIAL_USER';
    reasonDetails?: string;
  };
}

export interface CreatedOrderModel {
  order: OrderModelSubmitted[];
  redirectToSearch: boolean;
}

export interface OrderTrackingUpdateModel {
  id?: number;
  lineItemSeqNum?: number;
  valueDate?: MsApp.DateString;
  valueText?: string;
  valueNumber?: number;
  serviceCode?: string;
  serviceDesc?: string;
  serviceTypeCode?: string;
  stageCode?: string;
  trackingCode?: string;
  orderTypeCode?: 'CUSTOMER' | 'FULFILL';
  subsequent?: boolean;
}

export type OrderTrackingByTypeModel = MsApp.Dictionary<OrderTrackingModel[]>;

export type OrderTrackingBySourceGuidAndTypeModel = MsApp.Dictionary<IOrderSources>;

interface OrderableSource {
  orderable: boolean;
  orderableForCmOnly: boolean;
  orderableRecipientClosed: boolean;
  orderableSameDayOrder: boolean;
  duplicateRecipient: boolean;
}

export interface GetInventorySerialNumbersParams {
  inventoryType: string;
  id?: number;
  serialNumber?: string;
  bpGuid: string;
  bulk?: boolean;
  used?: boolean;
  context?: HttpContext;
}

export const INVENTORY_TYPE = 'SWAB_KIT';

export type OrderableCord = CordListModel & OrderableSource;

export type OrderableDonor = DonorListModel & OrderableSource & {vaccination?: VaccinationModel};

export type OrderableBiobank = BiobankListModel & OrderableSource;

export type GetOrderableSourcesResponse = (OrderableDonor | OrderableCord | OrderableBiobank)[];

export interface IOrderTrackingSerializable {
  serialize(): OrderTrackingModel;
}
export interface IOrderSources {
  bdpFulfillment?: OrderTrackingModel[];
  informationSessionDate?: OrderTrackingSourceModel;
  physicalExamDate?: OrderTrackingSourceModel;
  clearance?: OrderTrackingSourceModel;
  customer?: OrderTrackingModel[];
  verificationReceivedDate?: OrderTrackingSourceModel;
  donorResearchSampleDate?: OrderTrackingSourceModel;
  patientResearchSampleDate?: OrderTrackingSourceModel;
  filgrastimStartDate?: OrderTrackingSourceModel;
  filgrastimStartDate4Marrow?: OrderTrackingSourceModel;
  prepStart?: OrderTrackingSourceModel;
  fulfillment?: OrderTrackingModel[];
  tcConfirmedCollectionDate?: OrderTrackingSourceModel;
  collection?: OrderTrackingSourceModel;
  extendedMedicalCollectionDate?: OrderTrackingSourceModel;
  idmCollectionDate?: OrderTrackingSourceModel;
  preCollectionSample?: OrderTrackingSourceModel;
  intervalPeDate?: OrderTrackingSourceModel;
  nmdpApprovalDate?: OrderTrackingSourceModel;
  dcApprovalDate?: OrderTrackingSourceModel;
  appointmentDate?: OrderTrackingSourceModel;
  resultsReceivedDate?: OrderTrackingSourceModel;
  ctShipDate?: OrderTrackingSourceModel;
  cordShipDate?: OrderTrackingSourceModel;
  cordPrepStart?: OrderTrackingSourceModel;
  shippingCompany?: OrderTrackingSourceModel;
  trackingNumber?: OrderTrackingSourceModel;
  cryopreservedShipmentDate?: OrderTrackingSourceModel;
  intervalPeDueDate?: string;
}

export interface IOrderTrackingItem {
  id: number | string;
  order: number;
  source: IOrderTrackingSourceModel;
  date?: string;
  value?: string;
  text?: string;
  data?: OrderTrackingModel;
  lineItemSeqNum?: number;

  get isNew(): boolean;
  get hasValue(): boolean;
}

export enum OrderType {
  ConfirmatoryTyping = 'CT',
  HpcApheresis = 'PBSC',
  HpcMarrow = 'WU',
  CustomizedTyping = 'HR',
  DrTyping = 'DR',
  NcWholeBlood = 'WB',
  MncApheresis = 'UW',
  HeldForWorkup = 'HW',
  CordConfirmatoryTyping = 'CCT',
  CordHold = 'HE',
  CordShipment = 'OR',
  Subsequent = 'SECEWU',
}

export const SHIPDATE_TRACKING_CODE = 'SHIPDATE';
export const CBURCVD_TRACKING_CODE = 'CBURCVD';

export interface LabRefferenceIdDropDownItem {
  labRefId: string;
  labRefLabel: string;
  traceCode: string | number | null;
}

export interface PostponementDropdownOption {
  code?: string;
  description?: string;
}

export enum OrderableReason {
  CmOnly = 'ORDERABLE_FOR_CM_ONLY',
  RecipientClosed = 'RECIPIENT_HAS_CLOSED_STATUS',
  SameDayOrders = 'RESTRICT_TWO_ORDERS_ON_THE_SAME_DAY',
  DuplicateRecipient = 'DUPLICATE_RECIPIENT',
}

export const ORDERABLE_STATE = {
  YES: true,
  NO: false,
  YES_WITH_RESTRICTION: true,
  YES_SUBSEQUENT: true,
};

export const SUBSEQUENT_ORDERABLE_STATUS = {
  YES_SUBSEQUENT: 'YES_SUBSEQUENT',
};

export enum EligibilityCode {
  Eligible = 'E',
  Ineligible = 'I',
  NotApplicable = 'NA',
  Incomplete = 'P',
}

export const ELIGIBILITIES = {
  [EligibilityCode.Eligible]: 'Eligible',
  [EligibilityCode.Ineligible]: 'Ineligible',
  [EligibilityCode.NotApplicable]: 'Not Applicable',
  [EligibilityCode.Incomplete]: 'Incomplete',
};
