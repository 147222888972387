import {Injectable, OnDestroy} from '@angular/core';
import {Store} from '@ngxs/store';
import {combineLatest, Observable, of, Subject} from 'rxjs';
import {distinctUntilChanged, filter, map, takeUntil, tap} from 'rxjs/operators';
import {SecondaryCodeStateModel} from 'app/store/secondary-codes/secondary-codes.state';
import {SecondaryCodeModel} from '@matchsource/models/secondary-codes';
import {GetTcSecondaryCodes} from 'app/store/secondary-codes/secondary-codes.action';

@Injectable({
  providedIn: 'root',
})
export class SecondaryCodesService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  public readonly data$: Observable<SecondaryCodeStateModel>;

  constructor(private readonly store: Store) {
    this.data$ = this.store
      .select((state: any) => state.secondaryCodeEntities as SecondaryCodeStateModel)
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$));
  }

  tcSecondaryCodes(tcId: MsApp.Guid): Observable<SecondaryCodeModel[]> {
    return combineLatest([of(tcId), this.data$]).pipe(
      tap(([key, state]) => {
        if (!(key in state) || (!state[key].loaded && !state[key].loading)) {
          this.store.dispatch(new GetTcSecondaryCodes(key));
        }
      }),
      filter(([key, state]) => {
        return key in state && state[key].loaded;
      }),
      distinctUntilChanged(),
      map(([key, state]) => {
        return key in state ? state[key].secondaryCodes : [];
      })
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
