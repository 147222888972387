import {Action, Selector, State, StateContext} from '@ngxs/store';
import {RaceApiService} from '@matchsource/api/race';
import {PopulationRaceModel} from '@matchsource/models/nomenclature';
import {Injectable} from '@angular/core';
import {
  addOrReplaceEntities,
  defaultEntitiesState,
  EntitiesStateModel,
  loadedEntities,
  loadingEntities,
  setError,
} from '@matchsource/store/core';
import {catchError, tap} from 'rxjs/operators';
import {compose} from '@ngxs/store/operators';
import {of} from 'rxjs';
import {GetPopulationRaces} from 'app/store/population-races/population-races.action';

export type PopulationRacesStateModel = EntitiesStateModel<PopulationRaceModel>;

@State<PopulationRacesStateModel>({
  name: 'populationRaces',
  defaults: defaultEntitiesState<PopulationRaceModel>(),
})
@Injectable()
export class PopulationRacesState {
  @Selector([PopulationRacesState])
  static populationRaces(state: PopulationRacesStateModel) {
    return state.entities;
  }

  @Selector([PopulationRacesState])
  static inProgressOrLoaded(state: PopulationRacesStateModel) {
    return state.loaded || state.loading;
  }

  constructor(private readonly raceApi: RaceApiService) {}

  @Action(GetPopulationRaces)
  getAll(ctx: StateContext<PopulationRacesStateModel>) {
    ctx.setState(loadingEntities(true));

    return this.raceApi.getPopulationRaces().pipe(
      catchError(error => {
        ctx.setState(compose(setError(error), loadingEntities(false)));
        return of([]);
      }),
      tap(races =>
        ctx.setState(
          compose(
            addOrReplaceEntities<PopulationRaceModel>('code', races),
            loadedEntities(true),
            loadingEntities(false)
          )
        )
      )
    );
  }
}
