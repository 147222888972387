import {Inject, Injectable} from '@angular/core';
import {
  NavigationGroups,
  NavigationGroupType,
  NavigationItemModel,
  NavigationItemTitleElementToken,
  NavigationItemUiModel,
} from '@matchsource/models/navigation-history';
import {
  NAVIGATION_GROUP_HANDLERS,
  NAVIGATION_ITEM_HANDLERS,
  NavigationGroupBaseService,
  NavigationItemBaseService,
} from '../declarations';
import {translate} from '@ngneat/transloco';
import {toMap} from '@matchsource/utils';

interface GroupItems<T extends NavigationItemModel> {
  items: T[];
  groupType: NavigationGroupType;
}

@Injectable({
  providedIn: 'root',
})
export class NavigationHistoryFormatterService {
  private readonly groupHandlersMap: Partial<Record<NavigationGroupType, NavigationGroupBaseService>>;

  constructor(
    @Inject(NAVIGATION_GROUP_HANDLERS) private readonly groupHandlers: NavigationGroupBaseService[],
    @Inject(NAVIGATION_ITEM_HANDLERS) private readonly itemHandlers: NavigationItemBaseService[]
  ) {
    this.groupHandlersMap = toMap(groupHandlers, 'groupType');
  }

  async group(items: NavigationItemModel[]): Promise<NavigationGroups> {
    await this.prefetchGroupData(items);
    const normalizedItems = await this.convertItemsToUiModel(items);
    const itemsByGroups = this.splitByGroupsByDate(normalizedItems);

    const groupsResolves: Promise<NavigationGroups>[] = itemsByGroups.reduce(
      (resolvers, {groupType, items: groupItems}) => {
        const groupHandler = this.groupHandlersMap[groupType];

        if (groupHandler) {
          resolvers.push(groupHandler.group(groupItems));
        }

        return resolvers;
      },
      []
    );

    const groups: NavigationGroups[] = await Promise.all(groupsResolves);

    return groups.flat();
  }

  private async prefetchGroupData(items: NavigationItemModel[]): Promise<void> {
    const groups = this.splitByGroups(items);

    const callbacks = Object.entries(groups).reduce((prefetchCallbacks, [groupType, groupItems]) => {
      const groupHandler = this.groupHandlersMap[groupType];

      if (groupHandler && groupHandler.prefetchData && groupHandler.prefetchDataEnabled) {
        prefetchCallbacks.push(groupHandler.prefetchData(groupItems));
      }

      return prefetchCallbacks;
    }, []);

    await Promise.all(callbacks);
  }

  private splitByGroups<T extends NavigationItemModel>(items: T[]): Partial<Record<NavigationGroupType, T[]>> {
    return items.reduce((groups, item) => {
      const {groupType} = item;

      if (!(groupType in groups)) {
        groups[groupType] = [];
      }

      groups[groupType].push(item);

      return groups;
    }, {});
  }

  private splitByGroupsByDate<T extends NavigationItemModel>(items: T[]): GroupItems<T>[] {
    let prevGroupType: NavigationGroupType | null = null;
    let prevGroupKey: number | string | null = null;

    return items.reduce((groups, item) => {
      const {groupType} = item;
      const groupHandler = this.groupHandlersMap[groupType];
      let groupKey: number | string | null = null;

      if (groupHandler.groupBy) {
        groupKey = groupHandler.groupBy(item);
      }

      if (groupType !== prevGroupType || prevGroupKey !== groupKey) {
        groups.push({
          items: [],
          groupType,
        });
      }

      groups[groups.length - 1].items.push(item);
      prevGroupType = groupType;
      prevGroupKey = groupKey;

      return groups;
    }, []);
  }

  private async convertItemsToUiModel(items: NavigationItemModel[]): Promise<NavigationItemUiModel[]> {
    return items.map(item => {
      const uiItem = {
        ...item,
        titleElements: [
          {
            token: NavigationItemTitleElementToken.Link,
            text: this.resolveLinkTitle(item),
          },
        ],
      };

      return this.resolveUiItem(uiItem);
    });
  }

  private resolveLinkTitle(item: NavigationItemModel): string {
    const messageKey = `NAV_HISTORY.LINKS.${item.route.toUpperCase()}`;

    return translate(messageKey);
  }

  private resolveUiItem(item: NavigationItemUiModel): NavigationItemUiModel {
    return this.itemHandlers.reduce((processedItem, itemHandler) => {
      if (this.isApplicableHandlerForItem(itemHandler, processedItem)) {
        processedItem = itemHandler.resolve(processedItem);
      }

      return processedItem;
    }, item);
  }

  private isApplicableHandlerForItem(itemHandler: NavigationItemBaseService, item: NavigationItemUiModel): boolean {
    return itemHandler.linkMask === item.route;
  }
}
