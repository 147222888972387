import {Action, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {
  addOrReplaceEntities,
  defaultEntitiesState,
  EntitiesStateModel,
  loadedEntities,
  loadingEntities,
  setError,
} from '@matchsource/store/core';
import {catchError, tap} from 'rxjs/operators';
import {compose} from '@ngxs/store/operators';
import {of} from 'rxjs';
import {GetBloodTypes} from 'app/store/blood-types/blood-types.actions';
import {BloodTypesApiService} from '@matchsource/api/blood-types';
import {Nomenclature} from '@matchsource/models/nomenclature';

export type BloodTypesStateModel = EntitiesStateModel<Nomenclature>;

@State<BloodTypesStateModel>({
  name: 'bloodTypes',
  defaults: defaultEntitiesState<Nomenclature>(),
})
@Injectable()
export class BloodTypesState {
  constructor(private readonly bloodTypesApi: BloodTypesApiService) {}

  @Action(GetBloodTypes)
  getAll(ctx: StateContext<BloodTypesStateModel>) {
    ctx.setState(loadingEntities(true));

    return this.bloodTypesApi.getAll().pipe(
      catchError(error => {
        ctx.setState(compose(setError(error), loadingEntities(false)));
        return of([]);
      }),
      tap(entities =>
        ctx.setState(
          compose(addOrReplaceEntities<Nomenclature>('code', entities), loadedEntities(true), loadingEntities(false))
        )
      )
    );
  }
}
