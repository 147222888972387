import {Dpb1ExpressionFavorability, Dpb1ExpressionMatchType, HlaHistoryLocusModel} from '@matchsource/models/hla';
import {OrderableStatusModel} from '@matchsource/models/order-shared';
import {SourceList, WmdaDonorData} from '@matchsource/models/source';

export enum Dpb1MatchGrade {
  Missed = 'MIS',

  Permissive = 'PER',

  Match = 'MAT',

  NonPermissive = 'NON',

  Potential = 'POT',
}

export interface DonorLookupModel {
  guid: MsApp.Guid;
  nmdpId: string;
  grId: string;
  displayGrid: string;
  localId: string;
  bpGuid: MsApp.Guid;
  emdisId: string;
  status: string;
  availabilityDate: string;
  displayId: string;
  categoryCode: string;
}

export interface DonorBaseModel {
  bpGuid?: string;
  displayGrid?: string;
  displayId?: string;
  emdisCoop?: boolean;
  emdisId?: string;
  grId?: string;
  guid?: string;
  localId?: string;
  nmdp?: boolean;
  nmdpId?: string;
  productType?: string;
  sourceType?: 'DONOR' | 'CORD' | 'BDP';
  status?: string;
}

export enum TestResultAnswerType {
  Text = 'TEXT',
  Date = 'DATE',
}

export type TestResultAnswerTypeLiteral = `${TestResultAnswerType}`;

export interface DonorTestResultModel {
  id: MsApp.Guid;
  date: MsApp.DateString;
  formType: string;
  name: string;
  protocol: string;
  version: number;
  children: TestResultChildModel[];
}

export interface TestResultChildModel {
  children: TestResultChildModel[];
  id: MsApp.Guid;
  tag: string;
  title: string;
  type: string;
  answer?: string;
  answerType?: TestResultAnswerTypeLiteral;
  date?: MsApp.DateTimeString;
}

export interface DonorListModel {
  id: MsApp.Guid;
  pid: string;
  ref: number;
  grid: string;
  localId: string;
  status: string;
  statusClass: string;
  statusLabel: string;
  statusOverride?: string;
  age: number;
  sex: string;
  rh: string;
  bloodTypeCode: string;
  bloodTypeRh: string;
  cmv: string;
  cmvDate: string;
  pregnancies: string | number;
  weight: string | number;
  race: string;
  races: any;
  ethnicityCode: string;
  ethnicity: string;
  regDate: string;
  bpGuid: string;
  bpId: string;
  bpName: string;
  doingBusinessAsName: string;
  country: string;
  countryCode: string;
  previousCT: string;
  lastContactDate: string;
  contactType: string;
  productType: string;
  list: string;
  orderableStatus?: OrderableStatusModel;
  orderable: boolean;
  orderableReasons: string[];
  orderableForCmOnly: boolean;
  orderableSameDayOrder: boolean;
  orderableRecipientClosed: boolean;
  duplicateRecipient: boolean;
  isWmda: boolean;
  guidPart: string;
  guid: MsApp.Guid;
  pendingThisRequest: boolean;
  pendingAnotherRequest: boolean;
  availabilityDate: string;
  availableDate?: MsApp.DateString;
  type: 'donor';
  previousDonation: number;
  isABOnly: boolean;
  birthDate: string;
  raceEth: string;
  list_bpId: string;
  age_sex: string;
  lastContactDate_contactType: string;
  cmv_bloodTypeRh: string;
  pregnancies_previousCT: string;
  availabilityDate_regDate: string;
  seq: number;
  populationCode: string;
  matching: any;
  cted: boolean;
  wmdaData?: WmdaDonorData;
  displayGrid?: MsApp.Guid;
  isRestricted: boolean;
  displayId: MsApp.Guid;
  wmdaRequested: boolean;
  bpRestricted: string;
  isRestrictedView: boolean;
  preferredTestResult?: MsApp.Dictionary<HlaHistoryLocusModel>;
  kirCenB: number;
  suggestedTyping?: string;
  drbxScore: number;
  isEmdisCoop: boolean;
  dpb1ExpressionMatchStatus: Dpb1ExpressionFavorability;
  dpb1ExpressionMatchType: Dpb1ExpressionMatchType;
  ebsMissing: boolean;
  permissivePercent: number;
  bLeader: string;
  internationalFormRequired?: boolean;
}

export enum DonorStatus {
  Available = 'AV',
  TemporaryUnavailable = 'TU',
  Deleted = 'DE',
  ActiveThisSearch = 'AC',
  ActiveAnotherSearch = 'AX',
  AvailablePreviouslyReleased = 'AVR',
  NotAvailable = 'NA',
}

export enum DonorProductType {
  Nmpd = 'NMDP',
  Bmdw = 'BMDW',
  Bmdwd = 'BMDWD',
  Coop = 'COOP',
}

export const DONOR_PRODUCT_TYPE_LIST_MAP = {
  [DonorProductType.Nmpd]: SourceList.Nmdp,
  [DonorProductType.Bmdw]: SourceList.Wmda,
  [DonorProductType.Bmdwd]: SourceList.Wmda,
  [DonorProductType.Coop]: SourceList.Coop,
};
export const DONOR_STATUS_CLASS_MAP = {
  [DonorStatus.Available]: 'available',
  [DonorStatus.TemporaryUnavailable]: 'temporary-unavailable',
  [DonorStatus.Deleted]: 'deleted',
  [DonorStatus.ActiveThisSearch]: 'active-this-search',
  [DonorStatus.ActiveAnotherSearch]: 'active-another-search',
  [DonorStatus.AvailablePreviouslyReleased]: 'available-pr',
};
export const DONOR_STATUS_LABEL_MAP = {
  [DonorStatus.Available]: '',
  [DonorStatus.TemporaryUnavailable]: 'DONOR.STATUS.TEMPORARY_UNAVAILABLE',
  [DonorStatus.Deleted]: 'DONOR.STATUS.DELETED',
  [DonorStatus.ActiveThisSearch]: 'DONOR.STATUS.ACTIVE_THIS_SEARCH',
  [DonorStatus.ActiveAnotherSearch]: 'DONOR.STATUS.ACTIVE_ANOTHER_SEARCH',
  [DonorStatus.AvailablePreviouslyReleased]: 'DONOR.STATUS.AVAILABLE_PREV_RELEASED',
};
