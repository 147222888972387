import {Injectable, Injector} from '@angular/core';
import {PatientApiService} from '@matchsource/api/patient';
import {ToastService} from '@matchsource/nmdp-ui';
import {SearchCompletedComponent} from 'app/shared/components/search-completed/search-completed.component';
import {SEARCH_SOURCE_TYPES} from 'app/shared';
import {Patient, SearchCompletedEventParams} from 'app/features/search-status/declarations';
import {Select, Store} from '@ngxs/store';
import {SearchesStatusPatientsState} from 'app/features/search-status/store/searches-patients.state';
import {SearchesState} from 'app/store/searches/searches.state';
import {LoadPatients} from 'app/features/search-status/store/searches-patients.action';
import {firstValueFrom, Observable} from 'rxjs';
import {filter} from 'rxjs/operators';
import {SearchNotificationType, SourceTypeSearchModel} from '@matchsource/models/search';
import {EventService} from '@matchsource/event';
import {USER_PERMISSIONS} from '@matchsource/models/permissions';
import {PermissionsService} from '@matchsource/store/permissions';

@Injectable({
  providedIn: 'root',
})
export class SearchCompletedService {
  readonly events: EventService;

  showSearchResultsLink: boolean;

  @Select(SearchesStatusPatientsState.patients)
  patients$: Observable<MsApp.Dictionary<Patient>>;

  get searches(): MsApp.Dictionary<SourceTypeSearchModel> {
    return this.store.selectSnapshot(SearchesState.searchesMap);
  }

  constructor(
    private readonly api: PatientApiService,
    private readonly toast: ToastService,
    private readonly permissions: PermissionsService,
    private readonly injector: Injector,
    private readonly store: Store,
    private readonly eventService: EventService
  ) {
    this.showSearchResultsLink = this.permissions.hasPermissions([USER_PERMISSIONS.SEARCH_RESULTS_LINK_SHOW]);
  }

  init(): void {
    this.eventService.on(SearchNotificationType.SearchCompleted, (data: SearchCompletedEventParams) =>
      this.searchCompleted(data)
    );
  }

  async searchCompleted(data: SearchCompletedEventParams): Promise<void> {
    const search = this.searches[data.searchGuid];
    const {patientId} = search;
    const sourceType = SEARCH_SOURCE_TYPES[data.sourceType];
    await firstValueFrom(this.store.dispatch(new LoadPatients([patientId])));
    const patients = await firstValueFrom(this.patients$.pipe(filter(patient => Object.keys(patient).length > 0)));

    this.toast.infoComponent({closeButton: true}, SearchCompletedComponent, {
      sourceType,
      search,
      showSearchResultsLink: this.showSearchResultsLink,
      patient: patients[patientId],
    });
  }
}
