import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import {GetDsa} from 'app/store/dsa/dsa.actions';
import {DsaApiService} from '@matchsource/api/dsa';
import {DsaModelLoci} from '@matchsource/models/dsa';
import {HLA_ORDER} from '@matchsource/models/hla';

export type DsaStateModel = {
  loading: boolean;
  loaded: boolean;
  dsa: DsaModelLoci[];
};

const initState = (): DsaStateModel => ({
  loading: false,
  loaded: false,
  dsa: null,
});

@State<DsaStateModel>({
  name: 'dsa',
  defaults: initState(),
})
@Injectable()
export class DsaState {
  @Selector([DsaState])
  static dsa(state: DsaStateModel): DsaModelLoci[] {
    return state.dsa;
  }

  @Selector([DsaState])
  static inProgressOrLoaded(state: DsaStateModel) {
    return state.loaded || state.loading;
  }

  constructor(private readonly dsaApi: DsaApiService) {}

  @Action(GetDsa)
  getAll(ctx: StateContext<DsaStateModel>) {
    ctx.patchState({
      loading: true,
    });

    return this.dsaApi.getAll().pipe(
      tap(dsa =>
        ctx.patchState({
          dsa: dsa.loci.sort((a, b) => HLA_ORDER[a.locusName] - HLA_ORDER[b.locusName]),
          loading: false,
          loaded: true,
        })
      )
    );
  }
}
