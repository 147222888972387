import {ChangeDetectionStrategy, Component, HostBinding, Inject, OnDestroy, OnInit} from '@angular/core';
import {filter, map, pairwise, switchMap, takeUntil} from 'rxjs/operators';
import {iif, interval, Observable, of, Subject, Subscription} from 'rxjs';
import {SearchesService} from 'app/store/searches/searches.service';
import {AppConfigService} from 'app/app/config';
import {IdleService} from 'app/core/services/idle.service';
import {FeatureService} from '@matchsource/feature-toggle';
import isEqual from 'lodash-es/isEqual';
import {SearchTypes} from 'app/shared';
import {RouterStateService, RouterState} from 'ngxs-ui-router';
import {AdvancedPatientLookupHelperService} from 'app/features/advanced-patient-lookup/services/advanced-patient-lookup-helper.service';
import {AnnouncementService} from 'app/store/announcements/announcement.service';
import {AnnouncementModel} from '@matchsource/api/announcements';
import {ServerSourceEventService} from 'app/core/services/server-source-event.service';
import {DialogService} from '@matchsource/nmdp-ui';
import {BusinessPartiesService} from '@matchsource/store/business-parties';
import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';
import {translate, TranslocoDirective, TranslocoPipe} from '@ngneat/transloco';
import {ApplicationTooltipService} from 'app/core/services/application-tooltip.service';
import {StateService, UIRouter} from '@uirouter/core';
import {userNameLabel} from 'app/core/utils';
import {Store} from '@ngxs/store';
import {Transition, UIRouterModule} from '@uirouter/angular';
import {NavigationHeaderTestIds} from 'app/core/declarations';
import {AnnouncementComponent} from 'app/features/announcements/components/announcement/announcement.component';
import {CpLayoutComponent} from 'app/features/cp-layout/components/cp-layout/cp-layout.component';
import {SearchComponent} from 'app/shared/components/search/search.component';
import {ToggleDirective} from '@matchsource/shared/toggle';
import {UserPermissionsDirective} from 'app/shared/directives/user-permissions/user-permissions.directive';
import {MsLogoComponent} from 'app/shared/components/ms-logo/ms-logo.component';
import {NgIf, NgClass, AsyncPipe, UpperCasePipe, DatePipe} from '@angular/common';
import {SearchStatusRoutes} from 'app/features/search-status/declarations';
import {AdvancedPatientLookupRoutes} from 'app/features/advanced-patient-lookup/declarations';
import {PatientFormRoutes} from 'app/features/patient-form/declarations';
import {PatientsInProgressRoutes} from 'app/features/patients-in-progress/declarations';
import {MyPatientsRoutes} from 'app/features/my-patients/declarations';
import {WorkflowManagerRoutes} from 'app/features/workflow-manager/declarations';
import {SpinnerComponent} from 'app/shared/components/spinner/spinner.component';
import {AppEvents, UserService} from '@matchsource/core';
import {EventService} from '@matchsource/event';
import {SpinnerService} from '@matchsource/spinner';
import {USER_PERMISSIONS} from '@matchsource/models/permissions';
import {PermissionsService} from '@matchsource/store/permissions';
import {
  ConfirmationDialogData,
  DynamicConfirmationDialogComponent,
  MessageDialogComponent,
  MessageDialogData,
} from '@matchsource/shared/dialog';
import {AuthService} from 'app/core/services/auth.service';
import {SwitchRolesComponent} from 'app/shared/components/switch-roles/switch-roles.component';

interface SearchOptions {
  searchTerm: string;
  type: SearchTypes | null;
}

@Component({
  selector: 'ms-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ApplicationTooltipService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    UIRouterModule,
    MsLogoComponent,
    UserPermissionsDirective,
    TranslocoDirective,
    NgClass,
    ToggleDirective,
    SearchComponent,
    CpLayoutComponent,
    AnnouncementComponent,
    AsyncPipe,
    UpperCasePipe,
    DatePipe,
    TranslocoPipe,
    SpinnerComponent,
    SwitchRolesComponent,
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  @HostBinding('class')
  hostClass = 'ms-dashboard';

  private searchesPolling: Subscription = null;

  private readonly destroy$ = new Subject<void>();

  private readonly idleModalId = 'IDLE_MODAL';

  readonly workflowLinkShowPermissions = USER_PERMISSIONS.WORKFLOW_LINK_SHOW;

  readonly workflowLinkHidePermissions = USER_PERMISSIONS.WORKFLOW_LINK_HIDE;

  readonly advancedLookupPermissions = USER_PERMISSIONS.PATIENT_ADVANCED_LOOKUP_LINK_SHOW;

  readonly patientCreatingPermissions = USER_PERMISSIONS.PATIENT_CREATE_BUTTON_SHOW;

  readonly searchPermissions = USER_PERMISSIONS.SEARCH_STATUS_BUTTON_SHOW;

  readonly secondaryCodePermissions = USER_PERMISSIONS.SECONDARY_CODE_LINK_SHOW;

  readonly duplicatesViewPermissions = USER_PERMISSIONS.DUPLICATES_VIEW;

  readonly formsDuePermissions = USER_PERMISSIONS.FORMS_DUE_LINK_SHOW;

  readonly settingsPermissions = USER_PERMISSIONS.SETTINGS_EDIT;

  readonly announcementsEditPermissions = USER_PERMISSIONS.ANNOUNCEMENT_EDIT;

  readonly announcementsViewPermissions = USER_PERMISSIONS.ANNOUNCEMENT_VIEW;

  readonly sampleInstructionPermissions = USER_PERMISSIONS.SAMPLE_INSTRUCTION_VIEW;

  readonly dashboardLink = 'dashboard';

  readonly lastLoginDateFormat = 'MMM dd yyyy, HH:mm';

  readonly hasAccessToProtectedArea: boolean = false;

  readonly searchTypes: SearchTypes[];

  readonly isCommonPractice: boolean = false;

  readonly showAnalyticsMenu$: Observable<boolean>;

  readonly signoutDialogId = 'SINGOUT';

  readonly featureChangedDialogId = 'FEATURE_CHANGED';

  readonly myPatientsRoutes = MyPatientsRoutes;
  readonly patientsInProgressRoutes = PatientsInProgressRoutes;
  readonly workflowManagerRoutes = WorkflowManagerRoutes;

  searchOptions: SearchOptions;

  currentRoute$: Observable<string>;

  advancedLookupLinks: string[];

  searchStatusLinks: string[];

  workflowManagerLinks: string[];

  patientsLinks: string[];

  testIds = NavigationHeaderTestIds;

  get isSpinnerActive$(): Observable<boolean> {
    return this.spinnerService.isActive$;
  }

  get isLoggedUser(): boolean {
    return this.userService.isLoggedIn();
  }

  get announcement$(): Observable<AnnouncementModel> {
    return this.announcementService.announcement$;
  }

  get userFullName(): string {
    return this.userService.getUserFullName();
  }

  get userName(): string {
    return this.userService.getUserName();
  }

  get userRole(): string {
    return this.userService.getUserRoles()[0];
  }

  get userNameAbbr(): string {
    return userNameLabel(this.userFullName);
  }

  get lastLogin(): MsApp.DateString {
    return this.userService.getLastLogin();
  }

  get userRoles(): string[] {
    return this.userService.getAvailableRoles();
  }

  get isMultipleRolesUser(): boolean {
    return this.userService.isMultipleRolesUser;
  }

  get hasInProgressSearches$(): Observable<boolean> {
    return this.searches.hasInProgress$;
  }

  get hasUnviewedSearches$(): Observable<boolean> {
    return this.searches.hasUnviewed$;
  }

  get unviewedSearchesCount$(): Observable<number> {
    return this.searches.unviewedCount$;
  }

  constructor(
    private readonly searches: SearchesService,
    @Inject(AppConfigService) private readonly appConfig: MsAppConfig,
    private readonly idle: IdleService,
    private readonly feature: FeatureService,
    private readonly events: EventService,
    private readonly spinnerService: SpinnerService,
    private readonly userService: UserService,
    private readonly permissionsService: PermissionsService,
    private readonly routerStateService: RouterStateService,
    private readonly advancedPatientLookupHelperService: AdvancedPatientLookupHelperService,
    private readonly announcementService: AnnouncementService,
    private readonly serverSourceEvent: ServerSourceEventService,
    private readonly dialog: DialogService,
    private readonly businessPartiesService: BusinessPartiesService,
    private readonly ng2Idle: Idle,
    private readonly $state: StateService,
    private readonly store: Store,
    private readonly router: UIRouter,
    appTooltipService: ApplicationTooltipService,
    private readonly auth: AuthService
  ) {
    appTooltipService.init();
    this.hasAccessToProtectedArea = permissionsService.hasPermissions(USER_PERMISSIONS.ACCESS_TO_PROTECTED_AREA);
    this.isCommonPractice = userService.isCommonPractice();
    this.searchTypes = this.getSearchTypes();

    this.resetSearchOptions(this.router.globals.params as any);
    this.showAnalyticsMenu$ = iif(
      () => this.permissionsService.hasPermissions(USER_PERMISSIONS.LOOOKER_REPORTS_VIEW),
      iif(
        () => this.userService.isTCC(),
        of(true),
        this.businessPartiesService.data$.pipe(
          map(({entities}) => entities),
          map(entities => this.userService.getBPIds().some(bpId => entities[bpId]?.showAnalyticsMenu ?? false))
        )
      ),
      of(false)
    );

    if (this.hasAccessToProtectedArea) {
      this.searches.load();
    }

    this.events.on(AppEvents.FeatureToggleChanges, () => this.onFeaturesChanged(), this.destroy$);
    this.serverSourceEvent.featureToggle$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => setTimeout(() => this.onFeaturesChanged(), 0));
    this.serverSourceEvent.connect();

    this.ng2Idle.setIdle(this.appConfig.idle.timeBeforeWarn);
    this.ng2Idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
  }

  search(searchOptions: SearchOptions): void {
    const navParams = {search: searchOptions.searchTerm};

    switch (searchOptions.type) {
      case SearchTypes.Patient:
        this.routerStateService.navigate('patients-lookup', navParams);
        break;

      case SearchTypes.Donor:
        this.routerStateService.navigate('donors-lookup', navParams);
        break;

      case SearchTypes.Cord:
        this.routerStateService.navigate('cords-lookup', navParams);
        break;

      case SearchTypes.Biobank:
        this.routerStateService.navigate('biobanks-lookup', navParams);
        break;
      // no default
    }
  }

  goToAdvancedLookUp(): void {
    this.advancedPatientLookupHelperService.clear();
    this.routerStateService.navigate(AdvancedPatientLookupRoutes.AdvancedLookup, null, {reload: true});
  }

  ngOnInit(): void {
    this.router.transitionService.onSuccess({}, (transition: Transition) => {
      this.resetSearchOptions(transition.params());
    });

    this.advancedLookupLinks = Object.values(AdvancedPatientLookupRoutes);
    this.searchStatusLinks = Object.values(SearchStatusRoutes);
    this.workflowManagerLinks = Object.values(WorkflowManagerRoutes);
    this.patientsLinks = Object.values({...PatientFormRoutes, ...PatientsInProgressRoutes, ...MyPatientsRoutes});

    this.searches.running$.pipe(pairwise(), takeUntil(this.destroy$)).subscribe(([prev, running]) => {
      this.searches.handleRunning(prev, running);

      if (running.length && !this.searchesPolling) {
        this.searchesPolling = interval(this.appConfig.polling.searchesPollingDelay)
          .pipe(
            filter(() => this.idle.isActive),
            takeUntil(this.destroy$)
          )
          .subscribe(() => this.searches.loadRunning());
      }

      if (!running.length && this.searchesPolling) {
        this.searchesPolling.unsubscribe();
        this.searchesPolling = null;
      }
    });

    this.idle.reactivated$
      .pipe(
        filter(() => this.userService.isLoggedIn()),
        switchMap(() => this.feature.getCurrentFeatures({silent: true, skipError: true}, true)),
        takeUntil(this.destroy$)
      )
      .subscribe((features: string[]) => {
        const cachedFeatures = this.feature.enabledFeatures;

        if (!isEqual(features.sort(), cachedFeatures.sort())) {
          this.events.dispatch(AppEvents.FeatureToggleChanges);
        }
      });

    if (this.ng2Idle.isRunning()) {
      this.ng2Idle.stop();
    }
    this.ng2Idle.watch();
    this.setIdleWarnEventListener();
    this.ng2Idle.onInterrupt.pipe(takeUntil(this.destroy$)).subscribe(() => this.idle.interrupt());
    this.currentRoute$ = this.store.select(RouterState.routeName);
  }

  private setIdleWarnEventListener(): void {
    this.ng2Idle.onTimeoutWarning.pipe(takeUntil(this.destroy$)).subscribe(async () => {
      this.ng2Idle.stop();
      this.idle.enable(false);

      await this.dialog.invoke(this.idleModalId, MessageDialogComponent).open<MessageDialogData>({
        headerText: translate('IDLE.MODAL_HEADER'),
        messageText: translate('IDLE.SESSION_WARNING'),
      }).onClose;

      this.ng2Idle.watch();

      this.idle.enable(true);
    });
  }

  openSearchStatusPage(): void {
    if (this.searches.running.length > 0 || this.searches.unviewed.length === 0) {
      this.routerStateService.navigate(SearchStatusRoutes.SearchStatusRunning);
      return;
    }

    this.routerStateService.navigate(SearchStatusRoutes.SearchStatusUnviewed);
  }

  navToCreatePatient(): void {
    this.routerStateService.navigate(PatientFormRoutes.PatientFormCreate, {id: null}, {reload: true});
  }

  async logout(): Promise<void> {
    if (this.userService.isCP() || this.userService.isTCC() || (await this.signOutConfirmation())) {
      await this.routerStateService.navigate('logout', {$skipGuards: true, logoutManually: true});
    }
  }

  switchRole(role: string): void {
    this.auth.switchRole(role);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.serverSourceEvent.close();
  }

  private getSearchTypes(): SearchTypes[] {
    const searchTypes: SearchTypes[] = [];

    if (this.permissionsService.hasPermissions(USER_PERMISSIONS.PATIENT_LOOKUP_LINK_SHOW)) {
      searchTypes.push(SearchTypes.Patient);
    }

    if (this.permissionsService.hasPermissions(USER_PERMISSIONS.DONOR_LOOKUP_LINK_SHOW)) {
      searchTypes.push(SearchTypes.Donor);
    }

    if (this.permissionsService.hasPermissions(USER_PERMISSIONS.CORD_LOOKUP_LINK_SHOW)) {
      searchTypes.push(SearchTypes.Cord);
    }

    if (this.permissionsService.hasPermissions(USER_PERMISSIONS.BIOBANK_LOOKUP_ALLOW)) {
      searchTypes.push(SearchTypes.Biobank);
    }

    return searchTypes;
  }

  private resetSearchOptions(params: {search?: string; searchType?: SearchTypes} = {}): void {
    const [searchType = null] = this.searchTypes;

    this.searchOptions = {
      type: params.searchType || searchType,
      searchTerm: params.search || '',
    };
  }

  private async signOutConfirmation(): Promise<boolean> {
    const res = await this.dialog
      .invoke(this.signoutDialogId, DynamicConfirmationDialogComponent)
      .open<Partial<ConfirmationDialogData>>({
        confirmMessage: 'USER.SIGN_OUT_WARNING',
        confirmText: 'USER.LOGOUT',
        caption: 'USER.LOGOUT',
      }).onClose;

    return res;
  }

  private async onFeaturesChanged(): Promise<void> {
    const res = await this.dialog
      .invoke(this.featureChangedDialogId, DynamicConfirmationDialogComponent)
      .open<Partial<ConfirmationDialogData>>({
        confirmMessage: 'FEATURE_CHANGED.MESSAGE',
        caption: 'FEATURE_CHANGED.HEADER',
        confirmText: 'FEATURE_CHANGED.RESTART',
        hideCancel: true,
      }).onClose;

    if (res) {
      window.location.reload();
    }
  }

  isStateActive(currentRoute: string, links: string[]): boolean {
    return !!links.find(link => link === currentRoute);
  }
}
