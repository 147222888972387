import {Injectable} from '@angular/core';
import {BiobankDetailsExtraParams, NavigationItemBaseService} from '../../declarations';
import {NavigationItemTitleElementToken, NavigationItemUiModel} from '@matchsource/models/navigation-history';
import {translate} from '@ngneat/transloco';

@Injectable()
export class BiobankDetailsResolver implements NavigationItemBaseService {
  linkMask = 'biobank.biobank-details';

  resolve(item: NavigationItemUiModel): NavigationItemUiModel {
    const {localId} = item.extraParams as BiobankDetailsExtraParams;

    item.titleElements = [
      {
        token: NavigationItemTitleElementToken.Link,
        text: translate(`NAV_HISTORY.LINKS.BIOBANK.BIOBANK-DETAILS`),
      },
      {
        token: NavigationItemTitleElementToken.NewLine,
      },
      {
        token: NavigationItemTitleElementToken.Title,
        text: translate(`COMMON.BIOBANK_ID`),
        postAdditionalText: ` ${localId}`,
      },
    ];

    return item;
  }
}
