/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CaseProgressExportConfig } from '../models/case-progress-export-config';
import { FormDueExportConfig } from '../models/form-due-export-config';
import { OplExportConfig } from '../models/opl-export-config';
import { PatientsExportConfig } from '../models/patients-export-config';
import { SourceExportConfig } from '../models/source-export-config';
import { SourcesExportConfig } from '../models/sources-export-config';
import { WorkupAnswer } from '../models/workup-answer';

@Injectable({
  providedIn: 'root',
})
export class ExportControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation exportWorkup
   */
  static readonly ExportWorkupPath = '/exports/workup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportWorkup()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportWorkup$Response(params: {
    orderGuid?: string;
    workupId?: number;
    sourceGuid: string;
    original: boolean;
    timezoneOffset: number;
    inProgress?: boolean;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ExportControllerService.ExportWorkupPath, 'get');
    if (params) {
      rb.query('orderGuid', params.orderGuid, {});
      rb.query('workupId', params.workupId, {});
      rb.query('sourceGuid', params.sourceGuid, {});
      rb.query('original', params.original, {});
      rb.query('timezoneOffset', params.timezoneOffset, {});
      rb.query('inProgress', params.inProgress, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/pdf',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportWorkup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportWorkup(params: {
    orderGuid?: string;
    workupId?: number;
    sourceGuid: string;
    original: boolean;
    timezoneOffset: number;
    inProgress?: boolean;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Blob> {

    return this.exportWorkup$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation exportAppliedWorkup
   */
  static readonly ExportAppliedWorkupPath = '/exports/workup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportAppliedWorkup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportAppliedWorkup$Response(params: {
    timezoneOffset: number;
    context?: HttpContext
    body: WorkupAnswer
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ExportControllerService.ExportAppliedWorkupPath, 'post');
    if (params) {
      rb.query('timezoneOffset', params.timezoneOffset, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/pdf',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportAppliedWorkup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportAppliedWorkup(params: {
    timezoneOffset: number;
    context?: HttpContext
    body: WorkupAnswer
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Blob> {

    return this.exportAppliedWorkup$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation exportSources
   */
  static readonly ExportSourcesPath = '/exports/sources';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportSources$Pdf()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportSources$Pdf$Response(params: {
    context?: HttpContext
    body: SourcesExportConfig
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ExportControllerService.ExportSourcesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/pdf',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportSources$Pdf$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportSources$Pdf(params: {
    context?: HttpContext
    body: SourcesExportConfig
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Blob> {

    return this.exportSources$Pdf$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportSources$Csv()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportSources$Csv$Response(params: {
    context?: HttpContext
    body: SourcesExportConfig
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ExportControllerService.ExportSourcesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/csv',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportSources$Csv$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportSources$Csv(params: {
    context?: HttpContext
    body: SourcesExportConfig
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<string> {

    return this.exportSources$Csv$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation exportSource
   */
  static readonly ExportSourcePath = '/exports/source';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportSource()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportSource$Response(params: {
    context?: HttpContext
    body: SourceExportConfig
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ExportControllerService.ExportSourcePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/pdf',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportSource$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportSource(params: {
    context?: HttpContext
    body: SourceExportConfig
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Blob> {

    return this.exportSource$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation exportSearchSummaryReport
   */
  static readonly ExportSearchSummaryReportPath = '/exports/search-summary-report';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportSearchSummaryReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportSearchSummaryReport$Response(params: {
    recipientGuid: string;
    searchGuid: string;
    timezoneOffset: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ExportControllerService.ExportSearchSummaryReportPath, 'get');
    if (params) {
      rb.query('recipientGuid', params.recipientGuid, {});
      rb.query('searchGuid', params.searchGuid, {});
      rb.query('timezoneOffset', params.timezoneOffset, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/pdf',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportSearchSummaryReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportSearchSummaryReport(params: {
    recipientGuid: string;
    searchGuid: string;
    timezoneOffset: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Blob> {

    return this.exportSearchSummaryReport$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation exportSearchSummaryReportWithLabs
   */
  static readonly ExportSearchSummaryReportWithLabsPath = '/exports/search-summary-report';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportSearchSummaryReportWithLabs()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportSearchSummaryReportWithLabs$Response(params: {
    recipientGuid: string;
    searchGuid: string;
    timezoneOffset: number;
    context?: HttpContext
    body: Array<string>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ExportControllerService.ExportSearchSummaryReportWithLabsPath, 'post');
    if (params) {
      rb.query('recipientGuid', params.recipientGuid, {});
      rb.query('searchGuid', params.searchGuid, {});
      rb.query('timezoneOffset', params.timezoneOffset, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/pdf',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportSearchSummaryReportWithLabs$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportSearchSummaryReportWithLabs(params: {
    recipientGuid: string;
    searchGuid: string;
    timezoneOffset: number;
    context?: HttpContext
    body: Array<string>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Blob> {

    return this.exportSearchSummaryReportWithLabs$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation exportPatients
   */
  static readonly ExportPatientsPath = '/exports/patients';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportPatients()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportPatients$Response(params: {
    context?: HttpContext
    body: PatientsExportConfig
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ExportControllerService.ExportPatientsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/csv',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportPatients$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportPatients(params: {
    context?: HttpContext
    body: PatientsExportConfig
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<string> {

    return this.exportPatients$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation exportOpl
   */
  static readonly ExportOplPath = '/exports/opl';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportOpl$Pdf()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportOpl$Pdf$Response(params: {
    context?: HttpContext
    body: OplExportConfig
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ExportControllerService.ExportOplPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/pdf',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportOpl$Pdf$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportOpl$Pdf(params: {
    context?: HttpContext
    body: OplExportConfig
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Blob> {

    return this.exportOpl$Pdf$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportOpl$Csv()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportOpl$Csv$Response(params: {
    context?: HttpContext
    body: OplExportConfig
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ExportControllerService.ExportOplPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/csv',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportOpl$Csv$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportOpl$Csv(params: {
    context?: HttpContext
    body: OplExportConfig
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<string> {

    return this.exportOpl$Csv$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportOpl$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportOpl$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response(params: {
    context?: HttpContext
    body: OplExportConfig
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ExportControllerService.ExportOplPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportOpl$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportOpl$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet(params: {
    context?: HttpContext
    body: OplExportConfig
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Blob> {

    return this.exportOpl$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation exportFormDue
   */
  static readonly ExportFormDuePath = '/exports/form-due';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportFormDue()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportFormDue$Response(params: {
    context?: HttpContext
    body: FormDueExportConfig
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ExportControllerService.ExportFormDuePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/pdf',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportFormDue$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportFormDue(params: {
    context?: HttpContext
    body: FormDueExportConfig
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Blob> {

    return this.exportFormDue$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation exportCordShipment1
   */
  static readonly ExportCordShipment1Path = '/exports/cord-shipment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportCordShipment1()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportCordShipment1$Response(params: {
    orderGuid?: string;
    workupId?: number;
    sourceGuid: string;
    original: boolean;
    timezoneOffset: number;
    inProgress?: boolean;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ExportControllerService.ExportCordShipment1Path, 'get');
    if (params) {
      rb.query('orderGuid', params.orderGuid, {});
      rb.query('workupId', params.workupId, {});
      rb.query('sourceGuid', params.sourceGuid, {});
      rb.query('original', params.original, {});
      rb.query('timezoneOffset', params.timezoneOffset, {});
      rb.query('inProgress', params.inProgress, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/pdf',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportCordShipment1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportCordShipment1(params: {
    orderGuid?: string;
    workupId?: number;
    sourceGuid: string;
    original: boolean;
    timezoneOffset: number;
    inProgress?: boolean;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Blob> {

    return this.exportCordShipment1$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation exportCordShipment
   */
  static readonly ExportCordShipmentPath = '/exports/cord-shipment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportCordShipment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportCordShipment$Response(params: {
    timezoneOffset: number;
    context?: HttpContext
    body: WorkupAnswer
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ExportControllerService.ExportCordShipmentPath, 'post');
    if (params) {
      rb.query('timezoneOffset', params.timezoneOffset, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/pdf',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportCordShipment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportCordShipment(params: {
    timezoneOffset: number;
    context?: HttpContext
    body: WorkupAnswer
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Blob> {

    return this.exportCordShipment$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation exportCaseProgress
   */
  static readonly ExportCaseProgressPath = '/exports/case-progress';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportCaseProgress$Pdf()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportCaseProgress$Pdf$Response(params: {
    context?: HttpContext
    body: CaseProgressExportConfig
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ExportControllerService.ExportCaseProgressPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/pdf',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportCaseProgress$Pdf$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportCaseProgress$Pdf(params: {
    context?: HttpContext
    body: CaseProgressExportConfig
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Blob> {

    return this.exportCaseProgress$Pdf$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportCaseProgress$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportCaseProgress$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response(params: {
    context?: HttpContext
    body: CaseProgressExportConfig
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ExportControllerService.ExportCaseProgressPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportCaseProgress$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportCaseProgress$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet(params: {
    context?: HttpContext
    body: CaseProgressExportConfig
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Blob> {

    return this.exportCaseProgress$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation exportSummaryCounts
   */
  static readonly ExportSummaryCountsPath = '/exports/summary-counts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportSummaryCounts()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportSummaryCounts$Response(params: {
    recipientGuid: string;
    phenotypeNumber: number;
    timezoneOffset: number;
    gamidaEligible?: boolean;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ExportControllerService.ExportSummaryCountsPath, 'get');
    if (params) {
      rb.query('recipientGuid', params.recipientGuid, {});
      rb.query('phenotypeNumber', params.phenotypeNumber, {});
      rb.query('timezoneOffset', params.timezoneOffset, {});
      rb.query('gamidaEligible', params.gamidaEligible, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/pdf',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportSummaryCounts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportSummaryCounts(params: {
    recipientGuid: string;
    phenotypeNumber: number;
    timezoneOffset: number;
    gamidaEligible?: boolean;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Blob> {

    return this.exportSummaryCounts$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation exportPatientDetails
   */
  static readonly ExportPatientDetailsPath = '/exports/patient';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportPatientDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportPatientDetails$Response(params: {
    recipientGuid: string;
    phenotypeNumber: number;
    timezoneOffset: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ExportControllerService.ExportPatientDetailsPath, 'get');
    if (params) {
      rb.query('recipientGuid', params.recipientGuid, {});
      rb.query('phenotypeNumber', params.phenotypeNumber, {});
      rb.query('timezoneOffset', params.timezoneOffset, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/pdf',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportPatientDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportPatientDetails(params: {
    recipientGuid: string;
    phenotypeNumber: number;
    timezoneOffset: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Blob> {

    return this.exportPatientDetails$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

}
