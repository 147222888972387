import {Injectable} from '@angular/core';
import {NavigationItemBaseService, OrderFulfillmentExtraParams} from '../../declarations';
import {NavigationItemTitleElementToken, NavigationItemUiModel} from '@matchsource/models/navigation-history';
import {translate} from '@ngneat/transloco';

@Injectable()
export class OrderFulfillmentResolver implements NavigationItemBaseService {
  linkMask = 'order-details';

  resolve(item: NavigationItemUiModel): NavigationItemUiModel {
    const {orderType, isSubsequent = false} = item.extraParams as OrderFulfillmentExtraParams;

    const subType = isSubsequent ? 'SUBSEQUENT' : null;

    const keys = [orderType.toUpperCase(), subType].filter(param => !!param);
    const messageKey = ['NAV_HISTORY.LINKS.ORDER_FULFILLMENT', ...keys].join('.');

    item.titleElements = [
      {
        token: NavigationItemTitleElementToken.Link,
        text: translate(messageKey),
      },
    ];

    return item;
  }
}
