import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Patient} from 'app/features/search-status/declarations';
import {TranslocoPipe} from '@ngneat/transloco';
import {NgIf} from '@angular/common';

@Component({
  selector: 'ms-patient-identification',
  templateUrl: './patient-identification.component.html',
  styleUrls: ['./patient-identification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, TranslocoPipe],
})
export class PatientIdentificationComponent {
  @Input()
  patient: Patient;

  @Input()
  phenotypeId: number;
}
