import {Injectable, Injector} from '@angular/core';
import {Action, Actions, createSelector, ofAction, State, StateContext, Store} from '@ngxs/store';
import {LoadCords, LoadDonors, LoadedCords, LoadedDonors} from 'app/store/haplogic-sources/haplogic-sources.actions';
import {CordListModel} from '@matchsource/models/cord';
import {append, patch} from '@ngxs/store/operators';
import {filter, finalize, take, tap} from 'rxjs/operators';
import {ensureArray, toMap} from '@matchsource/utils';
import {MatchResultsService} from '@matchsource/source-factories';
import {DonorListModel} from '@matchsource/models/donor';

interface HaplogicPatientCordsStateModel {
  loadingIds: MsApp.Guid[];
  entities: MsApp.Dictionary<CordListModel>;
}

interface HaplogicPatientDonorsStateModel {
  loadingIds: MsApp.Guid[];
  entities: MsApp.Dictionary<DonorListModel>;
}

interface HaplogicPatientSourcesStateModel {
  cords: HaplogicPatientCordsStateModel;
  donors: HaplogicPatientDonorsStateModel;
}

interface HaplogicSourcesStateModel {
  patients: MsApp.Dictionary<HaplogicPatientSourcesStateModel>;
}

const defaultPatientState = (): HaplogicPatientSourcesStateModel => ({
  cords: {
    loadingIds: [],
    entities: {},
  },
  donors: {
    loadingIds: [],
    entities: {},
  },
});

const DEFAULT_PATIENT_STATE = defaultPatientState();

@State({
  name: 'haplogicSources',
  defaults: {
    patients: {},
  },
})
@Injectable()
export class HaplogicSourcesState {
  static patientState(patientId: MsApp.Guid) {
    return createSelector(
      [HaplogicSourcesState],
      (state: HaplogicSourcesStateModel) => state.patients[patientId] || DEFAULT_PATIENT_STATE
    );
  }

  static patientCordsState(patientId: MsApp.Guid) {
    return createSelector([this.patientState(patientId)], ({cords}: HaplogicPatientSourcesStateModel) => cords);
  }

  static patientCords(patientId: MsApp.Guid) {
    return createSelector(
      [this.patientCordsState(patientId)],
      ({entities}: HaplogicPatientCordsStateModel) => entities
    );
  }

  static patientCordsById(patientId: MsApp.Guid, sourceIds: MsApp.Guid | MsApp.Guid[]) {
    const sourceIdList = ensureArray(sourceIds);
    return createSelector([this.patientCords(patientId)], (cords: MsApp.Dictionary<CordListModel>) =>
      sourceIdList.reduce<CordListModel[]>((filteredCords, sourceId) => {
        if (sourceId in cords) {
          filteredCords.push(cords[sourceId]);
        }

        return filteredCords;
      }, [])
    );
  }

  static patientCordsAreLoading(patientId: MsApp.Guid, sourceIds: MsApp.Guid | MsApp.Guid[]) {
    const sourceIdList = ensureArray(sourceIds);
    return createSelector([this.patientCordsState(patientId)], ({loadingIds}: HaplogicPatientCordsStateModel) =>
      sourceIdList.some(id => loadingIds.includes(id))
    );
  }

  static patientDonorsState(patientId: MsApp.Guid) {
    return createSelector([this.patientState(patientId)], ({donors}: HaplogicPatientSourcesStateModel) => donors);
  }

  static patientDonors(patientId: MsApp.Guid) {
    return createSelector(
      [this.patientDonorsState(patientId)],
      ({entities}: HaplogicPatientDonorsStateModel) => entities
    );
  }

  static patientDonorsById(patientId: MsApp.Guid, sourceIds: MsApp.Guid | MsApp.Guid[]) {
    const sourceIdList = ensureArray(sourceIds);
    return createSelector([this.patientDonors(patientId)], (donors: MsApp.Dictionary<DonorListModel>) =>
      sourceIdList.reduce<DonorListModel[]>((filteredDonors, sourceId) => {
        if (sourceId in donors) {
          filteredDonors.push(donors[sourceId]);
        }
        return filteredDonors;
      }, [])
    );
  }

  static patientDonorsAreLoading(patientId: MsApp.Guid, sourceIds: MsApp.Guid | MsApp.Guid[]) {
    const sourceIdList = ensureArray(sourceIds);
    return createSelector([this.patientDonorsState(patientId)], ({loadingIds}: HaplogicPatientDonorsStateModel) =>
      sourceIdList.some(id => loadingIds.includes(id))
    );
  }

  constructor(
    private readonly matchResultService: MatchResultsService,
    private readonly injector: Injector,
    private readonly store: Store,
    private readonly actions$: Actions
  ) {}

  private ensurePatientState(ctx: StateContext<HaplogicSourcesStateModel>, patientId: MsApp.Guid) {
    const state = ctx.getState();

    if (!(patientId in state.patients)) {
      ctx.setState(
        patch({
          patients: patch({
            [patientId]: defaultPatientState(),
          }),
        })
      );
    }
  }

  @Action(LoadCords)
  loadCords(ctx: StateContext<HaplogicSourcesStateModel>, {patientId, sourceIds}: LoadCords) {
    this.ensurePatientState(ctx, patientId);

    const {entities, loadingIds} = this.store.selectSnapshot(HaplogicSourcesState.patientCordsState(patientId));

    const isAllLoaded = sourceIds.every(sourceId => sourceId in entities);
    if (isAllLoaded) {
      return;
    }

    const notRequestedIds = sourceIds.filter(sourceId => !(sourceId in entities || loadingIds.includes(sourceId)));

    if (notRequestedIds.length === 0) {
      return this.actions$.pipe(
        ofAction(LoadedCords),
        filter(() => {
          const {loadingIds: remainLoadingIds} = this.store.selectSnapshot(
            HaplogicSourcesState.patientCordsState(patientId)
          );

          return notRequestedIds.every(id => !(id in remainLoadingIds));
        }),
        take(1)
      );
    }

    ctx.setState(
      patch({
        patients: patch({
          [patientId]: patch({
            cords: patch({
              loadingIds: append(notRequestedIds),
            }),
          }),
        }),
      })
    );

    return this.matchResultService.getCords(patientId, notRequestedIds).pipe(
      tap(cords => {
        const cordsMap = toMap(cords, 'id');

        ctx.setState(
          patch({
            patients: patch({
              [patientId]: patch({
                cords: patch({
                  entities: patch(cordsMap),
                }),
              }),
            }),
          })
        );
      }),
      take(1),
      finalize(() => {
        const {loadingIds: currentLoadingIds} = this.store.selectSnapshot(
          HaplogicSourcesState.patientCordsState(patientId)
        );

        ctx.setState(
          patch({
            patients: patch({
              [patientId]: patch({
                cords: patch({
                  loadingIds: currentLoadingIds.filter(id => !notRequestedIds.includes(id)),
                }),
              }),
            }),
          })
        );
        this.store.dispatch(new LoadedCords(patientId));
      })
    );
  }

  @Action(LoadDonors)
  loadDonors(ctx: StateContext<HaplogicSourcesStateModel>, {patientId, sourceIds}: LoadDonors) {
    this.ensurePatientState(ctx, patientId);

    const {entities, loadingIds} = this.store.selectSnapshot(HaplogicSourcesState.patientDonorsState(patientId));

    const isAllLoaded = sourceIds.every(sourceId => sourceId in entities);
    if (isAllLoaded) {
      return;
    }

    const notRequestedIds = sourceIds.filter(sourceId => !(sourceId in entities || loadingIds.includes(sourceId)));

    if (notRequestedIds.length === 0) {
      return this.actions$.pipe(
        ofAction(LoadedDonors),
        filter(() => {
          const {loadingIds: remainLoadingIds} = this.store.selectSnapshot(
            HaplogicSourcesState.patientDonorsState(patientId)
          );

          return notRequestedIds.every(id => !(id in remainLoadingIds));
        }),
        take(1)
      );
    }

    ctx.setState(
      patch({
        patients: patch({
          [patientId]: patch({
            donors: patch({
              loadingIds: append(notRequestedIds),
            }),
          }),
        }),
      })
    );

    return this.matchResultService.getDonors(patientId, notRequestedIds).pipe(
      tap(donors => {
        const donorsMap = toMap(donors, 'id');

        ctx.setState(
          patch({
            patients: patch({
              [patientId]: patch({
                donors: patch({
                  entities: patch(donorsMap),
                }),
              }),
            }),
          })
        );
      }),
      take(1),
      finalize(() => {
        const {loadingIds: currentLoadingIds} = this.store.selectSnapshot(
          HaplogicSourcesState.patientDonorsState(patientId)
        );

        ctx.setState(
          patch({
            patients: patch({
              [patientId]: patch({
                donors: patch({
                  loadingIds: currentLoadingIds.filter(id => !notRequestedIds.includes(id)),
                }),
              }),
            }),
          })
        );
        this.store.dispatch(new LoadedDonors(patientId));
      })
    );
  }
}
