import {ensureArray} from '@matchsource/utils';

export class LoadDonors {
  static readonly type = '[Haplogic sources] Load donors';

  readonly sourceIds: MsApp.Guid[];

  constructor(
    public readonly patientId: MsApp.Guid,
    sourceIds: MsApp.Guid | MsApp.Guid[]
  ) {
    this.sourceIds = ensureArray(sourceIds);
  }
}

export class LoadCords {
  static readonly type = '[Haplogic sources] Load cords';

  readonly sourceIds: MsApp.Guid[];

  constructor(
    public readonly patientId: MsApp.Guid,
    sourceIds: MsApp.Guid[]
  ) {
    this.sourceIds = ensureArray(sourceIds);
  }
}

export class LoadedCords {
  static readonly type = '[Haplogic sources] Cords loaded';

  constructor(public readonly patientId: MsApp.Guid) {}
}

export class LoadedDonors {
  static readonly type = '[Haplogic sources] Donors loaded';

  constructor(public readonly patientId: MsApp.Guid) {}
}
