import {Serializer} from '@matchsource/api-utils';
import {IdentityHistoryModel} from '@matchsource/models/patient';
import {translate} from '@ngneat/transloco';
import {RecipientIdentityVerifyHistory} from '@matchsource/api-generated/subject';

export const patientIdentitySerializer: Serializer<IdentityHistoryModel, RecipientIdentityVerifyHistory> = {
  fromDTO(input: RecipientIdentityVerifyHistory): IdentityHistoryModel {
    return {
      confirmed: input.confirmed,
      confirmedDate: input.confirmedDate,
      tcGuid: input.tcGuid,
      confirmedByFullName: input.confirmedByFullName,
      status: input.confirmed ? translate('PATIENT.CONFIRMED_STATUS') : translate('PATIENT.UNCONFIRMED_STATUS'),
    };
  },
};
