import {NavigationGroupBaseService} from 'app/navigation-history/declarations';
import {
  NavigationGroups,
  NavigationGroupType,
  NavigationItemModel,
  NavigationItemUiModel,
} from '@matchsource/models/navigation-history';
import {Injectable} from '@angular/core';
import {firstValueFrom} from 'rxjs';
import {MatchResultsService} from '@matchsource/source-factories';
import uniqBy from 'lodash-es/uniqBy';
import {BiobankListModel} from '@matchsource/models/biobank';

@Injectable()
export class BioBankDetailsNavigationGroupService implements NavigationGroupBaseService {
  readonly groupType: NavigationGroupType = NavigationGroupType.BioBank;

  readonly prefetchDataEnabled = false;

  private donorCryoMap: MsApp.Dictionary = {};

  constructor(private readonly api: MatchResultsService) {}

  groupBy(item: NavigationItemUiModel): string | number {
    const id = item.routeParams.id;

    return id;
  }

  async group(items: NavigationItemUiModel[]): Promise<NavigationGroups> {
    const [item] = items;
    const id = this.groupBy(item);
    const donorCryoMap = this.donorCryoMap;
    const isSourcePrefetched = id in donorCryoMap;

    return [
      {
        disabled: this.prefetchDataEnabled && !isSourcePrefetched,
        items,
      },
    ];
  }

  async prefetchData<T extends NavigationItemModel>(items: T[]): Promise<void> {
    const patientIds: any[] = uniqBy(items, data => data.routeParams.patientId && data.routeParams.id).map(
      ({routeParams}) => ({
        patientId: routeParams.patientId,
        localId: routeParams.id,
      })
    );

    const donorCryoToSearches: {[key: string]: MsApp.Guid[]} = patientIds.reduce((obj, data) => {
      obj[data.patientId] = obj[data.patientId] ? obj[data.patientId].concat([data.localId]) : [data.localId];
      return obj;
    }, {});

    const res = await Promise.all(
      Object.entries(donorCryoToSearches).map(([patientId, ids]): Promise<BiobankListModel[]> => {
        return firstValueFrom(this.api.getBDPs(patientId, ids));
      })
    );

    this.donorCryoMap = res.reduce((result, data) => {
      data.forEach(arr => {
        result[arr.id] = arr;
      });
      return result;
    }, {});
  }
}
