import {ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef} from '@angular/core';
import {NgxSmartModalComponent} from 'ngx-smart-modal';
import {uniq} from '@matchsource/utils';
import {NgIf, NgTemplateOutlet} from '@angular/common';

@Component({
  selector: 'ms-dynamic-dialog',
  templateUrl: './dynamic-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgTemplateOutlet],
})
export class DynamicDialogComponent {
  @ContentChild(TemplateRef)
  dialogTemplate: TemplateRef<any>;

  @Input()
  set customClass(value: string) {
    this.hostDialog.customClass = value;
  }

  get customClass() {
    return this.hostDialog.customClass;
  }

  get visible() {
    return this.hostDialog.visible;
  }

  constructor(public readonly hostDialog: NgxSmartModalComponent) {}

  appendCustomClass(customClass: string): void {
    const cssClasses: string[] = (this.customClass || '').split(/\s+/);
    cssClasses.push(customClass);

    // setTimeout is used to avoid ExpressionChangedAfterItHasBeenCheckedError error
    setTimeout(() => {
      this.customClass = uniq(cssClasses).join(' ');
    });
  }
}
