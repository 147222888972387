import {Ng2StateDeclaration, Transition, UIView} from '@uirouter/angular';
import {cachingWrapper} from '@matchsource/utils';
import {PatientCartServiceFactory} from '@matchsource/store/cart';
import {WorkupListFactory, WorkupListService} from '@matchsource/store/workup-list';
import {PatientContainerComponent} from './containers/patient-container/patient-container.component';
import {NavigationGroupType} from '@matchsource/models/navigation-history';
import {PatientExtraParams} from 'app/navigation-history';
import {PatientApiService} from '@matchsource/api/patient';
import {ACTUAL_PHENOTYPE_INDEX} from '@matchsource/models/patient-shared';
import {PatientCoreModel} from '@matchsource/models/patient-extended';
import {PatientService} from '@matchsource/store/patient';

export function resolvePatientId(transition: Transition): MsApp.Guid {
  const {patientId} = transition.params();

  return patientId;
}

export async function resolvePatientLegacyModel(
  patientId: MsApp.Guid,
  patientService: PatientService
): Promise<PatientCoreModel> {
  await patientService.loadAsync(patientId);

  return patientService.patientLegacy;
}

export function resolvePatientHlaProvider(patientId: MsApp.Guid, transition: Transition) {
  const patientApiService: PatientApiService = transition.injector().get(PatientApiService);

  return cachingWrapper((index = ACTUAL_PHENOTYPE_INDEX) => patientApiService.getPTRLegacy(patientId, index));
}

export function onPatientExitHandler(transition: Transition): void {
  const params = transition.params('from');
  const patientId = params['patientId'];
  const patientService: PatientService = transition.injector().get(PatientService);

  patientService.clear(patientId);
}

export function resolvePatientCartProvider(patientId: MsApp.Guid, patientCartFactory: PatientCartServiceFactory) {
  return cachingWrapper(() => patientCartFactory.create(patientId));
}

export function resolveWorkupList(patientId: MsApp.Guid, workupListFactory: WorkupListFactory): WorkupListService {
  return workupListFactory.create(patientId);
}

const ROUTES: Array<Ng2StateDeclaration> = [
  {
    parent: 'core',
    abstract: true,
    component: UIView,
    name: 'patient',
    url: '/patient',
    data: {},
  },
  {
    name: 'patient.abstract-view',
    url: '/:patientId',
    abstract: true,
    component: PatientContainerComponent,
    params: {
      patientId: '',
    },
    resolve: [
      {
        token: 'patientId',
        deps: [Transition],
        resolveFn: resolvePatientId,
      },
      {
        token: 'patient',
        deps: ['patientId', PatientService],
        resolveFn: resolvePatientLegacyModel,
      },
      {
        token: 'patientHlaProvider',
        deps: ['patientId', Transition],
        resolveFn: resolvePatientHlaProvider,
      },
      {
        token: 'patientCartResolver',
        deps: ['patientId', PatientCartServiceFactory],
        resolveFn: resolvePatientCartProvider,
      },
      {
        token: 'workupList',
        deps: ['patientId', WorkupListFactory],
        resolveFn: resolveWorkupList,
      },
    ],
    data: {
      navigation: {
        groupType: NavigationGroupType.Patient,
        compareParams: ['patientId'],
        getExtraParams: (transition: Transition): PatientExtraParams => {
          const injector = transition.injector();

          const patient: PatientCoreModel = injector.get('patient');

          return {
            firstName: patient.info.firstName,
            lastName: patient.info.lastName,
            rid: patient.rawNmdpId,
          };
        },
      },
    },
    onExit: onPatientExitHandler,
  },
];

export {ROUTES};
