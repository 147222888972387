import {HttpBackend, HttpClient} from '@angular/common/http';
import {Injectable, NgModule} from '@angular/core';
import {environment} from '../../environments/environment';
import {provideTransloco, TranslocoLoader, TranslocoModule} from '@ngneat/transloco';
import {config} from 'app/app/config';
import {provideTranslocoMessageformat} from '@ngneat/transloco-messageformat';

@Injectable({providedIn: 'root'})
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private readonly httpBackend: HttpBackend) {}

  getTranslation(lang: string) {
    return new HttpClient(this.httpBackend).get(
      `/assets/i18n/${lang}.json?v=${encodeURIComponent(config.BUILD_VERSION)}`
    );
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTranslocoMessageformat(),
    provideTransloco({
      config: {
        availableLangs: ['en'],
        defaultLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: false,
        prodMode: environment.production,
      },
      loader: TranslocoHttpLoader,
    }),
  ],
})
export class TranslocoRootModule {}
