import {NavigationGroupBaseService} from 'app/navigation-history/declarations';
import {
  NavigationGroups,
  NavigationGroupType,
  NavigationItemModel,
  NavigationItemUiModel,
} from '@matchsource/models/navigation-history';
import {Injectable} from '@angular/core';
import {toMap, uniq} from '@matchsource/utils';
import {firstValueFrom} from 'rxjs';
import {CordApiService} from '@matchsource/api/cord';
import {Cord} from '@matchsource/api-generated/subject';

@Injectable()
export class CordSourceNavigationGroup implements NavigationGroupBaseService {
  readonly groupType: NavigationGroupType = NavigationGroupType.CordSource;

  readonly prefetchDataEnabled = false;

  private cordsMap: MsApp.Dictionary<Cord> = {};

  constructor(private readonly cordApiService: CordApiService) {}

  groupBy(item: NavigationItemUiModel): string | number {
    const sourceId = item.routeParams.sourceId;

    return sourceId;
  }

  async group(items: NavigationItemUiModel[]): Promise<NavigationGroups> {
    const [item] = items;
    const sourceId = this.groupBy(item);
    const cordsMap = this.cordsMap;
    const isCordPrefetched = sourceId in cordsMap;

    return [
      {
        disabled: this.prefetchDataEnabled && !isCordPrefetched,
        items,
      },
    ];
  }

  async prefetchData<T extends NavigationItemModel>(items: T[]): Promise<void> {
    const sourcesIds: MsApp.Guid[] = uniq(
      items.map(({routeParams}) => routeParams.sourceId).filter(sourceId => !!sourceId)
    );

    try {
      const cords = await firstValueFrom(this.cordApiService.getCordsByGuids(sourcesIds));
      this.cordsMap = toMap(cords, 'guid');
    } catch (ex) {
      this.cordsMap = {};
    }
  }
}
