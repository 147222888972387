import {State, Action, StateContext, Selector} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {tap, finalize} from 'rxjs/operators';
import {Observable} from 'rxjs';

import {ClearSmartyAddresses, GetSmartyAddresses} from 'app/store/smarty-streets/smarty-streets-autocomplete.actions';
import {AddressApiService} from '@matchsource/api/patient';
import {SmartyAddressWithSecondaryEntries} from '@matchsource/models/smarty-street';

export interface SmartyStreetsAutocompleteStateModel {
  loading: boolean;
  loaded: boolean;
  addressAutocompleteResponse: SmartyAddressWithSecondaryEntries[];
}

const initState = (): SmartyStreetsAutocompleteStateModel => ({
  loading: false,
  loaded: false,
  addressAutocompleteResponse: [],
});

@State<SmartyStreetsAutocompleteStateModel>({
  name: 'SmartyStreetsAutocompleteStateStateModel',
  defaults: initState(),
})
@Injectable()
export class SmartyStretsAutocompleteState {
  constructor(private readonly addressApi: AddressApiService) {}

  @Selector([SmartyStretsAutocompleteState])
  static list(state: SmartyStreetsAutocompleteStateModel): SmartyAddressWithSecondaryEntries[] {
    return state.addressAutocompleteResponse;
  }

  @Selector([SmartyStretsAutocompleteState])
  static loading(state: SmartyStreetsAutocompleteStateModel): boolean {
    return state.loading;
  }

  @Selector([SmartyStretsAutocompleteState])
  static loaded(state: SmartyStreetsAutocompleteStateModel): boolean {
    return state.loaded;
  }

  @Selector([SmartyStretsAutocompleteState.loading, SmartyStretsAutocompleteState.loaded])
  static inProgressOrCompleted(loading: boolean, loaded: boolean): boolean {
    return loading || loaded;
  }

  @Action(GetSmartyAddresses)
  getSmartyAddresses(
    ctx: StateContext<SmartyStreetsAutocompleteStateModel>,
    {request}: GetSmartyAddresses
  ): Observable<SmartyAddressWithSecondaryEntries[]> {
    ctx.patchState({
      loading: true,
    });

    return this.addressApi.getAddressSuggestions(request).pipe(
      tap(addressAutocompleteResponse => {
        ctx.patchState({
          addressAutocompleteResponse,
          loaded: true,
        });
      }),
      finalize(() => ctx.patchState({loading: false}))
    );
  }

  @Action(ClearSmartyAddresses)
  clearSmartyAddresses(ctx: StateContext<SmartyStreetsAutocompleteStateModel>) {
    ctx.patchState(initState());
  }
}
