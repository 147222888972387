import {CoopSourceTypes} from '@matchsource/models/shared';

export const ION_AS_ID_BY_TYPES = ['REG', 'BMD', 'CDS', 'BMC', 'CCS'];

export enum BusinessPartyContractStatus {
  Contracted = 'CONTRACTED',

  NonContracted = 'NON_CONTRACTED',
}

export enum BusinessPartyTypes {
  TransplantCenter = 'TC',

  CommunityPractice = 'CP',

  REG = 'REG',

  CBB = 'CBB',

  DC = 'DC',

  BMD = 'BMD',

  BMC = 'BMC',

  CCS = 'CCS',

  CDS = 'CDS',
}

export class BusinessPartyModel {
  id: MsApp.Guid;
  bpName: string;
  name: string;
  formattedName: string;
  formattedNameCp: string;
  type: BusinessPartyTypes;
  countryCode: string;
  countryName: string;
  identifier: string;
  isDomestic: boolean;
  isActive: boolean;
  identQualifier: string;
  sourceType: CoopSourceTypes;
  ion: string;
  allowCOOPs: boolean;
  address1: string;
  address2: string;
  city: string;
  zipCode: string;
  state: string;
  restrictionType?: string;
  isPatientRestricted?: boolean;
  isCordRestricted?: boolean;
  isDonorRestricted?: boolean;
  patientProcess: boolean;
  domestic: boolean;
  internationalBehavior: boolean;
  doingBusinessAsName?: string;
  internationalFormRequired: boolean;
  showAnalyticsMenu?: boolean;
}

export const PRIMARY_ADDRESS = 'PRIMARY';

export interface BusinessPartyExtendedFieldsModel {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  id: MsApp.Guid;
  name: string;
  countryCode: string;
  // Note: there are more fields, if needed
}

export type BusinessPartyExtendedModel = BusinessPartyModel & BusinessPartyExtendedFieldsModel;
export const TCS_WITH_INTERNATIONAL_BEHAVIOUR = {
  FOR_NBD_USERS: '399',
  NON_NETWORK_TC: '479',
  HLA_TODAY_TC: '500',
};
