import {FormControlNames} from './components/additional-product-information-workup-section/declarations';

export enum DonorWorkupFormRoutes {
  DonorWorkupForm = 'donor-workup-form',
  SubsequentDonorWorkup = 'subsequent-donor-workup',
}

export enum DonorWorkupFormKey {
  RecipientInfo = 'recipientInfo',
  StemCellPreference = 'stemCellPreference',
  PreCollectionSamples = 'preCollectionSamples',
  DayOfCollectionSamples = 'dayOfCollectionSamples',
  AdditionalProductInformation = 'additionalProductInformation',
  OptionalComments = 'optionalComments',
  InternationalForm = 'internationalForms',
  MarrowCollection = 'marrowCollection',
  PBSCCollection = 'pbscCollection',
  OutstandingRequest = 'outstandingRequest',
  IdentifyVerification = 'identifyVerification',
}

export enum DonorWorkupRouteParamKeys {
  DonorId = 'sourceId',
  WorkupId = 'workupId',
  PatientId = 'patientId',
  OrderId = 'orderId',
  Working = 'working',
}

export interface WorkupUrlParams {
  patientId: MsApp.Guid;
  [DonorWorkupRouteParamKeys.WorkupId]: MsApp.Guid;
  [DonorWorkupRouteParamKeys.DonorId]: MsApp.Guid;
  [DonorWorkupRouteParamKeys.OrderId]: MsApp.Guid;
}

export enum AddressesFormControlNames {
  Country = 'country',
  CenterName = 'centerName',
  AttnName = 'attnName',
  Address1 = 'address1',
  Address2 = 'address2',
  Address3 = 'address3',
  City = 'city',
  State = 'state',
  ZipPostal = 'zipPostal',
  Phone = 'phone',
  Email = 'email',
}

export interface WorkupAddressesSubsection {
  [AddressesFormControlNames.Country]: string;
  [AddressesFormControlNames.CenterName]: string;
  [AddressesFormControlNames.AttnName]: string;
  [AddressesFormControlNames.Address1]: string;
  [AddressesFormControlNames.Address2]: string;
  [AddressesFormControlNames.Address3]: string;
  [AddressesFormControlNames.City]: string;
  [AddressesFormControlNames.State]: string;
  [AddressesFormControlNames.ZipPostal]: string;
  [AddressesFormControlNames.Phone]?: string;
  [AddressesFormControlNames.Email]?: string;
}

export interface AdditionalProductInformationForm {
  [FormControlNames.AdditionalProductAddresses]: WorkupAddressesSubsection;
  [FormControlNames.EmergencyContactName1]: string;
  [FormControlNames.EmergencyContactName2]: string;
  [FormControlNames.EmergencyContactPhone1]: string;
  [FormControlNames.EmergencyContactPhone2]: string;
  [FormControlNames.AcEmail]: string;
  [FormControlNames.AcFax]: string;
  [FormControlNames.FormCompletedBy]: string;
  [FormControlNames.FormSubmissionDate]: string;
  [FormControlNames.OrderingPhysician]: string;
  [FormControlNames.FormCompletedOnBehalf]: string;
}
