import {DonorThreshold, SearchCriteriaModel} from '@matchsource/models/patient';
import {SourceType} from '@matchsource/models/source';
import {Parameter} from '@matchsource/api-generated/search-match';
import {CordSearchParams} from '@matchsource/models/cord';

export interface SearchCriteria {
  donors: boolean;
  cords: boolean;
  bdps: boolean;
  donorsMatchThreshold: DonorThreshold | null;
  deprioritizeSearch?: boolean;
}

export interface DonorSearchParams {
  sourceType: 'DONOR';
  matchThreshold: DonorThreshold;
  deprioritized: boolean;
}

export enum SearchStatus {
  Completed = 'COMPLETED',

  Pending = 'PENDING',

  Running = 'RUNNING',

  InQueue = 'INQUEUE',

  Failed = 'FAILED',

  Deferred = 'DEFERRED',
}

export interface SearchStatusModel {
  sourceType: SourceType;
  status: SearchStatus;
  searchCompleteDate: string;
  viewed: boolean;
  abOnly: boolean;
  hapSearchStatus: SearchStatus;
  parameter: DonorSearchParams | CordSearchParams;
  patientId: MsApp.Guid;
  phenotypeNum: number;
  positionInQueue: number;
  searchBundleGuid: MsApp.Guid;
  searchGuid: MsApp.Guid;
}

export enum SearchNotificationType {
  SearchCompleted = 'SEARCH_COMPLETED',
  SearchStatusChanged = 'SEARCH_STATUS_CHANGED',
}

type MatchThresholdLiteral =
  | 'EIGHT_OF_EIGHT'
  | 'SEVEN_OF_EIGHT'
  | 'SIX_OF_EIGHT'
  | 'FIVE_OF_EIGHT'
  | 'FOUR_OF_EIGHT'
  | 'FOUR_OF_SIX';

export interface SearchModelParameter {
  deprioritized?: boolean;
  matchThreshold: MatchThresholdLiteral;
  sourceType: 'DONOR' | 'CORD' | 'BDP';
}

export interface SearchMaintenanceModel {
  lastSearchRunDate?: string;
  maintained?: boolean;
}

export interface SearchModel {
  abOnly: boolean;
  maintenance: SearchMaintenanceModel;
  patientId: MsApp.Guid;
  phenotype: number;
  sourceSearches: SearchStatusModel[];
  parameters: SearchModelParameter[];
  searchCriteria?: SearchCriteriaModel;
  searchGuid: MsApp.Guid;
}

export interface SourceTypeSearchModel {
  abOnly?: boolean;
  parameter?: Parameter;
  patientId?: string;
  phenotype?: number;
  positionInQueue?: number;
  searchBundleGuid?: string;
  searchCompleteDate?: string;
  searchGuid?: string;
  sourceType?: SourceType;
  status?: SearchStatus;
  viewed?: boolean;
}
