import {AppConfigService, config, WIDGET_OPTIONS} from './config';
import {OverlayModule} from '@angular/cdk/overlay';
import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ApplicationRef, DoBootstrap, NgModule} from '@angular/core';
import {ResponseInterceptor} from 'app/core/interceptors/response.interceptor';
import {ErrorCodeZeroResponseInterceptor} from 'app/core/interceptors/error-code-zero-response.interceptor';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {ROUTES} from './routes';
import {ApiModule} from '@matchsource/api-config';
import {StoreModule} from 'app/store/store.module';
import {TranslocoService, TRANSLOCO_TRANSPILER} from '@ngneat/transloco';
import {MessageFormatTranspiler} from '@ngneat/transloco-messageformat';
import {FormModule} from '@matchsource/form';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {environment} from '../../environments/environment';
import {NgxsUiRouterPluginModule, Navigate} from 'ngxs-ui-router';
import {UIRouterModule, UIView} from '@uirouter/angular';
import {AuthService} from 'app/core/services/auth.service';
import {IdleService} from 'app/core/services/idle.service';
import {throttleTime, filter, switchMap, debounceTime, take, startWith} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {TranslocoRootModule} from 'app/transloco/transloco.module';
import {DragulaModule} from 'ng2-dragula';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {ToastNoAnimation, ToastNoAnimationModule, ToastrModule} from 'ngx-toastr';
import {NmdpWidget, NmdpWidgetModule, SESSION_CLOSED, SESSION_TIMEOUT} from '@nmdp/nmdp-login';
import {TokenInterceptor} from 'app/core/interceptors/token.interceptor';
import {ApiModule as CommonApiModule} from '@matchsource/api-generated/common';
import {ApiModule as SubjectApiModule} from '@matchsource/api-generated/subject';
import {ApiModule as OrdersApiModule} from '@matchsource/api-generated/orders';
import {ApiModule as SearchMatchApiModule} from '@matchsource/api-generated/search-match';
import {ApiModule as NotificationsApiModule} from '@matchsource/api-generated/notifications';
import {ApiModule as DocumentsApiModule} from '@matchsource/api-generated/documents';
import {
  NgxSmartModalAdapterModule,
  NgxToastrAdapterModule,
  TooltipModule,
  popperVariation,
  tooltipVariation,
} from '@matchsource/nmdp-ui';
import {FeatureService, FeatureToggleStateDeclaration} from '@matchsource/feature-toggle';
import {FeatureToggleRootModule} from 'app/app/feature-toggle-root.module';
import {OrdersModule} from 'app/features/orders/orders.module';
import {SearchCompletedService} from 'app/shared/services/search-completed.service';
import {ErrorHandlingService} from '@matchsource/error-handling/ui';
import {provideNgxMask} from 'ngx-mask';
import {ReactiveFormsModule} from '@angular/forms';
import {PatientRootModule} from 'app/features/patient-root/patient-root.module';
import {NavigationHistoryModule} from 'app/navigation-history/navigation-history.module';
import {firstValueFrom} from 'rxjs';
import {NgIdleModule} from '@ng-idle/core';
import {configureRouter} from './routing.config';
import {WalkmeService} from 'app/shared/services/walkme.service';
import {SpinnerInterceptor, UserService} from '@matchsource/core';
import {AnalyticsService} from '@matchsource/analytics';

async function restoreSession(auth: AuthService, user: UserService) {
  if (user.isLoggedIn() === false && (await auth.isSessionAlive())) {
    await firstValueFrom(auth.restoreSession());
    await firstValueFrom(auth.login());
  }
}

export function initializeApp(
  auth: AuthService,
  idle: IdleService,
  widget: NmdpWidget,
  appConfig: MsAppConfig,
  store: Store,
  transloco: TranslocoService,
  user: UserService,
  feature: FeatureService,
  analytics: AnalyticsService,
  errorHandlingService: ErrorHandlingService,
  walkmeService: WalkmeService
) {
  return async () => {
    if (!environment.production) {
      feature.overwrite(environment.overwriteFeatures);
    }

    analytics.init();
    errorHandlingService.init();
    auth.init();

    idle.interrupted.pipe(debounceTime(appConfig.idle.timeBeforeIdleBackgroundJobs * 1000)).subscribe(() => {
      idle.pause();
    });

    idle.pause$
      .pipe(
        filter(paused => paused),
        switchMap(() => idle.interrupted)
      )
      .subscribe(() => idle.resume());

    idle.active$
      .pipe(
        filter(active => active),
        switchMap(() => idle.interrupted.pipe(startWith(true))),
        throttleTime(appConfig.idle.interruptThrottleTime * 1000)
      )
      .subscribe(() => widget.markActive());

    widget.onEvent
      .pipe(
        filter(event => event.type === SESSION_TIMEOUT || event.type === SESSION_CLOSED),
        switchMap(() =>
          idle.active$.pipe(
            filter(isActive => isActive),
            take(1)
          )
        )
      )
      .subscribe(async () => {
        if (!(await auth.isSessionAlive())) {
          return;
        }

        auth.sessionDestroyed();
        store.dispatch(new Navigate('logout', {$skipGuards: true, refreshLogin: true}));
      });

    await Promise.all([firstValueFrom(transloco.load('en')), restoreSession(auth, user)]);
    if (user.isLoggedIn()) {
      await feature.load();
      walkmeService.load();
    }
  };
}

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    OverlayModule,
    // @TODO: Temporary solution to fix https://jira.nmdp.org/browse/MS-30796. Radio component should be refactored to avoid this fix.
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
      warnOnNgModelWithFormControl: 'never',
    }),
    UIRouterModule.forRoot({
      states: ROUTES,
      config: configureRouter,
      useHash: true,
    }),
    ApiModule.forRoot(config.APP_CONFIG),
    StoreModule,
    FormModule.forRoot(),
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
      disableConsoleLogging: !config.APP_CONFIG.debug,
    }),
    NgxWebstorageModule.forRoot({prefix: 'unite', separator: '.', caseSensitive: true}),
    NmdpWidgetModule.forRoot(WIDGET_OPTIONS),
    NgxsUiRouterPluginModule,
    HttpClientModule,
    TranslocoRootModule,
    DragulaModule.forRoot(),
    NgxSmartModalModule.forRoot(),
    ToastNoAnimationModule,
    ToastrModule.forRoot({
      toastComponent: ToastNoAnimation,
      closeButton: true,
      enableHtml: true,
    }),
    TooltipModule.forRoot({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: {
          ...tooltipVariation,
          arrow: true,
          duration: 0,
          delay: 50,
          maxWidth: 600,
          allowHTML: true,
          theme: 'default-tooltip',
        },
        popper: popperVariation,
      },
    }),
    CommonApiModule.forRoot({
      rootUrl: config.APP_CONFIG.api_endpoint + config.APP_CONFIG.context.common,
    }),
    SearchMatchApiModule.forRoot({
      rootUrl: config.APP_CONFIG.api_endpoint + config.APP_CONFIG.context.searchMatch,
    }),
    SubjectApiModule.forRoot({
      rootUrl: config.APP_CONFIG.api_endpoint + config.APP_CONFIG.context.subject,
    }),
    OrdersApiModule.forRoot({
      rootUrl: config.APP_CONFIG.api_endpoint + config.APP_CONFIG.context.order,
    }),
    SearchMatchApiModule.forRoot({
      rootUrl: config.APP_CONFIG.api_endpoint + config.APP_CONFIG.context.searchMatch,
    }),
    NotificationsApiModule.forRoot({
      rootUrl: config.APP_CONFIG.api_endpoint + config.APP_CONFIG.context.notifications,
    }),
    DocumentsApiModule.forRoot({
      rootUrl: config.APP_CONFIG.api_endpoint + config.APP_CONFIG.context.documents,
    }),
    NgxSmartModalAdapterModule,
    NgxToastrAdapterModule,
    FeatureToggleRootModule,
    OrdersModule,
    PatientRootModule,
    NgIdleModule.forRoot(),
    NavigationHistoryModule,
  ],
  providers: [
    AuthService,
    NgxsUiRouterPluginModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCodeZeroResponseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [
        AuthService,
        IdleService,
        NmdpWidget,
        AppConfigService,
        Store,
        TranslocoService,
        UserService,
        FeatureService,
        AnalyticsService,
        ErrorHandlingService,
        WalkmeService,
      ],
      multi: true,
    },
    {
      provide: TRANSLOCO_TRANSPILER,
      useClass: MessageFormatTranspiler,
    },
    provideNgxMask(),
  ],
})
export class AppModule implements DoBootstrap {
  constructor(
    private readonly feature: FeatureService,
    private readonly searchCompleted: SearchCompletedService
  ) {}

  ngDoBootstrap(appRef: ApplicationRef) {
    appRef.bootstrap(UIView);

    this.feature.init(ROUTES as FeatureToggleStateDeclaration[]);
    this.searchCompleted.init();
  }
}
