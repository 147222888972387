import {MapTo} from '@matchsource/api-utils';
import {DonorWorkupModel, FullDonorWorkupModel} from '@matchsource/models/donor-workup';
import {WuDefaultWorkup, WorkupInProgressInfo} from '@matchsource/api-generated/subject';
import {Workup} from '@matchsource/api-generated/orders';
import {FormCode, WorkupInProgressModel} from '@matchsource/models/workup';

interface DonorWorkupMetadata {
  patientId?: MsApp.Guid;
  tcId?: MsApp.Guid;
  sourceId?: MsApp.Guid;
  completed?: boolean;
  workupId?: number;
}

export const mapDefaultWorkupDtoToModel: MapTo<WuDefaultWorkup, DonorWorkupModel | null> = (
  input: WuDefaultWorkup
): DonorWorkupModel | null => {
  if (!input) {
    return null;
  }

  return {
    psamMlAcd: input.psamMlAcd,
    psamMlEdta: input.psamMlEdta,
    psamMlHep: input.psamMlHep,
    psamMlClot: input.psamMlClot,
    psamShipCenterName: input.psamShipCenterName,
    psamShipAttnName: input.psamShipAttnName,
    psamShipAddrLine1: input.psamShipAddrLine1,
    psamShipAddrLine2: input.psamShipAddrLine2,
    psamShipAddrLine3: input.psamShipAddrLine3,
    psamShipCity: input.psamShipCity,
    psamShipStateProvince: input.psamShipStateProvince,
    psamShipCountry: input.psamShipCountry,
    psamShipZipCode: input.psamShipZipCode,
    psamShipTelephone: input.psamShipTelephone,
    psamShipEmail: input.psamShipEmail,
    psamRequirePsams: input.psamRequirePsams,
    psamRequireSecondSetPsams: input.psamRequireSecondSetPsams,
    psamRequireCtPerformedPsams: input.psamRequireCtPerformedPsams,
    psamMlAcdSecond: input.psamMlAcdSecond,
    psamMlEdtaSecond: input.psamMlEdtaSecond,
    psamMlHepSecond: input.psamMlHepSecond,
    psamMlClotSecond: input.psamMlClotSecond,
    psamShipCenterNameSecond: input.psamShipCenterNameSecond,
    psamShipAttnNameSecond: input.psamShipAttnNameSecond,
    psamShipAddrLine1Second: input.psamShipAddrLine1Second,
    psamShipAddrLine2Second: input.psamShipAddrLine2Second,
    psamShipAddrLine3Second: input.psamShipAddrLine3Second,
    psamShipCitySecond: input.psamShipCitySecond,
    psamShipStateProvinceSecond: input.psamShipStateProvinceSecond,
    psamShipCountrySecond: input.psamShipCountrySecond,
    psamShipZipCodeSecond: input.psamShipZipCodeSecond,
    psamShipTelephoneSecond: input.psamShipTelephoneSecond,
    psamShipEmailSecond: input.psamShipEmailSecond,
    psamRationaleForMlRequest: input.psamRationaleForMlRequest,
    psamSpecSamplesCollected: input.psamSpecSamplesCollected,
    psamAdditionalComments: input.psamAdditionalComments,

    diagnosis: input.diagnosis,
    diagnosisOthrSpecify: input.diagnosisOthrSpecify,
    cmlStatus: input.cmlStatus,
    saaTransfused: input.saaTransfused,
    amlAllOrOther: input.amlAllOrOther,
    numberOfRemissions: input.numberOfRemissions,
    previousAlloHtcInd: input.previousAlloHtcInd,

    firstChoice: input.firstChoice,
    secondChoice: input.secondChoice,

    dcsamDay1Acd: input.dcsamDay1Acd,
    dcsamDay1Edta: input.dcsamDay1Edta,
    dcsamDay1Hep: input.dcsamDay1Hep,
    dcsamDay1Clot: input.dcsamDay1Clot,
    dcsamDay2Acd: input.dcsamDay2Acd,
    dcsamDay2Edta: input.dcsamDay2Edta,
    dcsamDay2Hep: input.dcsamDay2Hep,
    dcsamDay2Clot: input.dcsamDay2Clot,
    prodDay1Acd: input.prodDay1Acd,
    prodDay1Edta: input.prodDay1Edta,
    prodDay1Hep: input.prodDay1Hep,
    prodDay1Clot: input.prodDay1Clot,
    prodDay2Acd: input.prodDay2Acd,
    prodDay2Edta: input.prodDay2Edta,
    prodDay2Hep: input.prodDay2Hep,
    prodDay2Clot: input.prodDay2Clot,

    dcsamWillBeSentWithProd: input.dcsamWillBeSentWithProd,
    dcsamCenterName: input.dcsamCenterName,
    dcsamAttnName: input.dcsamAttnName,
    dcsamAddrLine1: input.dcsamAddrLine1,
    dcsamAddrLine2: input.dcsamAddrLine2,
    dcsamAddrLine3: input.dcsamAddrLine3,
    dcsamCity: input.dcsamCity,
    dcsamStateProv: input.dcsamStateProv,
    dcsamZipPostalCode: input.dcsamZipPostalCode,
    dcsamCountry: input.dcsamCountry,
    dcsamPhone: input.dcsamPhone,
    dcsamEmail: input.dcsamEmail,

    dcsamAddnlComments: input.dcsamAddnlComments,

    dcsamRequireSecondSet: input.dcsamRequireSecondSet,

    dcsamDay1AcdSecond: input.dcsamDay1AcdSecond,
    dcsamDay1EdtaSecond: input.dcsamDay1EdtaSecond,
    dcsamDay1HepSecond: input.dcsamDay1HepSecond,
    dcsamDay1ClotSecond: input.dcsamDay1ClotSecond,
    dcsamDay2AcdSecond: input.dcsamDay2AcdSecond,
    dcsamDay2EdtaSecond: input.dcsamDay2EdtaSecond,
    dcsamDay2HepSecond: input.dcsamDay2HepSecond,
    dcsamDay2ClotSecond: input.dcsamDay2ClotSecond,
    prodDay1AcdSecond: input.prodDay1AcdSecond,
    prodDay1EdtaSecond: input.prodDay1EdtaSecond,
    prodDay1HepSecond: input.prodDay1HepSecond,
    prodDay1ClotSecond: input.prodDay1ClotSecond,
    prodDay2AcdSecond: input.prodDay2AcdSecond,
    prodDay2EdtaSecond: input.prodDay2EdtaSecond,
    prodDay2HepSecond: input.prodDay2HepSecond,
    prodDay2ClotSecond: input.prodDay2ClotSecond,
    dcsamAddnlCommentsSecond: input.dcsamAddnlCommentsSecond,

    dcsamDeliveryCenterName: input.dcsamDeliveryCenterName,
    dcsamDeliveryAttnName: input.dcsamDeliveryAttnName,
    dcsamDeliveryAddrLine1: input.dcsamDeliveryAddrLine1,
    dcsamDeliveryAddrLine2: input.dcsamDeliveryAddrLine2,
    dcsamDeliveryAddrLine3: input.dcsamDeliveryAddrLine3,
    dcsamDeliveryCity: input.dcsamDeliveryCity,
    dcsamDeliveryStateProv: input.dcsamDeliveryStateProv,
    dcsamDeliveryZipCode: input.dcsamDeliveryZipCode,
    dcsamDeliveryCountry: input.dcsamDeliveryCountry,
    dcsamDeliveryEmail: input.dcsamDeliveryEmail,
    dcsamDeliveryPhone: input.dcsamDeliveryPhone,

    prodDeliveryTcName: input.prodDeliveryTcName,
    prodDeliveryAttnName: input.prodDeliveryAttnName,
    prodDeliveryAddrLine1: input.prodDeliveryAddrLine1,
    prodDeliveryAddrLine2: input.prodDeliveryAddrLine2,
    prodDeliveryAddrLine3: input.prodDeliveryAddrLine3,
    prodDeliveryCity: input.prodDeliveryCity,
    prodDeliveryStateProvince: input.prodDeliveryStateProvince,
    prodDeliveryZip: input.prodDeliveryZip,
    prodDeliveryCountry: input.prodDeliveryCountry,
    prodDeliveryPhone: input.prodDeliveryPhone,
    prodDeliveryEmail: input.prodDeliveryEmail,
    firstEmergencyContPhn: input.firstEmergencyContPhn,
    firstEmergencyContNme: input.firstEmergencyContNme,
    secondEmergencyContPhn: input.secondEmergencyContPhn,
    secondEmergencyContNme: input.secondEmergencyContNme,
    aphCtrEmail: input.aphCtrEmail,
    aphCtrFax: input.aphCtrFax,
    formCompletedBy: input.formCompletedBy,
    formSubmissionDte: input.formSubmissionDte,
    orderingPhysician: input.orderingPhysician,
    completeOnBehalf: input.completeOnBehalf,

    nuclPerKg: input.nuclPerKg,
    recipWeight: input.recipWeight,
    nuclCellsQa: input.nuclCellsQa,
    totalCellsRecip: input.totalCellsRecip,
    totalCellsReq: input.totalCellsReq,
    marrRationaleRequest: input.marrRationaleRequest,
    marrCryopreservRequested: input.marrCryopreservRequested,
    marrSelectCryopreservLoc: input.marrSelectCryopreservLoc,
    anticoagulant: input.anticoagulant,
    amtRatio: input.amtRatio,
    anticoagulantOthr: input.anticoagulantOthr,
    marrSpecialProcessReq: input.marrSpecialProcessReq,
    marrAdditionalComments: input.marrAdditionalComments,
    marrowTransportCondition: input.marrowTransportCondition,
    firstChoiceMar: input.firstChoiceMar,
    clearDay1FirstChoiceMar: input.clearDay1FirstChoiceMar,
    marrNumDaysPatientPreparativeRegimen: input.marrNumDaysPatientPreparativeRegimen,
    marrDnrcntrPrvds1stAvail: input.marrDnrcntrPrvds1stAvail,
    marrSchedOnCertainWeekday: input.marrSchedOnCertainWeekday,
    marrNumDaysClearance: input.marrNumDaysClearance,
    marrSchedSelSun: input.marrSchedSelSun,
    marrSchedSelMon: input.marrSchedSelMon,
    marrSchedSelTue: input.marrSchedSelTue,
    marrSchedSelWed: input.marrSchedSelWed,
    marrSchedSelThu: input.marrSchedSelThu,
    marrSchedSelFri: input.marrSchedSelFri,
    marrSchedSelSat: input.marrSchedSelSat,

    cd34Cells: input.cd34Cells,
    pbscRecipWeight: input.pbscRecipWeight,
    cd34ForQa: input.cd34ForQa,
    totalCd34: input.totalCd34,
    totalCd34Requested: input.totalCd34Requested,
    pbscRationaleRequest: input.pbscRationaleRequest,
    pbscSecondDayRationale: input.pbscSecondDayRationale,
    pbscNctNumber: input.pbscNctNumber,
    pbscOtherProvideRationale: input.pbscOtherProvideRationale,
    pbscCryopreservRequested: input.pbscCryopreservRequested,
    pbscSelectCryopreservLoc: input.pbscSelectCryopreservLoc,
    pbscDonorPlasmaRequested: input.pbscDonorPlasmaRequested,
    pbscPlasmaVolumeMl: input.pbscPlasmaVolumeMl,
    pbscSelOneForPlasmaDeliv: input.pbscSelOneForPlasmaDeliv,
    pbscAdditivesToProduct: input.pbscAdditivesToProduct,
    pbscSpecifyAdditives: input.pbscSpecifyAdditives,
    pbscVolumeRatio: input.pbscVolumeRatio,
    pbscSpecialProcessReq: input.pbscSpecialProcessReq,
    pbscAdditionalComments: input.pbscAdditionalComments,
    firstChoicePbsc: input.firstChoicePbsc,
    clearDay1FirstChoicePbsc: input.clearDay1FirstChoicePbsc,
    pbscNumDaysPatientPreparativeRegimen: input.pbscNumDaysPatientPreparativeRegimen,
    pbscDnrcntrPrvds1stAvail: input.pbscDnrcntrPrvds1stAvail,
    pbscSchedOnCertainWeekday: input.pbscSchedOnCertainWeekday,
    pbscNumDaysClearance: input.pbscNumDaysClearance,
    pbscSchedSelSun: input.pbscSchedSelSun,
    pbscSchedSelMon: input.pbscSchedSelMon,
    pbscSchedSelTue: input.pbscSchedSelTue,
    pbscSchedSelWed: input.pbscSchedSelWed,
    pbscSchedSelThu: input.pbscSchedSelThu,
    pbscSchedSelFri: input.pbscSchedSelFri,
    pbscSchedSelSat: input.pbscSchedSelSat,

    outstandingRequestCancel: input.outstandingRequestCancel,
    multDonorsHeldForWorkup: input.multDonorsHeldForWorkup,
    outstandReqAddnlComments: input.outstandReqAddnlComments,
    id1HeldForWorkup: input.id1HeldForWorkup,
    id2HeldForWorkup: input.id2HeldForWorkup,
    cb1Id: input.cb1Id,
    cb2Id: input.cb2Id,
    cb3Id: input.cb3Id,
    cb4Id: input.cb4Id,
    backupDonorsHeldForWorkup: input.backupDonorsHeldForWorkup,
    backupCbuOnHold: input.backupCbuOnHold,
    optionalCommentsText: input.optionalCommentsText,
    identifyVerification: input.patientIdentityVerifiedInd,
  };
};

export const mapWorkupModelToDefaultWorkupDto: MapTo<DonorWorkupModel, WuDefaultWorkup> = (
  input: DonorWorkupModel,
  {patientId = null, tcId = null}: DonorWorkupMetadata = {}
): WuDefaultWorkup => ({
  psamMlAcd: input.psamMlAcd,
  psamMlEdta: input.psamMlEdta,
  psamMlHep: input.psamMlHep,
  psamMlClot: input.psamMlClot,
  psamShipCenterName: input.psamShipCenterName,
  psamShipAttnName: input.psamShipAttnName,
  psamShipAddrLine1: input.psamShipAddrLine1,
  psamShipAddrLine2: input.psamShipAddrLine2,
  psamShipAddrLine3: input.psamShipAddrLine3,
  psamShipCity: input.psamShipCity,
  psamShipStateProvince: input.psamShipStateProvince,
  psamShipCountry: input.psamShipCountry,
  psamShipZipCode: input.psamShipZipCode,
  psamShipTelephone: input.psamShipTelephone,
  psamShipEmail: input.psamShipEmail,
  psamRequirePsams: input.psamRequirePsams,
  psamRequireSecondSetPsams: input.psamRequireSecondSetPsams,
  psamRequireCtPerformedPsams: input.psamRequireCtPerformedPsams,
  psamMlAcdSecond: input.psamMlAcdSecond,
  psamMlEdtaSecond: input.psamMlEdtaSecond,
  psamMlHepSecond: input.psamMlHepSecond,
  psamMlClotSecond: input.psamMlClotSecond,
  psamShipCenterNameSecond: input.psamShipCenterNameSecond,
  psamShipAttnNameSecond: input.psamShipAttnNameSecond,
  psamShipAddrLine1Second: input.psamShipAddrLine1Second,
  psamShipAddrLine2Second: input.psamShipAddrLine2Second,
  psamShipAddrLine3Second: input.psamShipAddrLine3Second,
  psamShipCitySecond: input.psamShipCitySecond,
  psamShipStateProvinceSecond: input.psamShipStateProvinceSecond,
  psamShipCountrySecond: input.psamShipCountrySecond,
  psamShipZipCodeSecond: input.psamShipZipCodeSecond,
  psamShipTelephoneSecond: input.psamShipTelephoneSecond,
  psamShipEmailSecond: input.psamShipEmailSecond,
  psamRationaleForMlRequest: input.psamRationaleForMlRequest,
  psamSpecSamplesCollected: input.psamSpecSamplesCollected,
  psamAdditionalComments: input.psamAdditionalComments,
  subjectGuid: patientId,
  bpGuid: tcId,

  diagnosis: input.diagnosis,
  diagnosisOthrSpecify: input.diagnosisOthrSpecify,
  cmlStatus: input.cmlStatus,
  saaTransfused: input.saaTransfused,
  amlAllOrOther: input.amlAllOrOther,
  numberOfRemissions: input.numberOfRemissions,
  previousAlloHtcInd: input.previousAlloHtcInd,

  firstChoice: input.firstChoice,
  secondChoice: input.secondChoice,

  dcsamDay1Acd: input.dcsamDay1Acd,
  dcsamDay1Edta: input.dcsamDay1Edta,
  dcsamDay1Hep: input.dcsamDay1Hep,
  dcsamDay1Clot: input.dcsamDay1Clot,
  dcsamDay2Acd: input.dcsamDay2Acd,
  dcsamDay2Edta: input.dcsamDay2Edta,
  dcsamDay2Hep: input.dcsamDay2Hep,
  dcsamDay2Clot: input.dcsamDay2Clot,
  prodDay1Acd: input.prodDay1Acd,
  prodDay1Edta: input.prodDay1Edta,
  prodDay1Hep: input.prodDay1Hep,
  prodDay1Clot: input.prodDay1Clot,
  prodDay2Acd: input.prodDay2Acd,
  prodDay2Edta: input.prodDay2Edta,
  prodDay2Hep: input.prodDay2Hep,
  prodDay2Clot: input.prodDay2Clot,

  dcsamWillBeSentWithProd: input.dcsamWillBeSentWithProd,
  dcsamCenterName: input.dcsamCenterName,
  dcsamAttnName: input.dcsamAttnName,
  dcsamAddrLine1: input.dcsamAddrLine1,
  dcsamAddrLine2: input.dcsamAddrLine2,
  dcsamAddrLine3: input.dcsamAddrLine3,
  dcsamCity: input.dcsamCity,
  dcsamStateProv: input.dcsamStateProv,
  dcsamZipPostalCode: input.dcsamZipPostalCode,
  dcsamCountry: input.dcsamCountry,
  dcsamPhone: input.dcsamPhone,
  dcsamEmail: input.dcsamEmail,

  dcsamAddnlComments: input.dcsamAddnlComments,

  dcsamRequireSecondSet: input.dcsamRequireSecondSet,

  dcsamDay1AcdSecond: input.dcsamDay1AcdSecond,
  dcsamDay1EdtaSecond: input.dcsamDay1EdtaSecond,
  dcsamDay1HepSecond: input.dcsamDay1HepSecond,
  dcsamDay1ClotSecond: input.dcsamDay1ClotSecond,
  dcsamDay2AcdSecond: input.dcsamDay2AcdSecond,
  dcsamDay2EdtaSecond: input.dcsamDay2EdtaSecond,
  dcsamDay2HepSecond: input.dcsamDay2HepSecond,
  dcsamDay2ClotSecond: input.dcsamDay2ClotSecond,
  prodDay1AcdSecond: input.prodDay1AcdSecond,
  prodDay1EdtaSecond: input.prodDay1EdtaSecond,
  prodDay1HepSecond: input.prodDay1HepSecond,
  prodDay1ClotSecond: input.prodDay1ClotSecond,
  prodDay2AcdSecond: input.prodDay2AcdSecond,
  prodDay2EdtaSecond: input.prodDay2EdtaSecond,
  prodDay2HepSecond: input.prodDay2HepSecond,
  prodDay2ClotSecond: input.prodDay2ClotSecond,
  dcsamAddnlCommentsSecond: input.dcsamAddnlCommentsSecond,

  dcsamDeliveryCenterName: input.dcsamDeliveryCenterName,
  dcsamDeliveryAttnName: input.dcsamDeliveryAttnName,
  dcsamDeliveryAddrLine1: input.dcsamDeliveryAddrLine1,
  dcsamDeliveryAddrLine2: input.dcsamDeliveryAddrLine2,
  dcsamDeliveryAddrLine3: input.dcsamDeliveryAddrLine3,
  dcsamDeliveryCity: input.dcsamDeliveryCity,
  dcsamDeliveryStateProv: input.dcsamDeliveryStateProv,
  dcsamDeliveryZipCode: input.dcsamDeliveryZipCode,
  dcsamDeliveryCountry: input.dcsamDeliveryCountry,
  dcsamDeliveryEmail: input.dcsamDeliveryEmail,
  dcsamDeliveryPhone: input.dcsamDeliveryPhone,

  prodDeliveryTcName: input.prodDeliveryTcName,
  prodDeliveryAttnName: input.prodDeliveryAttnName,
  prodDeliveryAddrLine1: input.prodDeliveryAddrLine1,
  prodDeliveryAddrLine2: input.prodDeliveryAddrLine2,
  prodDeliveryAddrLine3: input.prodDeliveryAddrLine3,
  prodDeliveryCity: input.prodDeliveryCity,
  prodDeliveryPhone: input.prodDeliveryPhone,
  prodDeliveryEmail: input.prodDeliveryEmail,
  prodDeliveryStateProvince: input.prodDeliveryStateProvince,
  prodDeliveryZip: input.prodDeliveryZip,
  prodDeliveryCountry: input.prodDeliveryCountry,
  firstEmergencyContPhn: input.firstEmergencyContPhn,
  firstEmergencyContNme: input.firstEmergencyContNme,
  secondEmergencyContPhn: input.secondEmergencyContPhn,
  secondEmergencyContNme: input.secondEmergencyContNme,
  aphCtrEmail: input.aphCtrEmail,
  aphCtrFax: input.aphCtrFax,
  formCompletedBy: input.formCompletedBy,
  formSubmissionDte: input.formSubmissionDte,
  orderingPhysician: input.orderingPhysician,
  completeOnBehalf: input.completeOnBehalf,

  nuclPerKg: input.nuclPerKg,
  recipWeight: input.recipWeight,
  nuclCellsQa: input.nuclCellsQa,
  totalCellsRecip: input.totalCellsRecip,
  totalCellsReq: input.totalCellsReq,
  marrRationaleRequest: input.marrRationaleRequest,
  marrCryopreservRequested: input.marrCryopreservRequested,
  marrSelectCryopreservLoc: input.marrSelectCryopreservLoc,
  anticoagulant: input.anticoagulant,
  amtRatio: input.amtRatio,
  anticoagulantOthr: input.anticoagulantOthr,
  marrSpecialProcessReq: input.marrSpecialProcessReq,
  marrAdditionalComments: input.marrAdditionalComments,
  marrowTransportCondition: input.marrowTransportCondition,
  firstChoiceMar: input.firstChoiceMar,
  clearDay1FirstChoiceMar: input.clearDay1FirstChoiceMar,
  marrNumDaysPatientPreparativeRegimen: input.marrNumDaysPatientPreparativeRegimen,
  marrDnrcntrPrvds1stAvail: input.marrDnrcntrPrvds1stAvail,
  marrSchedOnCertainWeekday: input.marrSchedOnCertainWeekday,
  marrNumDaysClearance: input.marrNumDaysClearance,
  marrSchedSelSun: input.marrSchedSelSun,
  marrSchedSelMon: input.marrSchedSelMon,
  marrSchedSelTue: input.marrSchedSelTue,
  marrSchedSelWed: input.marrSchedSelWed,
  marrSchedSelThu: input.marrSchedSelThu,
  marrSchedSelFri: input.marrSchedSelFri,
  marrSchedSelSat: input.marrSchedSelSat,

  cd34Cells: input.cd34Cells,
  pbscRecipWeight: input.pbscRecipWeight,
  cd34ForQa: input.cd34ForQa,
  totalCd34: input.totalCd34,
  totalCd34Requested: input.totalCd34Requested,
  pbscRationaleRequest: input.pbscRationaleRequest,
  pbscSecondDayRationale: input.pbscSecondDayRationale,
  pbscNctNumber: input.pbscNctNumber,
  pbscOtherProvideRationale: input.pbscOtherProvideRationale,
  pbscCryopreservRequested: input.pbscCryopreservRequested,
  pbscSelectCryopreservLoc: input.pbscSelectCryopreservLoc,
  pbscDonorPlasmaRequested: input.pbscDonorPlasmaRequested,
  pbscPlasmaVolumeMl: input.pbscPlasmaVolumeMl,
  pbscSelOneForPlasmaDeliv: input.pbscSelOneForPlasmaDeliv,
  pbscAdditivesToProduct: input.pbscAdditivesToProduct,
  pbscSpecifyAdditives: input.pbscSpecifyAdditives,
  pbscVolumeRatio: input.pbscVolumeRatio,
  pbscSpecialProcessReq: input.pbscSpecialProcessReq,
  pbscAdditionalComments: input.pbscAdditionalComments,
  firstChoicePbsc: input.firstChoicePbsc,
  clearDay1FirstChoicePbsc: input.clearDay1FirstChoicePbsc,
  pbscNumDaysPatientPreparativeRegimen: input.pbscNumDaysPatientPreparativeRegimen,
  pbscDnrcntrPrvds1stAvail: input.pbscDnrcntrPrvds1stAvail,
  pbscSchedOnCertainWeekday: input.pbscSchedOnCertainWeekday,
  pbscNumDaysClearance: input.pbscNumDaysClearance,
  pbscSchedSelSun: input.pbscSchedSelSun,
  pbscSchedSelMon: input.pbscSchedSelMon,
  pbscSchedSelTue: input.pbscSchedSelTue,
  pbscSchedSelWed: input.pbscSchedSelWed,
  pbscSchedSelThu: input.pbscSchedSelThu,
  pbscSchedSelFri: input.pbscSchedSelFri,
  pbscSchedSelSat: input.pbscSchedSelSat,

  outstandingRequestCancel: input.outstandingRequestCancel,
  multDonorsHeldForWorkup: input.multDonorsHeldForWorkup,
  outstandReqAddnlComments: input.outstandReqAddnlComments,
  id1HeldForWorkup: input.id1HeldForWorkup,
  id2HeldForWorkup: input.id2HeldForWorkup,
  cb1Id: input.cb1Id,
  cb2Id: input.cb2Id,
  cb3Id: input.cb3Id,
  cb4Id: input.cb4Id,
  backupDonorsHeldForWorkup: input.backupDonorsHeldForWorkup,
  backupCbuOnHold: input.backupCbuOnHold,
  optionalCommentsText: input.optionalCommentsText,
  patientIdentityVerifiedInd: input.identifyVerification,
});

export const mapWorkupModelToDto: MapTo<DonorWorkupModel, Workup> = (
  input: DonorWorkupModel,
  metadata: DonorWorkupMetadata = {}
): Workup => {
  const defaultWorkupDto = mapWorkupModelToDefaultWorkupDto(input, metadata);

  return {
    ...defaultWorkupDto,
    sourceGuid: metadata.sourceId,
    completed: metadata.completed,
    workupId: metadata.workupId,
    saaTransfusedRemoved: input.saaTransfusedRemoved,
    amlAllOrOtherRemoved: input.amlAllOrOtherRemoved,
    otherDiagnosisRemoved: input.otherDiagnosisRemoved,
    numberOfRemissionRemoved: input.numberOfRemissionRemoved,
    cmlStatusRemoved: input.cmlStatusRemoved,
    patientIdentityVerifiedInd: input.identifyVerification,
  };
};

export const mapWorkupInProgressInfoToModel: MapTo<WorkupInProgressInfo, WorkupInProgressModel> = (
  input: WorkupInProgressInfo
): WorkupInProgressModel => ({
  isCompleted: input.completed,
  createDate: input.createDate,
  id: input.id,
  sourceId: input.sourceId,
  gamidaOmisirgeOrderInd: input.gamidaOmisirgeOrderInd,
  sourceType: input.sourceType,
  patientIdentityVerifiedInd: input.patientIdentityVerifiedInd,
  formCode: input.formCode as FormCode,
});

export const mapWorkupDtoToModel: MapTo<Workup, DonorWorkupModel> = (input: Workup): DonorWorkupModel => {
  return mapDefaultWorkupDtoToModel(input);
};

export const mapWorkupDtoToFullModel: MapTo<Workup, FullDonorWorkupModel> = (input: Workup): FullDonorWorkupModel => {
  return {
    ...mapDefaultWorkupDtoToModel(input),
    workupId: input.workupId,
    lastUpdated: input.lastUpdated,
    lastUpdatedBy: input.lastUpdatedBy,
  };
};
