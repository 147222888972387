import {VALIDATION_MESSAGES} from '@matchsource/nmdp-ui';
import {ValidationErrors} from '@angular/forms';
import {ALPHA_VALIDATOR_KEY} from '../validators/alpha.validator';
import {ALPHA_NUMERIC_VALIDATOR_KEY} from '../validators/alpha-numeric.validator';
import {ALPHA_PERIODS_APOSTROPHES_DASHES_SPACES_VALIDATOR_KEY} from '../validators/alpha-periods-apostrophes-dashes-spaces.validator';
import {HAS_ALPHA_DASH_VALIDATOR_KEY} from '../validators/has-alpha-dash.validator';
import {HAS_ALPHA_NUMERIC_VALIDATOR_KEY} from '../validators/has-alpha-numeric.validator';
import {HAS_NUMERIC_VALIDATOR_KEY} from '../validators/has-numeric.validator';
import {NO_LEADING_SPACES_VALIDATOR_KEY} from '../validators/no-leading-spaces.validator';
import {NO_REPEATING_DASHES_VALIDATOR_KEY} from '../validators/no-repeating-dashes.validator';
import {NO_TRAILING_SPACES_VALIDATOR_KEY} from '../validators/no-trailing-spaces.validator';
import {ZIP_POSTAL_CODE_VALIDATOR_KEY} from '../validators/zip-postal-code.validator';
import {NOT_EMPTY_VALIDATOR_KEY} from '../validators/not-empty.validator';
import {DATE_VALIDATOR_KEY} from '../validators/valid-date.validator';
import {
  NUMERIC_NOT_EMPTY_VALIDATOR_KEY,
  NUMERIC_VALIDATOR_KEY,
  WHOLE_NUMERIC_VALIDATOR_KEY,
} from '../validators/numeric.validator';
import {RANGE_KEY} from '../validators/range.validator';
import {NUMERIC_RANGE_DATE_KEY, NUMERIC_RANGE_KEY} from '../validators/numeric-range.validator';
import {NUMERIC_DECIMAL_VALIDATOR_KEY, NUMERIC_DECIMALS_VALIDATOR_KEY} from '../validators/numeric-decimal.validator';
import {DECIMAL_RANGE_KEY} from '../validators/decimal-range.validator';
import {DECIMAL_VALIDATOR_KEY} from '../validators/decimal.validator';
import {NO_SPACES_VALIDATOR_KEY} from '../validators/no-spaces.validator';
import {INVALID_ENTRY_KEY} from '../validators/invalid-entry.validator';
import {WHOLE_NUMBER_KEY} from '../validators/numeric-whole.validator';
import {OVER_WEIGHT_KEY} from '../validators/over-weight.validator';
import {ALPHA_NUMERIC_PERIODS_APOSTROPHES_DASHES_SPACES} from '../validators/alpha-numeric-periods-apostrophes-dashes-spaces.validator';
import {SMARTY_ERROR_CODE} from '../validators/smarty.validator';
import {MIN_FIVE_VALIDATOR_KEY} from '../validators/min-five-symbols.validator';
import {NUMERIC_DASHES_PARENTHESIS_KEY} from '../validators/numeric-dashes-parenthesis.validator';
import {MIN_TEN_VALIDATOR_KEY} from '../validators/min-ten.validator';
import {EMAIL_VALIDATOR_KEY} from '../validators/email.validator';
import {ALPHA_SPACES_APOSTROPHES_DASHES_VALIDATOR_KEY} from '../validators/alpha-spaces-apostrophes-dashes.validator';
import {FULLNAME_VALIDATOR_KEY} from '../validators/full-name.validator';
import {WORKUP_DECIMAL_KEY} from '../validators/workup-decimal.validator';
import {translate} from '@ngneat/transloco';
import {EXPORT_LIMIT_KEY} from '../validators/export-limit.validator';
import {PAST_DATE_VALIDATOR_KEY} from '../validators/past-date.validator';
import {GREATER_THAN_MIN_KEY} from '../validators/greater-than-minimum.validator';
import {OPL_NAME_NOT_UNIQUE_KEY} from '../validators/not-unique-opl-name';
import {NUMERIC_ONE_DECIMAL_VALIDATOR_KEY} from '../validators/one-decimal.validator';
import {NUMERIC_TWO_DECIMAL_VALIDATOR_KEY} from '../validators/two-decimal.validator';
import {
  OPL_LIMIT_GRID_VALIDATION_KEY,
  OPL_SOURCE_EXIST_GRID_VALIDATION_KEY,
  OPL_UNIQUE_NAME_GRID_VALIDATION_KEY,
} from '../validators/opls-errors-validators';
import {ALPHA_NUMERIC_COLON_VALIDATOR_KEY} from '../validators/alpha-numeric-colon.validator';
import {HAS_ALPHA_NUMERIC_DASH_VALIDATOR_KEY} from '../validators/has-alpha-numeric-dash.validator';
import {ALPHA_NUMERIC_PERIODS_APOSTROPHES_DASHES_SPACES_PARENTHESIS_VALIDATOR_KEY} from '../validators/alpha-numeric-periods-apostrophes-dashes-spaces-parenthesis.validator';
import {INVALID_TYPING_KEY} from '../validators/invalid-typing.validator';
import {NAME_VALIDATOR_KEY} from '../validators/name.validator';
import {NUMERIC_HUNDREDTH_DECIMAL_VALIDATOR_KEY} from '../validators/numeric-decimal-hundredth-antibody.validator';
import {ALPHA_ONLY_VALIDATOR_KEY} from '../validators/alpha-only.validator';
import {DATE_FORMAT_VALIDATOR_KEY} from '../validators/valid-date-format.validator';
import {INCOMPLETE_NAME_VALIDATOR_KEY} from '../validators/incomplete-name.validator';
import {HLA_ANTIBODY_PROFILE_NAME_KEY} from '../validators/hla-antibody-profile-name.validators';
import {HAS_ALPHA_PERIODS_APOSTROPHES_DASHES_KEY} from '../validators/has-alpha-periods-apostrophes-dashes-spaces.validator';
import {NOT_START_WITH_VALIDATOR_KEY} from '../validators/not-start-with.validator';
import {NOT_END_WITH_VALIDATOR_KEY} from '../validators/not-end-with.validator';
import {
  PAST_MONTH_DATE_VALIDATOR_KEY,
  PAST_MONTH_DATE_VALIDATOR_KEY_NEW,
} from '../validators/past-month-date.validator';
import {OPL_MAX_LIMIT_VALIDATOR_KEY} from '../validators/opl-max-limit.validator';
import {DECIMAL_XX_XX_KEY} from '../validators/decimal-hundredths-precision.validator';
import {FUTURE_DATE_VALIDATOR_KEY} from '../validators/future-date.validator';
import {FUTURE_DATE_TODAY_VALIDATOR_KEY} from '../validators/future-date-today-included.validator';
import {TNC_CD34_KEY} from '../validators/tnc-cd34.validator';
import {SAME_OR_FUTURE_MONTH_VALIDATOR_KEY} from '../validators/same-or-future-month.validator';
import {ASYNC_LABREF_EXISTS_KEY} from '../validators/async-labref-exists.validator';
import {ONE_TO_NINETY_NINE} from '../validators/one-to-ninety-nine-numeric.validator';
import {NOT_EMPTY_ITEM_VALIDATOR_KEY} from '../validators/not-empty-item.validator';
import {INVALID_PERIPHERAL_BLOOD_MAX_VALUE_KEY} from '../validators/invalid-peripheral-blood-max-value.validator';
import {EMAIL_EXTENDED_VALIDATOR_KEY} from '../validators/email-extended.validator';
import {FAX_VALIDATOR_KEY} from '../validators/fax.validator';
import {CHAIN_OF_IDENTITY_VALIDATOR_KEY} from '../validators/chain-of-identity.validator';
import {
  NO_LEADING_ZERO_DONOR_WORKUP_VALIDATOR_KEY,
  NO_LEADING_ZERO_INTEGER_VALIDATOR_KEY,
  NO_LEADING_ZERO_CORD_WORKUP_VALIDATOR_KEY,
} from '../validators/no-leading-zero.validator';
import {LOCAL_ID_ALREADY_IN_USE} from '../validators/local-id-already-in-use.validator';
import {REF_ID_ALREADY_IN_USE} from '../validators/ref-id-already-in-use.validator';
import {COOP_DONOR_GRID_VALIDATION_KEY, GRID_VALIDATION_MESSAGE, ONLY_TWO_TYPING_VALIDATOR_KEY} from '../constants';

export function validationMessagesFactory() {
  return {
    [ALPHA_VALIDATOR_KEY]: translate('VALIDATORS.ALPHA'),
    [NUMERIC_VALIDATOR_KEY]: translate('VALIDATORS.NUMERIC'),
    [WHOLE_NUMERIC_VALIDATOR_KEY]: translate('VALIDATORS.WHOLE_NUMERIC'),
    [ALPHA_NUMERIC_VALIDATOR_KEY]: translate('VALIDATORS.ALPHA_NUMERIC'),
    [ALPHA_PERIODS_APOSTROPHES_DASHES_SPACES_VALIDATOR_KEY]: translate(
      'VALIDATORS.ALPHA_PERIODS_APOSTROPHES_DASHES_SPACES'
    ),
    [HAS_ALPHA_DASH_VALIDATOR_KEY]: translate('VALIDATORS.HAS_ALPHA_DASH'),
    [HAS_ALPHA_NUMERIC_VALIDATOR_KEY]: translate('VALIDATORS.HAS_ALPHA_NUMERIC'),
    [HAS_NUMERIC_VALIDATOR_KEY]: translate('VALIDATORS.HAS_NUMERIC'),
    [NO_LEADING_SPACES_VALIDATOR_KEY]: translate('VALIDATORS.NO_LEADING_SPACES'),
    [NO_REPEATING_DASHES_VALIDATOR_KEY]: translate('VALIDATORS.NO_REPEATING_DASHES'),
    [NO_TRAILING_SPACES_VALIDATOR_KEY]: translate('VALIDATORS.NO_TRAILING_SPACES'),
    [ZIP_POSTAL_CODE_VALIDATOR_KEY]: translate('VALIDATORS.ZIP_POSTAL_CODE'),
    [NOT_EMPTY_VALIDATOR_KEY]: translate('VALIDATORS.NOT_EMPTY_VALIDATOR_KEY'),
    [DATE_VALIDATOR_KEY]: translate('VALIDATORS.VALID_DATE'),
    minlength: (params: ValidationErrors) => translate('VALIDATORS.MIN_LENGTH', params),
    [RANGE_KEY]: translate('VALIDATORS.INVALID_RANGE'),
    [NUMERIC_RANGE_KEY]: (params: ValidationErrors) => translate('VALIDATORS.NUMERIC_RANGE', params),
    [EXPORT_LIMIT_KEY]: (params: ValidationErrors) => translate('VALIDATORS.EXPORT_LIMIT', params),
    [NUMERIC_DECIMAL_VALIDATOR_KEY]: translate('VALIDATORS.NUMBER_DECIMAL'),
    [NUMERIC_DECIMALS_VALIDATOR_KEY]: translate('VALIDATORS.NUMBER_DECIMALS'),
    [DECIMAL_RANGE_KEY]: (params: ValidationErrors) => translate('VALIDATORS.NUMERIC_RANGE', params),
    [DECIMAL_VALIDATOR_KEY]: (params: ValidationErrors) => translate('VALIDATORS.DECIMAL', params),
    serverError: (params: ValidationErrors) => params,
    [NO_SPACES_VALIDATOR_KEY]: translate('VALIDATORS.NO_SPACES'),
    [INVALID_ENTRY_KEY]: translate('VALIDATORS.INVALID_ENTRY'),
    [PAST_DATE_VALIDATOR_KEY]: translate('VALIDATORS.INVALID_ENTRY'),
    [FUTURE_DATE_VALIDATOR_KEY]: translate('VALIDATORS.INVALID_ENTRY_FUTURE_DATE'),
    [FUTURE_DATE_TODAY_VALIDATOR_KEY]: translate('VALIDATORS.INVALID_ENTRY'),
    [SAME_OR_FUTURE_MONTH_VALIDATOR_KEY]: translate('VALIDATORS.INVALID_ENTRY'),
    [WHOLE_NUMBER_KEY]: translate('VALIDATORS.WHOLE_NUMBER'),
    [OVER_WEIGHT_KEY]: translate('VALIDATORS.INVALID_OVER_WEIGHT'),
    [ALPHA_NUMERIC_PERIODS_APOSTROPHES_DASHES_SPACES]: translate(
      'VALIDATORS.ALPHA_NUMERIC_PERIODS_APOSTROPHES_DASHES_SPACES_VALIDATION'
    ),
    [SMARTY_ERROR_CODE]: translate('VALIDATORS.SMARTY_VALIDATION'),
    [MIN_FIVE_VALIDATOR_KEY]: translate('VALIDATORS.MIN_FIVE'),
    [MIN_TEN_VALIDATOR_KEY]: translate('VALIDATORS.MIN_TEN'),
    [NUMERIC_DASHES_PARENTHESIS_KEY]: translate('VALIDATORS.NUMERIC_DASHES_PARENTHESIS'),
    [EMAIL_VALIDATOR_KEY]: translate('VALIDATORS.EMAIL'),
    [EMAIL_EXTENDED_VALIDATOR_KEY]: translate('VALIDATORS.EMAIL'),
    [FAX_VALIDATOR_KEY]: translate('VALIDATORS.FAX'),
    [ALPHA_SPACES_APOSTROPHES_DASHES_VALIDATOR_KEY]: translate('VALIDATORS.ALPHA_SPACES_APOSTROPHES_DASHES'),
    [FULLNAME_VALIDATOR_KEY]: translate('VALIDATORS.FULLNAME'),
    [WORKUP_DECIMAL_KEY]: translate('VALIDATORS.NUMBER_DECIMAL'),
    [OPL_UNIQUE_NAME_GRID_VALIDATION_KEY]: translate('OPL.OPL_NAME_NOT_UNIQUE'),
    [OPL_LIMIT_GRID_VALIDATION_KEY]: translate('OPL.OPL_LIMIT'),
    [OPL_SOURCE_EXIST_GRID_VALIDATION_KEY]: (params: ValidationErrors) => translate('OPL.OPL_SOURCE_EXISTS', params),
    [GREATER_THAN_MIN_KEY]: translate('VALIDATORS.GREATER_THAN_MIN'),
    [NUMERIC_RANGE_DATE_KEY]: translate('VALIDATORS.INVALID_ENTRY'),
    [OPL_NAME_NOT_UNIQUE_KEY]: translate('VALIDATORS.OPL_NAME_NOT_UNIQUE'),
    [NUMERIC_ONE_DECIMAL_VALIDATOR_KEY]: translate('VALIDATORS.NUMBER_ONE_DECIMAL'),
    [NUMERIC_TWO_DECIMAL_VALIDATOR_KEY]: translate('VALIDATORS.NUMBER_TWO_DECIMAL'),
    [ALPHA_NUMERIC_COLON_VALIDATOR_KEY]: translate('VALIDATORS.ALPHA_NUMERIC_COLON'),
    [HAS_ALPHA_NUMERIC_DASH_VALIDATOR_KEY]: translate('VALIDATORS.HAS_ALPHA_NUMERIC_DASH'),
    [ALPHA_NUMERIC_PERIODS_APOSTROPHES_DASHES_SPACES_PARENTHESIS_VALIDATOR_KEY]: translate(
      'VALIDATORS.ALPHA_NUMERIC_PERIODS_APOSTROPHES_DASHES_SPACES_PARENTHESIS'
    ),
    [INVALID_TYPING_KEY]: translate('VALIDATORS.INVALID_TYPING'),
    [NAME_VALIDATOR_KEY]: translate('VALIDATORS.NAME'),
    [NUMERIC_HUNDREDTH_DECIMAL_VALIDATOR_KEY]: translate('VALIDATORS.INVALID_ENTRY'),
    [ALPHA_ONLY_VALIDATOR_KEY]: translate('VALIDATORS.LETTERS_ONLY'),
    [DATE_FORMAT_VALIDATOR_KEY]: translate('VALIDATORS.INVALID_FORMAT'),
    [INCOMPLETE_NAME_VALIDATOR_KEY]: translate('VALIDATORS.INCOMPLETE_NAME'),
    [HAS_ALPHA_PERIODS_APOSTROPHES_DASHES_KEY]: translate('VALIDATORS.HAS_ALPHA_PERIODS_APOSTROPHES_DASHES'),
    [HLA_ANTIBODY_PROFILE_NAME_KEY]: translate('VALIDATORS.HLA_ANTIBODY_PROFILE_NAME'),
    [NOT_START_WITH_VALIDATOR_KEY]: translate('VALIDATORS.INVALID_TYPING'),
    [ONLY_TWO_TYPING_VALIDATOR_KEY]: translate('VALIDATORS.ONLY_TWO_TYPING'),
    [NOT_END_WITH_VALIDATOR_KEY]: translate('VALIDATORS.INVALID_TYPING'),
    [PAST_MONTH_DATE_VALIDATOR_KEY]: translate('VALIDATORS.INVALID_FORMAT'),
    [PAST_MONTH_DATE_VALIDATOR_KEY_NEW]: translate('VALIDATORS.FUTURE_DATE'),
    [OPL_MAX_LIMIT_VALIDATOR_KEY]: translate('VALIDATORS.OPL_LIMIT'),
    [DECIMAL_XX_XX_KEY]: translate('VALIDATORS.INVALID_XX_POINT_XX_FORMAT'),
    [TNC_CD34_KEY]: translate('VALIDATORS.TNC_CD34_KEY_FORMAT'),
    [GRID_VALIDATION_MESSAGE.ERR_LENGTH]: translate('COOP_SEARCHES.VALIDATION.INVALID_LENGTH'),
    [GRID_VALIDATION_MESSAGE.ERR_CHECKSUM]: translate('COOP_SEARCHES.VALIDATION.CHECKSUM'),
    [GRID_VALIDATION_MESSAGE.ERR_FIRST_CHAR_ZERO]: translate('COOP_SEARCHES.VALIDATION.FIRST_CHAR_ZERO'),
    [GRID_VALIDATION_MESSAGE.ERR_FIRST_FOUR_CHARS_NON_NUMERIC]: translate(
      'COOP_SEARCHES.VALIDATION.FIRST_FOUR_CHARS_NON_NUMERIC'
    ),
    [GRID_VALIDATION_MESSAGE.ERR_CHECKSUM_NON_NUMERIC]: translate('COOP_SEARCHES.VALIDATION.CHECKSUM_NON_NUMERIC'),
    [GRID_VALIDATION_MESSAGE.ERR_NON_UNIQUE_IND]: translate('COOP_SEARCHES.VALIDATION.ERR_NON_UNIQUE_IND'),
    [COOP_DONOR_GRID_VALIDATION_KEY]: translate('COOP_SEARCHES.VALIDATION.PLEASE_ENTER_GRID_AND_OR_REGISTRY_DONOR_ID'),
    [ASYNC_LABREF_EXISTS_KEY]: translate('VALIDATORS.LABREF_ALREADY_EXISTS'),
    [ONE_TO_NINETY_NINE]: translate('VALIDATORS.INVALID_ENTRY'),
    [NOT_EMPTY_ITEM_VALIDATOR_KEY]: translate('VALIDATORS.NOT_EMPTY_ITEM'),
    [INVALID_PERIPHERAL_BLOOD_MAX_VALUE_KEY]: translate('VALIDATORS.INVALID_PERIPHERAL_BLOOD_MAX_VALUE'),
    [CHAIN_OF_IDENTITY_VALIDATOR_KEY]: translate('VALIDATORS.CHAIN_OF_IDENTITY'),
    [NO_LEADING_ZERO_INTEGER_VALIDATOR_KEY]: translate('VALIDATORS.INVALID_INTEGER'),
    [NO_LEADING_ZERO_CORD_WORKUP_VALIDATOR_KEY]: translate('VALIDATORS.INVALID_INTEGER_WORKUP'),
    [NUMERIC_NOT_EMPTY_VALIDATOR_KEY]: translate('VALIDATORS.INVALID_NUMBER_ENTRY'),
    [NO_LEADING_ZERO_DONOR_WORKUP_VALIDATOR_KEY]: translate('VALIDATORS.INVALID_NUMBER_VALUE'),
    [LOCAL_ID_ALREADY_IN_USE]: translate('VALIDATORS.ID_ALREADY_IN_USE'),
    [REF_ID_ALREADY_IN_USE]: translate('VALIDATORS.ID_ALREADY_IN_USE'),
  };
}

export const VALIDATORS_PROVIDER = {
  provide: VALIDATION_MESSAGES,
  useFactory: validationMessagesFactory,
  multi: true,
};
