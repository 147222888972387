import {NavigationGroupBaseService} from 'app/navigation-history/declarations';
import {
  NavigationGroups,
  NavigationGroupType,
  NavigationItemModel,
  NavigationItemUiModel,
} from '@matchsource/models/navigation-history';
import {Injectable} from '@angular/core';
import {toMap, uniq} from '@matchsource/utils';
import {firstValueFrom} from 'rxjs';
import {DonorApiService} from '@matchsource/api/donor';
import {DonorBaseModel} from '@matchsource/models/donor';

@Injectable()
export class DonorSourceNavigationGroup implements NavigationGroupBaseService {
  readonly groupType: NavigationGroupType = NavigationGroupType.DonorSource;

  readonly prefetchDataEnabled = false;

  private donorsMap: MsApp.Dictionary<DonorBaseModel> = {};

  constructor(private readonly donorApiService: DonorApiService) {}

  groupBy(item: NavigationItemUiModel): string | number {
    const sourceId = item.routeParams.sourceId;

    return sourceId;
  }

  async group(items: NavigationItemUiModel[]): Promise<NavigationGroups> {
    const [item] = items;
    const sourceId = this.groupBy(item);
    const donorsMap = this.donorsMap;
    const isDonorPrefetched = sourceId in donorsMap;

    return [
      {
        disabled: this.prefetchDataEnabled && !isDonorPrefetched,
        items,
      },
    ];
  }

  async prefetchData<T extends NavigationItemModel>(items: T[]): Promise<void> {
    const sourcesIds: MsApp.Guid[] = uniq(
      items.map(({routeParams}) => routeParams.sourceId).filter(sourceId => !!sourceId)
    );

    try {
      const donors = await firstValueFrom(this.donorApiService.getDonorsBase(sourcesIds));

      this.donorsMap = toMap(donors, 'guid');
    } catch (ex) {
      this.donorsMap = {};
    }
  }
}
