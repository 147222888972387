export const ERROR_NOTIFICATION = 'http_proxy.error';

export enum FeatureToggleKey {
  Vaccination = 'VACCINATION',
  FamilyTyping = 'FAMILY_TYPING',
  SubsequentDonorRequest = 'SEC_WORKUP',
  RoleSelection = 'ROLE_SELECTION',
}

export enum AppEvents {
  FeatureToggleChanges = 'FeatureToggleChanged',
}
