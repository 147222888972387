import {NmdpWidget} from '@nmdp/nmdp-login';
import {Injectable} from '@angular/core';
import {of, zip, merge, Observable, from, firstValueFrom} from 'rxjs';
import {map, mapTo, switchMap, tap} from 'rxjs/operators';
import {ClearPermissions, LoadPermissions, PermissionsService} from '@matchsource/store/permissions';
import {Store} from '@ngxs/store';
import {CaseResourceControllerService, LoginControllerService, UserDetails} from '@matchsource/api-generated/common';

import {Logout} from 'app/store/user/user.actions';
import {EventAction, EventCategory, LoginLogoutAnalyticsModel} from '@matchsource/models/analytics';
import {createUser, UserService} from '@matchsource/core';
import {AnalyticsService} from '@matchsource/analytics';
import {RouterStateService} from 'ngxs-ui-router';

const OKTA_WIDGET_LOCATION_ID = 'nmdp-login-container';
const OKTA_WIDGET_LOCATION = `#${OKTA_WIDGET_LOCATION_ID}`;

const logEvents = (eventAction: EventAction.Login | EventAction.Logout): LoginLogoutAnalyticsModel => ({
  eventCategory: EventCategory.Feature,
  eventClass: 'UserSession',
  eventAction,
});

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private initialized = false;
  private sessionStatus: Promise<boolean>;

  constructor(
    private readonly user: UserService,
    public readonly widget: NmdpWidget,
    private readonly loginControllerService: LoginControllerService,
    private readonly store: Store,
    private readonly analyticsService: AnalyticsService,
    private readonly caseResourceService: CaseResourceControllerService,
    private readonly routerStateService: RouterStateService,
    private readonly permissionsService: PermissionsService
  ) {}

  private getAuthData() {
    return {
      subject: this.widget.subject,
      firstName: this.widget.firstName,
      lastName: this.widget.lastName,
    };
  }

  init() {
    if (this.initialized) {
      throw new Error('AuthService is already initialized. init() method should be called only once.');
    }
    this.widget.setWidgetLocation(OKTA_WIDGET_LOCATION);
    this.fetchSessionAliveStatus();

    this.initialized = true;
  }

  login() {
    this.fetchSessionAliveStatus();
    return zip(
      of(this.getAuthData()),
      this.loginControllerService.login(),
      this.caseResourceService.getResourceRoles1()
    ).pipe(
      map((loginData: any) => {
        const [authData, extData, availableRoles] = loginData;
        return createUser({...authData, ...extData, availableRoles});
      }),
      switchMap(user => zip(of(this.user.store(user)), this.store.dispatch(new LoadPermissions()))),
      tap(() => {
        this.analyticsService.log(logEvents(EventAction.Login));
        this.sessionStatus = Promise.resolve(true);
        return this.sessionStatus;
      })
    );
  }

  get widgetLocationId(): string {
    return OKTA_WIDGET_LOCATION_ID;
  }

  async getAccessToken() {
    if (await this.isSessionAlive()) {
      return this.widget.getAccessToken();
    }

    return null;
  }

  async acceptTc() {
    const userDetails: UserDetails = await firstValueFrom(this.loginControllerService.acceptTc());
    this.user.setTcAcceptedDate(userDetails.tcAcceptedDate);
  }

  isLoggedIn() {
    return this.user.isLoggedIn() && this.widget.isLoggedIn();
  }

  async isTokenExpired() {
    const token = await this.widget.getAccessToken();
    if (!token) {
      return true;
    }

    const decoded = this.widget.decodeJWT(token);
    const expirationDate = new Date(0).setUTCSeconds(decoded.exp);
    return new Date().valueOf() >= expirationDate.valueOf();
  }

  fetchSessionAliveStatus(): Promise<boolean> {
    this.sessionStatus = new Promise<boolean>(resolve =>
      this.widget.sessionInfo((session: any) => (session ? resolve(true) : resolve(false)))
    );
    return this.sessionStatus;
  }

  isSessionAlive(): Promise<boolean> {
    return this.sessionStatus;
  }

  sessionDestroyed() {
    this.sessionStatus = Promise.resolve(false);
  }

  restoreSession(): Observable<void> {
    return this.widget.getNewToken().pipe(mapTo(undefined));
  }

  switchRole(role: string): void {
    zip(this.routerStateService.navigate('switch-user-role'), this.loginControllerService.changeRole({role})).subscribe(
      async () => {
        this.user.switchRole([role]);
        await this.permissionsService.reset();
        this.routerStateService.navigate('core');
      }
    );
  }

  logout(logoutManually: boolean) {
    return from(this.getAccessToken()).pipe(
      switchMap(token => (token ? this.loginControllerService.logout() : of(null))),
      tap(() => {
        if (logoutManually) {
          this.analyticsService.log(logEvents(EventAction.Logout));
        }
        this.sessionStatus = Promise.resolve(false);
        return this.sessionStatus;
      }),
      switchMap(() =>
        merge(this.widget.signout(), this.store.dispatch([new Logout(), new ClearPermissions()]), of(this.user.clear()))
      ),
      mapTo(undefined)
    );
  }
}
