import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {GetVaccinationsData} from 'app/store/vaccinations/vaccinations.actions';
import {AvailableVaccinationModel, VaccinationsConfigModel, VaccinationStatus} from '@matchsource/models/vaccination';
import {VaccinationsApiService} from '@matchsource/api/vaccinations';
import {LOADING_STATUS, LoadingStatus} from 'app/shared/entities/loading-status';

export interface VaccinationsStateModel {
  loadingStatus: LoadingStatus;
  vaccinations: AvailableVaccinationModel[];
  vaccinationStatuses: VaccinationStatus[];
  vaccineManufactures: string[];
}

const getDefaultValues = (): VaccinationsStateModel => ({
  loadingStatus: LOADING_STATUS.default,
  vaccinations: [],
  vaccinationStatuses: [],
  vaccineManufactures: [],
});

@State<VaccinationsStateModel>({
  name: 'vaccinations',
  defaults: getDefaultValues(),
})
@Injectable()
export class VaccinationsState {
  constructor(private readonly vaccinationsApiService: VaccinationsApiService) {}

  @Selector([VaccinationsState])
  static loadingStatus(state: VaccinationsStateModel): LoadingStatus {
    return state.loadingStatus;
  }

  @Selector([VaccinationsState.loadingStatus])
  static loading(loadingStatus: LoadingStatus): boolean {
    return loadingStatus.loading;
  }

  @Selector([VaccinationsState.loadingStatus])
  static loaded(loadingStatus: LoadingStatus): boolean {
    return loadingStatus.loaded;
  }

  @Selector([VaccinationsState.loading, VaccinationsState.loaded])
  static inProgressOrLoaded(loading: boolean, loaded: boolean): boolean {
    return loaded || loading;
  }

  @Selector([VaccinationsState])
  static vaccinationsConfig(state: VaccinationsStateModel): VaccinationsConfigModel {
    return {
      availableVaccinations: state.vaccinations,
      vaccineManufactures: state.vaccineManufactures,
      vaccinationStatuses: state.vaccinationStatuses,
    };
  }

  @Action(GetVaccinationsData)
  getVaccinationsData(ctx: StateContext<VaccinationsStateModel>) {
    ctx.patchState({loadingStatus: LOADING_STATUS.loading});
    return this.vaccinationsApiService.getConfig().pipe(
      tap((res: VaccinationsConfigModel) => {
        ctx.patchState({
          loadingStatus: LOADING_STATUS.loaded,
          vaccinations: res.availableVaccinations,
          vaccinationStatuses: res.vaccinationStatuses,
          vaccineManufactures: res.vaccineManufactures,
        });
      }),
      catchError(() => {
        ctx.patchState({
          loadingStatus: LOADING_STATUS.error,
          vaccinations: [],
          vaccinationStatuses: [],
          vaccineManufactures: [],
        });
        return of({});
      })
    );
  }
}
