import {Injectable} from '@angular/core';
import {Action, createSelector, Selector, State, StateContext, Store} from '@ngxs/store';
import {GetSourceHlaHistory} from 'app/store/source-hla-histories/source-hla-histories.action';
import {HlaHistoryModel, HlaHistoryApiService} from '@matchsource/api/hla-history';

import {catchError, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {patch} from '@ngxs/store/operators';
import orderBy from 'lodash-es/orderBy';

interface EntityMetadata {
  loading: boolean;
  loaded: boolean;
  error: any;
}

export interface SourceHlaHistoriesStateModel {
  entities: MsApp.Dictionary<HlaHistoryModel[]>;
  metadata: MsApp.Dictionary<EntityMetadata>;
}

const DEFAULT_ENTITY_METADATA: EntityMetadata = {
  loading: false,
  loaded: false,
  error: null,
};

@State<SourceHlaHistoriesStateModel>({
  name: 'sourceHlaHistories',
  defaults: {
    entities: {},
    metadata: {},
  },
})
@Injectable()
export class SourceHlaHistoriesState {
  @Selector([SourceHlaHistoriesState])
  static entities({entities}: SourceHlaHistoriesStateModel) {
    return entities;
  }

  @Selector([SourceHlaHistoriesState])
  static metadata({metadata}: SourceHlaHistoriesStateModel) {
    return metadata;
  }

  static sourceHlaHistory(sourceId: MsApp.Guid) {
    return createSelector([SourceHlaHistoriesState.entities], (entities: MsApp.Dictionary<HlaHistoryModel[]>) =>
      sourceId in entities ? entities[sourceId] : null
    );
  }

  static sourceHlaHistorMetadata(sourceId: MsApp.Guid) {
    return createSelector([this.metadata], (metadata: MsApp.Dictionary<EntityMetadata>) =>
      sourceId in metadata ? metadata[sourceId] : null
    );
  }

  static sourceHlaHistoryLoadedOrInProgress(sourceId: MsApp.Guid) {
    return createSelector(
      [this.sourceHlaHistorMetadata(sourceId)],
      (entityMetadata: EntityMetadata) => entityMetadata && (entityMetadata.loaded || entityMetadata.loading)
    );
  }

  constructor(
    private readonly hlaHistoryApi: HlaHistoryApiService,
    private readonly store: Store
  ) {}

  @Action(GetSourceHlaHistory)
  loadHistory(ctx: StateContext<SourceHlaHistoriesStateModel>, {sourceId}: GetSourceHlaHistory) {
    const sourceIsLoadedOrInProgress = this.store.selectSnapshot(
      SourceHlaHistoriesState.sourceHlaHistoryLoadedOrInProgress(sourceId)
    );

    if (sourceIsLoadedOrInProgress) {
      return;
    }

    ctx.setState(
      patch({
        metadata: patch({
          [sourceId]: {
            ...DEFAULT_ENTITY_METADATA,
            loading: true,
          },
        }),
      })
    );

    return this.hlaHistoryApi.getSourceHlaHistory(sourceId).pipe(
      catchError(error => {
        ctx.setState(
          patch({
            metadata: patch({
              [sourceId]: patch({
                error,
              }),
            }),
          })
        );
        return of(null);
      }),
      tap(hlaHistory => {
        ctx.setState(
          patch({
            entities: patch({
              [sourceId]: orderBy(hlaHistory, ['reportingDate', 'seqNum'], ['desc', 'desc']),
            }),
            metadata: patch({
              [sourceId]: patch({
                loaded: true,
              }),
            }),
          })
        );
      })
    );
  }
}
