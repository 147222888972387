import {NomenclatureOptionsStateModel} from './declarations';
import {BloodSourceType, SourceType} from '@matchsource/models/source';
import {Buttons} from '@matchsource/nmdp-ui';
import {OplSourceTypes} from '@matchsource/models/opl';
import {ORDER_TYPE} from '@matchsource/models/order';

export const BLOOD_SOURCE_TYPE_TO_SOURCE_TYPE_MAP: Partial<Record<BloodSourceType, SourceType>> = {
  [BloodSourceType.AbOnly]: 'DONOR',
  [BloodSourceType.Cords]: 'CORD',
  [BloodSourceType.Donors]: 'DONOR',
  [BloodSourceType.Biobanks]: 'BDP',
};

export const OPL_SOURCE_TYPE_TO_BLOOD_SOURCE_TYPE: Record<OplSourceTypes, BloodSourceType> = {
  ['CORD']: BloodSourceType.Cords,
  ['DONOR']: BloodSourceType.Donors,
};

export enum PreferenceType {
  Columns = 'COLUMNS',
  Sort = 'SORT',
  All = 'ALL',
}

export const PREFERENCES = {
  COLUMNS: 'COLUMNS',
  SORT: 'SORT',
  ALL: 'ALL',

  is: (value: string | PreferenceType, preference: string) => value === preference || value === PREFERENCES.ALL,
  isSort: (value: string | PreferenceType) => PREFERENCES.is(value, PREFERENCES.SORT),
  isColumns: (value: string | PreferenceType) => PREFERENCES.is(value, PREFERENCES.COLUMNS),
};

export enum FileFormat {
  PDF = 'pdf',
  CSV = 'csv',
}

export const SYSTEM_MAINTENANCE = 'System Maintenance';

export const nomenclatureOptionsInitState = (): NomenclatureOptionsStateModel => ({
  loading: false,
  loaded: false,
  options: [],
});

export const AWAY_DIALOG = {
  caption: 'LEAVE_POPUP.TITLE',
  cancelText: 'LEAVE_POPUP.LEAVE',
  confirmText: 'LEAVE_POPUP.STAY',
};

export const ADD_WARNING_DIALOG_CONFIG = {
  headerText: 'CART.WARNING_HEADER',
};

export const REMOVE_FROM_CART_DIALOG_CONFIG = {
  caption: 'CART.REMOVE_HEADER',
  confirmText: 'COMMON.DELETE',
  confirmButtonType: Buttons.Warning,
};

export const MATCH_LEVEL = {
  TEN_OF_TEN: 11,
  NINE_OF_TEN: 9,
  EIGHT_OF_TEN: 8,
  SEVEN_OF_TEN: 7,
  SIX_OF_TEN: 5,
  FIVE_OF_TEN: 3,

  EIGHT_OF_EIGHT: 10,
  SEVEN_OF_EIGHT: 6,
  SIX_OF_EIGHT: 4,
  FIVE_OF_EIGHT: 2,
  FOUR_OF_EIGHT: 1,

  ANY: -1,
};

export const AGE_RANGE = {
  MIN_AGE: 16,
  MAX_AGE: 60,
};

export enum DefaultSettings {
  DefaultPatientSettings = 'defaultPatientSettings',
  DefaultAllPatientsSettings = 'defaultAllPatientsSettings',
}

export type DefaultSettingsTypes = DefaultSettings.DefaultAllPatientsSettings | DefaultSettings.DefaultPatientSettings;

export const VALIDATION_ERRORS = {
  OPL_NAME_NOT_UNIQUE: 'UniqueOplName',
  OPL_SOURCE_LIM: 'OPL_SOURCE_MAX_LIMIT',
  OPL_SOURCE_EXIST: 'OPL_SOURCE_EXIST',
  OPL_LIMIT: 'OPL_MAX_LIMIT',
};

export const BLOOD_SRC_OPL_TYPES = {
  donors: 'DONOR',
  cords: 'CORD',
  abonly: 'DONOR',
};

export const OPL_SOURCE_TYPES = {
  [BLOOD_SRC_OPL_TYPES.donors]: 'donors',
  [BLOOD_SRC_OPL_TYPES.cords]: 'cords',
};

export const NMDP_US_GROUP_BP_GUID = '6B8B9AFD05F94B428AADED5898579092';

export const CART_EDIT_ORDERS = {
  ORDER_TYPE: 'Order Type',
  WORKUP_FORM: 'Workup Form',
  CORD_WORKUP_FORM: 'Cord Shipment Form',
};

export const OPL_BLOOD_TYPES = {
  donors: 'donors',
  cords: 'cords',
  abonly: 'donors',
};

export const EXPORT_LIMIT = 500;

export enum BloodTypes {
  Donor = 'donor',
  Cord = 'cord',
  Bdp = 'bdp',
  AdultDonorCryopreservedProduct = 'Adult Donor Cryopreserved Product',
}

export const CART_NOTIFICATIONS = {
  CAN_DEACTIVATE: 'CART:CAN_DEACTIVATE',
};

export const FAILED_CODE = {
  HAS_PENDING_ORDER: 'HAS_PENDING_ORDER',
  IS_NOT_AVAILABLE: 'IS_NOT_AVAILABLE',
  ORDER_TYPE_MISSED_IN_CATALOG: 'ORDER_TYPE_MISSED_IN_CATALOG',
  SERVER_ERROR: 'SERVER_ERROR',
  RECIPIENT_FROM_OFAC_RESTRICTED_TC: 'RECIPIENT_FROM_OFAC_RESTRICTED_TC',
  ORDERABLE_FOR_CM_ONLY: 'ORDERABLE_FOR_CM_ONLY',
  RECIPIENT_HAS_CLOSED_STATUS: 'RECIPIENT_HAS_CLOSED_STATUS',
  DUPLICATE_RECIPIENT: 'DUPLICATE_RECIPIENT',
  RESTRICT_TWO_ORDERS_ON_THE_SAME_DAY: 'RESTRICT_TWO_ORDERS_ON_THE_SAME_DAY',
  CRITICAL_STATUS_ANOMALY_NOT_INITIAL_USER: 'CRITICAL_STATUS_ANOMALY_NOT_INITIAL_USER',
  CRITICAL_STATUS_ANOMALY_INITIAL_USER: 'CRITICAL_STATUS_ANOMALY_INITIAL_USER',
  TEMPORAL_STATUS_ANOMALY_NOT_INITIAL_USER: 'TEMPORAL_STATUS_ANOMALY_NOT_INITIAL_USER',
  TEMPORAL_STATUS_ANOMALY_INITIAL_USER: 'TEMPORAL_STATUS_ANOMALY_INITIAL_USER',
};

export const ORDER_STATUS = {
  CREATED: 'CREATED',
  FAILED: 'FAILED',
  NOT_ALLOWED: 'NOT_ALLOWED',
};

export const CT_ORDER_TYPES = [ORDER_TYPE.CT.type, ORDER_TYPE.CCT.type, ORDER_TYPE.HR.type];

export const SEARCH_SOURCE_TYPES = {
  DONOR: 'donor',
  CORD: 'cord',
  BDP: 'Adult Donor Cryopreserved Product',
};

export const FAILED_POPULATION_CODE = 'failed';

export enum DonorGridTestIds {
  Export = 'export',
  Checkbox = 'checkbox',
  Expand = 'expand',
  Cart = 'cart',
  Ref = 'ref',
  StatusCountry = 'status_country',
  ListIonDcId = 'listIonDcId',
  GridRace = 'grid_race',
  AgeSex = 'age_sex',
  ContactData = 'lastContactDate_contactType',
  CmvBloodTypeRh = 'cmv_bloodTypeRh',
  PrevDonation = 'previousDonation',
  PregnanciesPrevCt = 'pregnancies_prevCT',
  KirCenBCcr5 = 'kirCenB_ccr5',
  Weight = 'weight',
  AvailDateRegDate = 'availabilityDate_regDate',
  Dpb1TceMatchPrediction = 'dpb1_Tce_Match_prediction',
  Dpb1ExpressionMatchStatus = 'dpb1ExpressionMatchStatus',
  MatchingCategory = 'matchingCategory',
  MatchingCategory10 = 'matchingCategory10',
  MatchingCategory8 = 'matchingCategory8',
  AlleleProbability10 = 'probability10',
  AlleleProbability8 = 'probability8',
  HlaMapA = 'matching.hlaMap.A',
  HlaMapB = 'matching.hlaMap.B',
  HlaMapC = 'matching.hlaMap.C',
  HlaMapDRB1 = 'matching.hlaMap.DRB1',
  HlaMapDQB1 = 'matching.hlaMap.DQB1',
  HlaMapDRB3 = 'matching.hlaMap.DRB3',
  HlaMapDRB4 = 'matching.hlaMap.DRB4',
  HlaMapDRB5 = 'matching.hlaMap.DRB5',
  HlaMapDPB1 = 'matching.hlaMap.DPB1',
  DPB1Expression = 'dpb1Expression',
  HlaMapDQA1 = 'matching.hlaMap.DQA1',
  HlaMapDPA1 = 'matching.hlaMap.DPA1',
  DrbxScore = 'drbxScore',
  PopulationCode = 'populationCode',
  DisplayId = 'displayId',
  SuggestedTyping = 'suggestedTyping',
  Drs = 'drs',
  Vaccination = 'vaccination',
  VaccinationDate = 'vaccinationDate',
  Comment = 'comment',
  SourceId = 'source_id',
  Order = 'order',
  CreateDate = 'createDate',
  GridId = 'gridId',
  StatusIon = 'status_ion',
  Race = 'race',
  PregWeight = 'preg_weight',
  Prediction = 'prediction',
  Ccr5 = 'ccr5',
  CartOrderType = 'cart-order-type',
  CartButton = 'cart-button',
  LabelPreviousCted = 'label-previous-cted',
  Age = 'age',
  SexCode = 'sex',
  CreationDate = 'creation-date',
}

export enum CordGridTestIds {
  Export = 'export_unitRpt',
  Expand = 'expand',
  Checkbox = 'select-checkbox',
  Cart = 'cart',
  Ref = 'ref',
  StatusCountry = 'status_country',
  ListIon = 'list_ion',
  PidRaceEth = 'pid_raceEth',
  Weight = 'tncWeight_cd34FcWeight',
  TncCd34 = 'tnc_cd34',
  TncCd34Kg = 'tnc_cd34_kg',
  PrevCTLicensure = 'previousCT_licensure',
  DobSex = 'DOB_SEX',
  AboRh = 'final_prod_vol_ABO_RH',
  Seg = 'seg',
  NumOfSeg = 'num-of-seg',
  HgbNrbc = 'hgb_nRBC',
  MCat8 = 'matching.matchingCategory_Xf8',
  MCat6 = 'matching.matchingCategory_Xf6',
  MCat = 'm_cat',
  AlleleProbability8 = 'matching.alleleLevelMatchProbability8',
  AlleleProbability6 = 'matching.alleleLevelMatchProbability6',
  HlaMapA = 'matching.hlaMap.A',
  HlaMapB = 'matching.hlaMap.B',
  HlaMapC = 'matching.hlaMap.C',
  HlaMapDRB1 = 'matching.hlaMap.DRB1',
  HlaMapDQB1 = 'matching.hlaMap.DQB1',
  HlaMapDRB3 = 'matching.hlaMap.DRB3',
  HlaMapDRB4 = 'matching.hlaMap.DRB4',
  HlaMapDRB5 = 'matching.hlaMap.DRB5',
  HlaMapDPB1 = 'matching.hlaMap.DPB1',
  DPB1Expression = 'dpb1ExpressionMatchStatus',
  HlaMapDQA1 = 'matching.hlaMap.DQA1',
  HlaMapDPA1 = 'matching.hlaMap.DPA1',
  PopulationCode = 'populationCode',
  PlasmaRbcReduced = 'plasmaRbcReduced',
  CureReady = 'cureReady',
  SingleCBU = 'meetsNmdp_singleCBU',
  LikelyMatchingCategory8 = 'likely_matchingCategory8',
  LikelyHlaMismatches = 'likelyHla_mismatches',
  CureReadySeg = 'cureReady_seg',
  Comment = 'comment',
  AvailDateOfSeg = 'availabilityDate_of_seg',
  RegCbbId = 'regCbbId',
  Order = 'order',
  CreateDate = 'create-date',
  Id = 'id',
  StatusRegCbbId = 'status_regCbbId',
  Race = 'race',
  HgbAboRh = 'hgd_ABO_RH',
  EligibilityLicensure = 'eligibility_licensure',
  Prediction = 'prediction',
  CartOrderType = 'cart-order-type',
  SourcesGrid = 'sources-grid',
  CartButton = 'cart-button',
  SourceIdButton = 'source-id-button',
  SexCode = 'sex',
  DateOfBirth = 'date-of-birth',
  BlockedIcon = 'blocked-icon',
  Cd34TncRatio = 'CD34_TNC_Ratio',
}

export enum BiobankGridTestIds {
  Export = 'export_unitRpt',
  Expand = 'expand',
  Checkbox = 'select-checkbox',
  Cart = 'cart',
  Ccr5 = 'ccr5',
  CmvBloodTypeRh = 'cmv_bloodTypeRh',
  MatchingCategory = 'matchingCategory',
  AlleleProbability10 = 'probability10',
  Dpb1TceMatchPrediction = 'dpb1_Tce_Match_prediction',
  Dpb1tcematchdsc = 'dpb1-tce-match-dsc',
  Ref = 'ref',
  StatusCountry = 'status_country',
  ListIon = 'list_ion',
  PidRaceEth = 'pid_raceEth',
  Weight = 'tncWeight_cd34FcWeight',
  TncCd34 = 'tnc_cd34',
  AgeSex = 'age_sex',
  TncCd34Kg = 'tnc_cd34_kg',
  PrevCTLicensure = 'previousCT_licensure',
  DobSex = 'DOB_SEX',
  AboRh = 'final_prod_vol_ABO_RH',
  Seg = 'seg',
  NumOfSeg = 'num-of-seg',
  HgbNrbc = 'hgb_nRBC',
  MCat8 = 'matching.matchingCategory_Xf8',
  MCat6 = 'matching.matchingCategory_Xf6',
  MCat = 'm_cat',
  AlleleProbability8 = 'matching.alleleLevelMatchProbability8',
  AlleleProbability6 = 'matching.alleleLevelMatchProbability6',
  HlaMapA = 'matching.hlaMap.A',
  HlaMapB = 'matching.hlaMap.B',
  HlaMapC = 'matching.hlaMap.C',
  HlaMapDRB1 = 'matching.hlaMap.DRB1',
  HlaMapDQB1 = 'matching.hlaMap.DQB1',
  HlaMapDRB3 = 'matching.hlaMap.DRB3',
  HlaMapDRB4 = 'matching.hlaMap.DRB4',
  HlaMapDRB5 = 'matching.hlaMap.DRB5',
  HlaMapDPB1 = 'matching.hlaMap.DPB1',
  DPB1Expression = 'dpb1ExpressionMatchStatus',
  HlaMapDQA1 = 'matching.hlaMap.DQA1',
  HlaMapDPA1 = 'matching.hlaMap.DPA1',
  PopulationCode = 'populationCode',
  PlasmaRbcReduced = 'plasmaRbcReduced',
  CureReady = 'cureReady',
  SingleCBU = 'meetsNmdp_singleCBU',
  LikelyMatchingCategory8 = 'likely_matchingCategory8',
  LikelyHlaMismatches = 'likelyHla_mismatches',
  CureReadySeg = 'cureReady_seg',
  Comment = 'comment',
  AvailDateOfSeg = 'availabilityDate_of_seg',
  RegCbbId = 'regCbbId',
  Order = 'order',
  CreateDate = 'create-date',
  Id = 'id',
  StatusRegCbbId = 'status_regCbbId',
  Race = 'race',
  HgbAboRh = 'hgd_ABO_RH',
  EligibilityLicensure = 'eligibility_licensure',
  Prediction = 'prediction',
  SourceStatus = 'source-status',
  OfacCountry = 'ofac-country',
  Country = 'country',
  DcList = 'dc-list',
  DcId = 'dc-id',
  SourceIdLink = 'source-id-link',
  RaceId = 'race-id',
  RaceEth = 'race-eth',
  Age = 'age',
  SexTestId = 'sex',
  Cmv = 'cmv',
  BloodTypeRh = 'blood-type-rh',
  TncWeight = 'tnc-weight',
  TncCd34Weight = 'tnc-cd34-weight',
  TncFrozen = 'tnc-frozen',
  TncCd34Frozen = 'tnc-cd34-frozen',
  FinalProdVol = 'final-prod-vol',
  NoHaplotypePairsInfoLink = 'source-no-haplotype-pairs-info-link',
  McatInfo = 'mcat-info',
  PctOf10 = 'pct-of-10',
  PctOf8 = 'pct-of-8',
  HeaderSeparator = 'header-separator',
  UnitReportHeaderItem = 'unit-report-header-item',
  HlaLocusDPB1 = 'hla-locus-dpb1',
  HlaLocusDQA1 = 'hla-locus-dqa1',
  HlaLocusDPA1 = 'hla-locus-dpa1',
}

export enum CartActions {
  RemoveFromCart = 'remove-from-cart',
  AddToCart = 'add-to-cart',
  OpenCordThresholdGuidelinesDialog = 'open-cord-threshold-guidelines-dialog',
}

export const ELEMENT_TYPE = {
  DEFAULT: 'DEFAULT',
  LABEL: 'LABEL',
  INPUT: 'INPUT',
  MASK_INPUT: 'MASK_INPUT',
  RADIO: 'RADIO',
  CHECKBOX: 'CHECKBOX',
  DATEPICKER: 'DATEPICKER',
  GROUPED_MULTISELECT: 'GROUPED_MULTISELECT',
  SELECT: 'SELECT',
};

export enum CriticalSourceStatusAnomalyReason {
  InitialUser = 'CRITICAL_STATUS_ANOMALY_INITIAL_USER',
  NotInitialUser = 'CRITICAL_STATUS_ANOMALY_NOT_INITIAL_USER',
}

export enum RelationshipToPatient {
  Parent = 'PARENT',
  Child = 'CHILD',
  Sibling = 'SIBLING',
  HalfSibling = 'HALF_SIBLING',
}
